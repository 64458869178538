import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { AUTH_TOKEN, readStorage } from '../utils/storage'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  login: ['email', 'password'],
  loginSuccess: ['user', 'token', 'currentOrganizationId'],
  loginFailure: null,
  logout: null,
  logoutSuccess: null,
  getMe: ['token'],
  getMeSuccess: ['user', 'currentOrganizationId'],
  updateUser: ['id', 'fields'],
  updateUserSuccess: ['user'],
  updateUserFailure: null,
  changePassword: ['oldPassword', 'newPassword'],
  changePasswordSuccess: ['user', 'token'],
  changePasswordFailure: null,
  requestPasswordReset: ['email'],
  requestPasswordResetFinished: null,
  checkCodeValidity: ['code', 'email'],
  resetPassword: ['code', 'email', 'password'],
  resetPasswordFinished: null,
  getUsers: ['organizationId', 'params'],
  getUsersSuccess: ['users'],
  getUsersFailure: null,
  getTargetUser: ['id'],
  getTargetUserSuccess: ['user'],
  getTargetUserFailure: null,
  setTargetUser: ['user'],
  createUser: ['fields'],
  createUserSuccess: ['targetUser'],
  createUserFailure: null,
  updateTargetUser: ['id', 'fields'],
  updateTargetUserFinished: null,
  addUserDomain: ['userId', 'domainIds', 'role'],
  addUserDomainFinished: null,
  removeUserDomain: ['userId', 'domainIds'],
  removeUserDomainFinished: null,
  updateUserDomains: ['userId', 'oldDomainIds', 'newDomainIds', 'role'],
  updateUserDomainsFinished: null,
  getExistingUserByEmail: ['email'],
  getExistingUserByEmailSuccess: ['user'],
  getExistingUserByEmailFailure: null,
  clearExistingUser: null,
  setCurrentOrganizationId: ['id']
})

export const UserTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  user: null, // Logged in user
  token: readStorage(AUTH_TOKEN),
  currentOrganizationId: null,
  users: null,
  targetUser: null, // User to view/edit,
  existingUser: null
})

/* ------------- Reducers ------------- */

const login = (state, { email, password }) => state.merge({ fetching: true })
const loginSuccess = (state, { user, token, currentOrganizationId }) => state.merge({ user, token, currentOrganizationId, fetching: false })
const loginFailure = (state) => state.merge({ fetching: false })
const logoutSuccess = (state) => state.merge({ user: null, token: null })

const getMeSuccess = (state, { user, currentOrganizationId }) => state.merge({ user, currentOrganizationId })

const updateUser = (state, { id, fields }) => state.merge({ fetching: true })
const updateUserSuccess = (state, { user }) => state.merge({ user, fetching: false })
const updateUserFailure = (state) => state.merge({ fetching: false })

const changePassword = (state, { oldPassword, newPassword }) => state.merge({ fetching: true })
const changePasswordSuccess = (state, { user, token }) => state.merge({ user, token, fetching: false })
const changePasswordFailure = (state) => state.merge({ fetching: false })

const requestPasswordReset = (state, { email }) => state.merge({ fetching: true })
const requestPasswordResetFinished = (state) => state.merge({ fetching: false })

const resetPassword = (state, { code, email, password }) => state.merge({ fetching: true })
const resetPasswordFinished = (state) => state.merge({ fetching: false })

const getUsers = (state) => state.merge({ fetching: true })
const getUsersSuccess = (state, { users }) => state.merge({ users, fetching: false })
const getUsersFailure = (state) => state.merge({ fetching: false })

const getTargetUser = (state) => state.merge({ fetching: true })
const getTargetUserSuccess = (state, { user }) => state.merge({ targetUser: user, fetching: false })
const getTargetUserFailure = (state) => state.merge({ fetching: false })

const setTargetUser = (state, { user }) => state.merge({ targetUser: user })

const createUser = (state) => state.merge({ fetching: true })
const createUserSuccess = (state, { targetUser }) => state.merge({ fetching: false, targetUser })
const createUserFailure = (state) => state.merge({ fetching: false })

const updateTargetUser = (state, { id, fields }) => state.merge({ fetching: true })
const updateTargetUserFinished = (state) => state.merge({ fetching: false })

const addUserDomain = (state) => state.merge({ fetching: true })
const addUserDomainFinished = (state) => state.merge({ fetching: false })

const removeUserDomain = (state) => state.merge({ fetching: true })
const removeUserDomainFinished = (state) => state.merge({ fetching: false })

const updateUserDomains = (state) => state.merge({ fetching: true })
const updateUserDomainsFinished = (state) => state.merge({ fetching: false })

const getExistingUserByEmail = (state) => state.merge({ fetching: true, existingUser: null })
const getExistingUserByEmailSuccess = (state, { user }) => state.merge({ fetching: false, existingUser: user })
const getExistingUserByEmailFailure = (state) => state.merge({ fetching: false })

const clearExistingUser = (state) => state.merge({ existingUser: null })

const setCurrentOrganizationId = (state, { id }) => state.merge({ currentOrganizationId: id })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN]: login,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.GET_ME_SUCCESS]: getMeSuccess,
  [Types.UPDATE_USER]: updateUser,
  [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [Types.UPDATE_USER_FAILURE]: updateUserFailure,
  [Types.CHANGE_PASSWORD]: changePassword,
  [Types.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [Types.CHANGE_PASSWORD_FAILURE]: changePasswordFailure,
  [Types.REQUEST_PASSWORD_RESET]: requestPasswordReset,
  [Types.REQUEST_PASSWORD_RESET_FINISHED]: requestPasswordResetFinished,
  [Types.RESET_PASSWORD]: resetPassword,
  [Types.RESET_PASSWORD_FINISHED]: resetPasswordFinished,
  [Types.GET_USERS]: getUsers,
  [Types.GET_USERS_SUCCESS]: getUsersSuccess,
  [Types.GET_USERS_FAILURE]: getUsersFailure,
  [Types.GET_TARGET_USER]: getTargetUser,
  [Types.GET_TARGET_USER_SUCCESS]: getTargetUserSuccess,
  [Types.GET_TARGET_USER_FAILURE]: getTargetUserFailure,
  [Types.SET_TARGET_USER]: setTargetUser,
  [Types.CREATE_USER]: createUser,
  [Types.CREATE_USER_SUCCESS]: createUserSuccess,
  [Types.CREATE_USER_FAILURE]: createUserFailure,
  [Types.UPDATE_TARGET_USER]: updateTargetUser,
  [Types.UPDATE_TARGET_USER_FINISHED]: updateTargetUserFinished,
  [Types.ADD_USER_DOMAIN]: addUserDomain,
  [Types.ADD_USER_DOMAIN_FINISHED]: addUserDomainFinished,
  [Types.REMOVE_USER_DOMAIN]: removeUserDomain,
  [Types.REMOVE_USER_DOMAIN_FINISHED]: removeUserDomainFinished,
  [Types.UPDATE_USER_DOMAINS]: updateUserDomains,
  [Types.UPDATE_USER_DOMAINS_FINISHED]: updateUserDomainsFinished,
  [Types.GET_EXISTING_USER_BY_EMAIL]: getExistingUserByEmail,
  [Types.GET_EXISTING_USER_BY_EMAIL_SUCCESS]: getExistingUserByEmailSuccess,
  [Types.GET_EXISTING_USER_BY_EMAIL_FAILURE]: getExistingUserByEmailFailure,
  [Types.CLEAR_EXISTING_USER]: clearExistingUser,
  [Types.SET_CURRENT_ORGANIZATION_ID]: setCurrentOrganizationId
})
