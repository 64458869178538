import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../utils/withRoot'
import CustomerActions from '../redux/CustomerRedux'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import CustomerInfoForm from '../components/Customer/CustomerInfoForm'
import CustomerContactInfoForm from '../components/Customer/CustomerContactInfoForm'
import { isValidEmail, isValidBusinessId } from '../utils/transforms'

const styles = theme => ({
})

class CreateCustomerScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = this.getInitialFields()
  }

  componentWillReceiveProps (nextProps) {
    // Clear fields on success
    const { fetching, error } = this.props
    if (fetching && !nextProps.fetching && !error && nextProps.customer) {
      this.props.history.push(`/asiakas/${nextProps.customer.id}`)
    }
  }

  getInitialFields = () => ({
    name: '',
    businessId: '',
    contactPerson: '',
    phone: '',
    email: '',
    url: '',
    address: '',
    city: '',
    postalCode: '',
    description: ''
  })

  setName = (evt) => this.setState({ name: evt.target.value })

  setBusinessId = (evt) => this.setState({ businessId: evt.target.value })

  setContactPerson = (evt) => this.setState({ contactPerson: evt.target.value })

  setPhone = (evt) => this.setState({ phone: evt.target.value })

  setEmail = (evt) => this.setState({ email: evt.target.value })

  setUrl = (evt) => this.setState({ url: evt.target.value })

  setAddress = (evt) => this.setState({ address: evt.target.value })

  setCity = (evt) => this.setState({ city: evt.target.value })

  setPostalCode = (evt) => this.setState({ postalCode: evt.target.value })

  setDescription = (evt) => this.setState({ description: evt.target.value })

  createCustomer = () => {
    const { currentOrganizationId } = this.props
    const newCustomer = {
      name: this.state.name.trim(),
      businessId: this.state.businessId.trim() || null,
      contactPerson: this.state.contactPerson.trim() || null,
      phone: this.state.phone.trim() || null,
      email: this.state.email.trim() || null,
      url: this.state.url.trim() || null,
      address: this.state.address.trim() || null,
      city: this.state.city.trim() || null,
      postalCode: this.state.postalCode.trim() || null,
      description: this.state.description.trim() || null
    }
    this.props.createCustomer(currentOrganizationId, newCustomer)
  }

  isCreateCustomerDisabled = () => {
    const { name, address, postalCode, city, businessId, contactPerson, phone, email } = this.state
    if (!isValidEmail(email)) return true
    if (!isValidBusinessId(businessId)) return true
    if (
      !name.trim() ||
      !address.trim() ||
      !postalCode.trim() ||
      !city.trim() ||
      !contactPerson.trim() ||
      !phone.trim()
    ) {
      return true
    }
  }

  renderPage = () => {
    const { t, fetching } = this.props
    const {
      name, businessId, contactPerson, phone, email, url, address, city, postalCode, description
    } = this.state
    return (
      <Layout>
        <PageHeader
          title={t('create_customer')}
          onClick={this.createCustomer}
          disabled={this.isCreateCustomerDisabled()}
          loading={fetching}
          editing
        />
        <CustomerInfoForm
          name={name}
          businessId={businessId}
          description={description}
          setName={this.setName}
          setBusinessId={this.setBusinessId}
          setDescription={this.setDescription}
          editing
        />
        <CustomerContactInfoForm
          contactPerson={contactPerson}
          phone={phone}
          email={email}
          url={url}
          address={address}
          city={city}
          postalCode={postalCode}
          setContactPerson={this.setContactPerson}
          setPhone={this.setPhone}
          setEmail={this.setEmail}
          setUrl={this.setUrl}
          setAddress={this.setAddress}
          setCity={this.setCity}
          setPostalCode={this.setPostalCode}
          editing
        />
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = (state) => {
  return {
    currentOrganizationId: state.user.currentOrganizationId,
    createCustomerOk: state.customer.createCustomerOk,
    fetching: state.customer.fetching,
    error: state.notification.error,
    customer: state.customer.customer
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createCustomer: (organizationId, fields) => dispatch(CustomerActions.createCustomer(organizationId, fields))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRoot(withStyles(styles)(withTranslation()(CreateCustomerScreen)))
)
