import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '../../static/muokkaa.svg'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  root: {
    backgroundColor: COLORS.dark,
    height: 50,
    cursor: 'pointer'
  },
  hover: {
    '&$hover:hover': {
      backgroundColor: COLORS.darkBackground
    }
  },
  cell: {
    fontSize: 16,
    fontFamily: 'Roboto',
    color: COLORS.white,
    textAlign: 'center',
    border: `0.5px solid ${COLORS.border}`
  },
  editBtn: {
    padding: 0,
    width: 40,
    height: 40,
    borderRadius: 20
  }
})

class CustomerTableRow extends React.PureComponent {
  getStatus = () => {
    const { t, customer } = this.props
    if (!customer.active) return t('deactive')
    return t('active')
  }

  editCustomer = (e) => {
    e.stopPropagation()
    this.props.toSingle(this.props.customer, true)
  }

  viewCustomer = () => this.props.toSingle(this.props.customer, false)

  renderEditButton = () => {
    const { editable, t, classes } = this.props
    if (!editable) return null
    return (
      <IconButton onClick={this.editCustomer} className={classes.editBtn} aria-label={t('edit')}>
        <img src={EditIcon} alt={t('edit')} />
      </IconButton>
    )
  }

  render () {
    const { classes, customer } = this.props
    return (
      <TableRow classes={{ root: classes.root, hover: classes.hover }} onClick={this.viewCustomer} hover>
        <TableCell className={classes.cell}>{customer.name}</TableCell>
        <TableCell className={classes.cell}>{this.getStatus()}</TableCell>
        <TableCell className={classes.cell}>
          {this.renderEditButton()}
        </TableCell>
      </TableRow>
    )
  }
}

export default withStyles(styles)(withTranslation()(CustomerTableRow))
