import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../utils/withRoot'
import PropertyActions from '../redux/PropertyRedux'
import CustomerActions from '../redux/CustomerRedux'
import NotificationActions from '../redux/NotificationRedux'
import MAPS from '../services/mapsApi'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import PropertyInfoForm from '../components/Property/PropertyInfoForm'
import PropertyCustomerInfo from '../components/Property/PropertyCustomerInfo'

const styles = theme => ({})
const api = MAPS.create()

class CreatePropertyScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = this.getInitialFields()
  }

  componentDidMount () {
    const { customer, getCustomer, match } = this.props
    if (!customer) {
      getCustomer(match.params.customerId)
    }
  }

  componentWillReceiveProps (nextProps) {
    // Clear fields on success
    const { fetching, error } = this.props
    if (fetching && !nextProps.fetching && !error) {
      this.resetFields()
    }
  }

  getInitialFields = () => ({
    name: '',
    address: '',
    city: '',
    postalCode: '',
    description: '',
    latitude: '',
    longitude: ''
  })

  resetFields = () => this.setState(this.getInitialFields())

  setName = evt => this.setState({ name: evt.target.value })

  setAddress = evt => this.setState({ address: evt.target.value })

  setCity = evt => this.setState({ city: evt.target.value })

  setPostalCode = evt => this.setState({ postalCode: evt.target.value })

  setLatitude = evt => this.setState({ latitude: evt.target.value })

  setLongitude = evt => this.setState({ longitude: evt.target.value })

  getLatLng = async () => {
    const { address, city, postalCode } = this.state
    if (address && city && postalCode) {
      const response = await api.getCoordsByPlace(address, city, postalCode)
      if (response.ok && response.data.results[0]) {
        const { lat, lng } = response.data.results[0].geometry.location
        this.setState({ latitude: lat, longitude: lng })
      } else {
        this.props.setError({ key: 'client_error', message: 'try_again' })
      }
    }
  }

  createProperty = () => {
    const { match, createProperty } = this.props
    const { customerId } = match.params
    const { name, address, postalCode, city, latitude, longitude } = this.state

    const fields = {}
    if (name) fields.name = this.state.name.trim()
    if (address) fields.address = this.state.address.trim()
    if (postalCode) fields.postalCode = this.state.postalCode.trim()
    if (city) fields.city = this.state.city.trim()
    if (latitude) fields.latitude = parseFloat(this.state.latitude)
    if (longitude) fields.longitude = parseFloat(this.state.longitude)

    createProperty(customerId, fields)
  }

  isCreatePropertyDisabled = () => {
    const { name, address, postalCode, city } = this.state
    if (!name.trim() || !address.trim() || !postalCode.trim() || !city.trim()) return true
  }

  renderPage = () => {
    const { t, fetching, customer } = this.props
    const { name, address, city, postalCode, latitude, longitude } = this.state
    return (
      <Layout>
        <PageHeader
          title={t('create_property')}
          onClick={this.createProperty}
          disabled={this.isCreatePropertyDisabled()}
          loading={fetching}
          editing
        />
        <PropertyInfoForm
          name={name}
          address={address}
          city={city}
          postalCode={postalCode}
          latitude={latitude}
          longitude={longitude}
          setName={this.setName}
          setAddress={this.setAddress}
          setCity={this.setCity}
          setPostalCode={this.setPostalCode}
          setLatitude={this.setLatitude}
          setLongitude={this.setLongitude}
          getLatLng={this.getLatLng}
          editing
        />
        <PropertyCustomerInfo customer={customer} />
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = state => {
  return {
    customer: state.customer.customer,
    fetching: state.property.fetching,
    error: state.notification.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createProperty: (customerId, fields) =>
      dispatch(PropertyActions.createProperty(customerId, fields)),
    getCustomer: customerId => dispatch(CustomerActions.getCustomer(customerId)),
    setError: error => dispatch(NotificationActions.setError(error))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoot(withStyles(styles)(withTranslation()(CreatePropertyScreen))))
