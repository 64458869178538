import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../utils/withRoot'
import { isSuperadmin } from '../utils/permissions'

const styles = theme => ({
})

class DashboardScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  renderPage = () => {
    const { user } = this.props
    if (isSuperadmin(user)) {
      return <Redirect to='/organisaatiot' />
    }
    return <Redirect to='/asetukset' />
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRoot(withStyles(styles)(DashboardScreen))
)
