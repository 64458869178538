import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import get from 'lodash/get'
import withRoot from '../utils/withRoot'
import AssetActions from '../redux/AssetRedux'
import CustomerActions from '../redux/CustomerRedux'
import PropertyActions from '../redux/PropertyRedux'
import UserActions from '../redux/UserRedux'
import AssetStorageActions from '../redux/AssetStorageRedux'
import AssetTransactionActions from '../redux/AssetTransactionRedux'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import AssetForm from '../components/Asset/AssetForm'
import AssetHolderSelector from '../components/Asset/AssetHolderSelector'
import AssetTransactionLog from '../components/AssetTransaction/AssetTransactionLog'
import AssetInfo from '../components/Asset/AssetInfo'
import { ASSET_HOLDERS, BIG_LIMIT, FIELDS } from '../utils/constants'
import { isEditDisabled } from '../utils/permissions'

const styles = theme => ({})

class AssetScreen extends React.Component {
  constructor (props) {
    super(props)

    const userId = get(props, 'asset.userId', '')
    const assetStorageId = get(props, 'asset.assetStorageId', '')

    this.state = {
      name: get(props, 'asset.name', '') || '',
      identifier: get(props, 'asset.identifier', '') || '',
      description: get(props, 'asset.description', '') || '',
      type: get(props, 'asset.type', '') || '',
      selectedProperty: get(props, 'asset.propertyId', '') || '',
      initialUserId: userId,
      initialAssetStorageId: assetStorageId,
      initialHolder: userId ? ASSET_HOLDERS.USER : assetStorageId ? ASSET_HOLDERS.ASSET_STORAGE : '',
      editing: this.props.location.state && this.props.location.state.edit
    }
  }

  componentDidMount () {
    const { asset, getAsset, getAssetStorages, currentOrganizationId, assetStorages, users, getUsers, match } = this.props
    const { userId, assetStorageId } = this.state
    if (!asset) getAsset(match.params.assetId)
    // If currently selected asset has assetStorageId, get asset storages
    if (!assetStorages && assetStorageId && currentOrganizationId) {
      getAssetStorages(currentOrganizationId, { limit: BIG_LIMIT, offset: 0 })
    }
    if (assetStorages && assetStorageId) {
      this.setState({ selectedAssetStorage: assetStorages.find(aS => aS.id === assetStorageId) })
    }
    // If currently selected asset has userId, get users
    if (!users && userId && currentOrganizationId) {
      getUsers(currentOrganizationId, { limit: BIG_LIMIT, offset: 0, sort: 'last_name:asc' })
    }
    if (users && users.total && userId) {
      this.setState({ selectedUser: users.results.find(user => user.id === userId) })
    }
  }

  componentWillReceiveProps (nextProps) {
    // Received asset props from api
    if (!this.props.asset && nextProps.asset) {
      const userId = get(nextProps, 'asset.userId', '') || ''
      const assetStorageId = get(nextProps, 'asset.assetStorageId', '') || ''
      const selectedProperty = get(nextProps, 'asset.propertyId', '') || ''
      if (selectedProperty && selectedProperty.length) {
        this.props.getProperty(selectedProperty.id)
        this.props.getCustomer(selectedProperty.customerId)
      }

      this.setState({
        name: get(nextProps, 'asset.name', '') || '',
        identifier: get(nextProps, 'asset.identifier', '') || '',
        description: get(nextProps, 'asset.description', '') || '',
        type: get(nextProps, 'asset.type', '') || '',
        selectedProperty,
        initialUserId: userId,
        initialAssetStorageId: assetStorageId,
        initialHolder: userId ? ASSET_HOLDERS.USER : ASSET_HOLDERS.ASSET_STORAGE
      })
    }

    const { getAssetStorages, currentOrganizationId, assetStorages, users, getUsers } = this.props
    const { userId, assetStorageId, initialAssetStorageId, initialUserId } = this.state

    // Received current organization ID -> get users or asset storages depending on which id exists
    if (!assetStorages && currentOrganizationId && initialAssetStorageId) {
      getAssetStorages(nextProps.currentOrganizationId, { limit: BIG_LIMIT, offset: 0 })
    }
    if (!users && currentOrganizationId && initialUserId) {
      getUsers(nextProps.currentOrganizationId, { limit: BIG_LIMIT, offset: 0, sort: 'last_name:asc' })
    }
    // Received asset storages or users -> set currently selected according to existing id and remove it
    if (!assetStorageId && nextProps.assetStorages && initialAssetStorageId) {
      this.setState({ assetStorageId: nextProps.assetStorages.find(aS => aS.id === initialAssetStorageId).id, initialAssetStorageId: '' })
    }
    if (!userId && nextProps.users && nextProps.users.total && initialUserId) {
      this.setState({ userId: nextProps.users.results.find(user => user.id === initialUserId).id, initialUserId: '' })
    }
  }

  setName = evt => this.setState({ name: evt.target.value })

  setIdentifier = evt => this.setState({ identifier: evt.target.value })

  setDescription = evt => this.setState({ description: evt.target.value })

  setEditing = () => this.setState({ editing: true })

  isUpdateAssetDisabled = () => {
    const { userId, assetStorageId, editing } = this.state
    if (!editing) return false
    if (!userId && !assetStorageId) return true
    const required = [FIELDS.NAME]
    return isEditDisabled(this.props.asset, this.state, required)
  }

  updateAsset = () => {
    const { userId, assetStorageId } = this.state
    const { asset, updateAsset } = this.props
    const fields = {
      name: this.state.name.trim(),
      description: this.state.description.trim() || null,
      type: this.state.type.trim() || null,
      selectedProperty: this.state.selectedProperty || null
    }
    // Set other id as null as not to have two holders for the asset
    if (userId) {
      fields.userId = userId
      fields.assetStorageId = null
    }
    if (assetStorageId) {
      fields.assetStorageId = assetStorageId
      fields.userId = null
    }
    updateAsset(asset.id, fields)
    this.setState({ editing: false })
  }

  onSelectCustomerChange = evt => {
    if (evt.target.value !== '') {
      this.props.getProperties(evt.target.value.id)
    }
    this.setState({ selectedCustomer: evt.target.value, selectedProperty: '' })
  }

  onSelectPropertyChange = evt => this.setState({ selectedProperty: evt.target.value })

  onSelectUserChange = evt => this.setState({ userId: evt.target.value, assetStorageId: '' })

  onSelectAssetStorageChange = evt => this.setState({ assetStorageId: evt.target.value, userId: '' })

  onInitialHolderChange = initialHolder => {
    const { getAssetStorages, getUsers, currentOrganizationId, assetStorages, users } = this.props
    if (initialHolder === ASSET_HOLDERS.ASSET_STORAGE && !assetStorages) {
      getAssetStorages(currentOrganizationId, { limit: BIG_LIMIT, offset: 0 })
    }
    if (initialHolder === ASSET_HOLDERS.USER && !users) {
      getUsers(currentOrganizationId, { limit: BIG_LIMIT, offset: 0, sort: 'last_name:asc' })
    }
    // Clear selected user and asset storage to avoid both having values at the same time
    this.setState({ initialHolder, userId: '', assetStorageId: '' })
  }

  getTransactions = (params) => {
    const { getAssetTransactions, match } = this.props
    getAssetTransactions(match.params.assetId, params)
  }

  renderPage = () => {
    const {
      t, asset, deactivateAsset, activateAsset, fetching, assetStorages, assetStoragesFetching, users, usersFetching,
      transactions, transactionsFetching, totalTransactions, setTransaction, ...rest
    } = this.props
    const { name, description, assetStorageId, userId, initialHolder, editing } = this.state
    const visibleUsers = users ? users.results : null

    return (
      <Layout>
        <PageHeader
          title={name}
          onClick={this.updateAsset}
          disabled={this.isUpdateAssetDisabled()}
          loading={fetching}
          setEditing={this.setEditing}
          editing={editing}
        />
        <AssetForm
          name={name}
          description={description}
          setName={this.setName}
          setDescription={this.setDescription}
          editing={editing}
        />
        <AssetHolderSelector
          header={t('holder')}
          isChecked={initialHolder}
          toggleHolder={this.onInitialHolderChange}
          onSelectUserChange={this.onSelectUserChange}
          onSelectAssetStorageChange={this.onSelectAssetStorageChange}
          selected={assetStorageId || userId}
          assetStorages={assetStorages}
          users={visibleUsers}
          loading={assetStoragesFetching || usersFetching}
          editing={editing}
        />
        <AssetInfo asset={asset} deactivate={deactivateAsset} activate={activateAsset} />
        <AssetTransactionLog
          transactions={transactions}
          loading={transactionsFetching}
          getTransactions={this.getTransactions}
          setTransaction={setTransaction}
          total={totalTransactions}
          editing={editing}
          {...rest}
        />
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = state => {
  return {
    asset: state.asset.asset,
    currentOrganizationId: state.user.currentOrganizationId,
    fetching: state.asset.fetching,
    users: state.user.users,
    token: state.user.token,
    error: state.notification.error,
    customer: state.customer.customer,
    property: state.property.property,
    assetStorages: state.assetStorage.assetStorages,
    transactions: state.assetTransaction.assetTransactions,
    transactionsFetching: state.assetTransaction.fetching,
    totalTransactions: state.assetTransaction.total,
    assetStoragesFetching: state.assetStorage.fetching,
    usersFetching: state.user.fetching
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAsset: assetId => dispatch(AssetActions.getAsset(assetId)),
    updateAsset: (assetId, fields) => dispatch(AssetActions.updateAsset(assetId, fields)),
    getCustomer: customerId => dispatch(CustomerActions.getCustomer(customerId)),
    getProperty: propertyId => dispatch(PropertyActions.getProperty(propertyId)),
    getUsers: (organizationId, params) => dispatch(UserActions.getUsers(organizationId, params)),
    getAssetStorages: (organizationId, params) =>
      dispatch(AssetStorageActions.getAssetStorages(organizationId, params)),
    getAssetTransactions: (assetId, params) =>
      dispatch(AssetTransactionActions.getAssetAssetTransactions(assetId, params)),
    setTransaction: (assetTransaction) => dispatch(AssetTransactionActions.setAssetTransaction(assetTransaction)),
    deactivateAsset: (assetId) => dispatch(AssetActions.deactivateAsset(assetId)),
    activateAsset: (assetId) => dispatch(AssetActions.activateAsset(assetId))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoot(withStyles(styles)(withTranslation()(AssetScreen))))
