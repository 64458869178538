import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import withRoot from '../utils/withRoot'
import OrganizationActions from '../redux/OrganizationRedux'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import OrganizationTable from '../components/Organization/OrganizationTable'
import { isSuperadmin } from '../utils/permissions'

const styles = theme => ({})

class OrganizationsScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      pageSize: 10,
      page: 0,
      search: ''
    }
    this.timeout = 0
  }

  componentDidMount () {
    const { pageSize, page } = this.state
    this.props.getOrganizations({ limit: pageSize, offset: page * pageSize })
  }

  handleSearchChange = evt => {
    const { page, pageSize } = this.state
    const searchValue = evt.target.value
    this.setState({ search: searchValue })

    if (this.timeout) clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.props.getOrganizations({
        limit: pageSize,
        offset: page * pageSize,
        search: searchValue
      })
    }, 300)
  }

  shouldHideButton = () => (this.props.user ? !isSuperadmin(this.props.user) : true)

  toCreateOrganization = () => this.props.history.push('/uusi-organisaatio')

  setPage = (evt, page) => {
    const { pageSize } = this.state
    this.props.getOrganizations({ limit: pageSize, offset: page * pageSize })
    this.setState({ page })
  }

  toOrganization = (organization, edit) => {
    this.props.setOrganization(organization)
    this.props.history.push({
      pathname: '/yrityksen-tiedot',
      state: {
        edit
      }
    })
  }

  renderPage = () => {
    const { t, user, total, organizations, fetching } = this.props
    const { page, pageSize, search } = this.state
    if (!isSuperadmin(user)) return <Redirect to='/dashboard' />
    return (
      <Layout>
        <PageHeader
          title={t('organizations')}
          onClick={this.toCreateOrganization}
          buttonText={t('create_organization')}
          hideButton={this.shouldHideButton()}
          listScreen
        />
        <OrganizationTable
          organizations={organizations}
          toSingle={this.toOrganization}
          loading={fetching}
          onChangePage={this.setPage}
          total={total}
          page={page}
          pageSize={pageSize}
          search={search}
          handleSearchChange={this.handleSearchChange}
        />
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = state => {
  return {
    fetching: state.organization.fetching,
    organizations: state.organization.organizations,
    total: state.organization.total,
    user: state.user.user,
    error: state.notification.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOrganizations: params => dispatch(OrganizationActions.getOrganizations(params)),
    setOrganization: organization => dispatch(OrganizationActions.setOrganization(organization))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoot(withStyles(styles)(withTranslation()(OrganizationsScreen))))
