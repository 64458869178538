import { takeLatest, all } from 'redux-saga/effects'
import API from '../services/api'

/* ------------- Types ------------- */

import { UserTypes } from '../redux/UserRedux'
import { AssetTypes } from '../redux/AssetRedux'
import { AssetStorageTypes } from '../redux/AssetStorageRedux'
import { CustomerTypes } from '../redux/CustomerRedux'
import { DomainTypes } from '../redux/DomainRedux'
import { OrganizationTypes } from '../redux/OrganizationRedux'
import { PropertyTypes } from '../redux/PropertyRedux'
import { AssetTransactionTypes } from '../redux/AssetTransactionRedux'

/* ------------- Sagas ------------- */

import {
  login,
  logout,
  getMe,
  updateUser,
  changePassword,
  requestPasswordReset,
  checkCodeValidity,
  resetPassword,
  getUsers,
  getTargetUser,
  createUser,
  updateTargetUser,
  addUserDomain,
  removeUserDomain,
  updateUserDomains,
  getExistingUserByEmail
} from './UserSagas'

import {
  getCustomerAssets,
  getPropertyAssets,
  getUserAssets,
  getAssetStorageAssets,
  getMyAssets,
  getAsset,
  createAsset,
  updateAsset,
  deactivateAsset,
  activateAsset
} from './AssetSagas'

import {
  getAssetStorages,
  getAssetStorage,
  createAssetStorage,
  updateAssetStorage,
  deactivateAssetStorage
} from './AssetStorageSagas'

import {
  createCustomer,
  getCustomer,
  getCustomers,
  updateCustomer,
  addCustomerDomain,
  removeCustomerDomain
} from './CustomerSagas'

import {
  getDomains,
  refreshDomains,
  createDomain,
  updateDomain
} from './DomainSagas'

import {
  setOrganization,
  getOrganization,
  getOrganizations,
  createOrganization,
  updateOrganization,
  deactivateOrganization
} from './OrganizationSagas'

import {
  getProperty,
  getProperties,
  createProperty,
  updateProperty,
  deactivateProperty
} from './PropertySagas'

import {
  getUserAssetTransactions,
  getAssetAssetTransactions
} from './AssetTransactionSagas'

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = API.create()

/* ------------- Connect Types To Sagas ------------- */

export default function * root () {
  yield all([
    /* User sagas */
    takeLatest(UserTypes.LOGIN, login, api),
    takeLatest(UserTypes.LOGOUT, logout),
    takeLatest(UserTypes.GET_ME, getMe, api),
    takeLatest(UserTypes.UPDATE_USER, updateUser, api),
    takeLatest(UserTypes.CHANGE_PASSWORD, changePassword, api),
    takeLatest(UserTypes.REQUEST_PASSWORD_RESET, requestPasswordReset, api),
    takeLatest(UserTypes.CHECK_CODE_VALIDITY, checkCodeValidity, api),
    takeLatest(UserTypes.RESET_PASSWORD, resetPassword, api),
    takeLatest(UserTypes.UPDATE_TARGET_USER, updateTargetUser, api),
    takeLatest(UserTypes.ADD_USER_DOMAIN, addUserDomain, api),
    takeLatest(UserTypes.REMOVE_USER_DOMAIN, removeUserDomain, api),
    takeLatest(UserTypes.UPDATE_USER_DOMAINS, updateUserDomains, api),
    takeLatest(UserTypes.GET_EXISTING_USER_BY_EMAIL, getExistingUserByEmail, api),
    takeLatest(UserTypes.GET_USERS, getUsers, api),
    takeLatest(UserTypes.GET_TARGET_USER, getTargetUser, api),
    takeLatest(UserTypes.CREATE_USER, createUser, api),
    /* Asset sagas */
    takeLatest(AssetTypes.GET_CUSTOMER_ASSETS, getCustomerAssets, api),
    takeLatest(AssetTypes.GET_PROPERTY_ASSETS, getPropertyAssets, api),
    takeLatest(AssetTypes.GET_USER_ASSETS, getUserAssets, api),
    takeLatest(AssetTypes.GET_ASSET_STORAGE_ASSETS, getAssetStorageAssets, api),
    takeLatest(AssetTypes.GET_MY_ASSETS, getMyAssets, api),
    takeLatest(AssetTypes.GET_ASSET, getAsset, api),
    takeLatest(AssetTypes.CREATE_ASSET, createAsset, api),
    takeLatest(AssetTypes.UPDATE_ASSET, updateAsset, api),
    takeLatest(AssetTypes.DEACTIVATE_ASSET, deactivateAsset, api),
    takeLatest(AssetTypes.ACTIVATE_ASSET, activateAsset, api),

    /* Asset storage sagas */
    takeLatest(AssetStorageTypes.GET_ASSET_STORAGES, getAssetStorages, api),
    takeLatest(AssetStorageTypes.GET_ASSET_STORAGE, getAssetStorage, api),
    takeLatest(AssetStorageTypes.CREATE_ASSET_STORAGE, createAssetStorage, api),
    takeLatest(AssetStorageTypes.UPDATE_ASSET_STORAGE, updateAssetStorage, api),
    takeLatest(AssetStorageTypes.DEACTIVATE_ASSET_STORAGE, deactivateAssetStorage, api),
    /* Customer sagas */
    takeLatest(CustomerTypes.CREATE_CUSTOMER, createCustomer, api),
    takeLatest(CustomerTypes.GET_CUSTOMER, getCustomer, api),
    takeLatest(CustomerTypes.GET_CUSTOMERS, getCustomers, api),
    takeLatest(CustomerTypes.UPDATE_CUSTOMER, updateCustomer, api),
    takeLatest(CustomerTypes.ADD_CUSTOMER_DOMAIN, addCustomerDomain, api),
    takeLatest(CustomerTypes.REMOVE_CUSTOMER_DOMAIN, removeCustomerDomain, api),
    /* Domain sagas */
    takeLatest(DomainTypes.GET_DOMAINS, getDomains, api),
    takeLatest(DomainTypes.REFRESH_DOMAINS, refreshDomains, api),
    takeLatest(DomainTypes.CREATE_DOMAIN, createDomain, api),
    takeLatest(DomainTypes.UPDATE_DOMAIN, updateDomain, api),
    /* Organization sagas */
    takeLatest(OrganizationTypes.SET_ORGANIZATION, setOrganization),
    takeLatest(OrganizationTypes.GET_ORGANIZATION, getOrganization, api),
    takeLatest(OrganizationTypes.GET_ORGANIZATIONS, getOrganizations, api),
    takeLatest(OrganizationTypes.CREATE_ORGANIZATION, createOrganization, api),
    takeLatest(OrganizationTypes.UPDATE_ORGANIZATION, updateOrganization, api),
    takeLatest(OrganizationTypes.DEACTIVATE_ORGANIZATION, deactivateOrganization, api),
    /* Property sagas */
    takeLatest(PropertyTypes.GET_PROPERTY, getProperty, api),
    takeLatest(PropertyTypes.GET_PROPERTIES, getProperties, api),
    takeLatest(PropertyTypes.CREATE_PROPERTY, createProperty, api),
    takeLatest(PropertyTypes.UPDATE_PROPERTY, updateProperty, api),
    takeLatest(PropertyTypes.DEACTIVATE_PROPERTY, deactivateProperty, api),
    /* Asset transaction sagas */
    takeLatest(AssetTransactionTypes.GET_USER_ASSET_TRANSACTIONS, getUserAssetTransactions, api),
    takeLatest(AssetTransactionTypes.GET_ASSET_ASSET_TRANSACTIONS, getAssetAssetTransactions, api)
  ])
}
