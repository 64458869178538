import { call, put, select } from 'redux-saga/effects'
import AssetTransactionActions from '../redux/AssetTransactionRedux'
import NotificationActions from '../redux/NotificationRedux'
import { composeError } from '../utils/transforms'

export function * getUserAssetTransactions (api, action) {
  const { userId, params } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.getUserAssetTransactions, token, userId, params)
  console.log(response)
  if (response.ok) {
    const assetTransactions = response.data.results
    const total = response.data.total
    yield put(AssetTransactionActions.getAssetTransactionsSuccess(assetTransactions, total))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetTransactionActions.getAssetTransactionsFailure())
  }
}

export function * getAssetAssetTransactions (api, action) {
  const { assetId, params } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.getAssetAssetTransactions, token, assetId, params)
  console.log(response)
  if (response.ok) {
    const assetTransactions = response.data.results
    const total = response.data.total
    yield put(AssetTransactionActions.getAssetTransactionsSuccess(assetTransactions, total))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetTransactionActions.getAssetTransactionsFailure())
  }
}
