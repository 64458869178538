import React from 'react'
import { withStyles, FormControlLabel, Checkbox, CircularProgress } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import SelectInput from '../SelectInput'
import { COLORS } from '../../utils/colors'
import { ASSET_HOLDERS } from '../../utils/constants'
import { addValueAndName } from '../../utils/transforms'

const styles = theme => ({
  container: {
    maxWidth: 1000,
    padding: '40px 60px 0px 60px'
  },
  header: {
    color: COLORS.white,
    fontSize: 24,
    margin: 0,
    marginBottom: 20,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  row: {
    display: 'flex',
    minHeight: 81,
    marginBottom: 30,
    position: 'relative'
  },
  smallRow: {
    display: 'flex',
    marginBottom: 30,
    maxWidth: 'calc(50% - 30px)'
  },
  label: {
    margin: 0,
    marginRight: 20,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  loading: {
    color: COLORS.white,
    position: 'absolute',
    left: '50%',
    top: '50%'
  },
  asterisk: {
    color: COLORS.red
  },
  columnLabel: {
    margin: 0,
    marginLeft: 15,
    marginBottom: 15,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 10,
      marginLeft: 5
    }
  },
  value: {
    margin: 0,
    marginLeft: 15,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginLeft: 5
    }
  }
})

class AssetHolderSelector extends React.PureComponent {
  toggleUser = () => this.props.toggleHolder(ASSET_HOLDERS.USER)

  toggleAssetStorage = () => this.props.toggleHolder(ASSET_HOLDERS.ASSET_STORAGE)

  userPlaceholder = () => {
    const { t, loading, users } = this.props
    if (!loading) {
      return users && users.length ? t('users') : t('users_empty')
    }
    return ''
  }

  assetStoragePlaceholder = () => {
    const { t, loading, assetStorages } = this.props
    if (!loading) {
      return assetStorages && assetStorages.length ? t('asset_storages') : t('asset_storages_empty')
    }
    return ''
  }

  renderUserSelect = () => {
    const { t, users, onSelectUserChange, isChecked, selected } = this.props
    const transformedUsers = users && addValueAndName(users)
    if (isChecked === ASSET_HOLDERS.USER) {
      return (
        <SelectInput
          options={users && users.length ? transformedUsers : []}
          label={t('select_user')}
          placeholder={this.userPlaceholder()}
          value={selected || ''}
          onChange={onSelectUserChange}
          disabled={!(users && users.length)}
        />
      )
    }
    return null
  }

  renderAssetStorageSelect = () => {
    const { t, assetStorages, onSelectAssetStorageChange, isChecked, selected } = this.props
    const transformedAssetStorages = assetStorages && addValueAndName(assetStorages)
    if (isChecked === ASSET_HOLDERS.ASSET_STORAGE) {
      return (
        <SelectInput
          options={assetStorages && assetStorages.length ? transformedAssetStorages : []}
          label={t('select_asset_storage')}
          placeholder={this.assetStoragePlaceholder()}
          value={selected}
          onChange={onSelectAssetStorageChange}
          disabled={!(assetStorages && assetStorages.length)}
        />
      )
    }
    return null
  }

  renderSelectors = () => {
    const { classes, loading, isChecked } = this.props
    if (isChecked.length) {
      return (
        <div className={classes.smallRow}>
          {loading && <CircularProgress className={classes.loading} size={30} />}
          {this.renderUserSelect()}
          {this.renderAssetStorageSelect()}
        </div>
      )
    }
    return null
  }

  renderCheckboxes = () => {
    const { t, classes, isChecked } = this.props
    return (
      <div className={classes.smallRow}>
        <FormControlLabel
          control={<Checkbox checked={isChecked === ASSET_HOLDERS.USER} onChange={this.toggleUser} />}
          label={t('user')}
          classes={{ label: classes.label }}
        />
        <div className={classes.spacer} />
        <FormControlLabel
          control={<Checkbox checked={isChecked === ASSET_HOLDERS.ASSET_STORAGE} onChange={this.toggleAssetStorage} />}
          label={t('asset_storage')}
          classes={{ label: classes.label }}
        />
      </div>
    )
  }

  getLabel = () => {
    const { t, isChecked } = this.props
    if (isChecked === ASSET_HOLDERS.USER) return t('user')
    return t('asset_storage')
  }

  getSelectedText = () => {
    const { selected, users, assetStorages, isChecked } = this.props
    if (isChecked === ASSET_HOLDERS.USER && users && users.length) {
      const user = users.find(user => user.id === selected)
      if (user) return `${user.firstName} ${user.lastName}`
      return null
    }
    if (isChecked === ASSET_HOLDERS.ASSET_STORAGE && assetStorages && assetStorages.length) {
      const assetStorage = assetStorages.find(assetStorage => assetStorage.id === selected)
      if (assetStorage) return assetStorage.name
      return null
    }
    return null
  }

  renderContent = () => {
    const { classes, editing } = this.props
    if (editing) {
      return (
        <div>
          {this.renderCheckboxes()}
          {this.renderSelectors()}
        </div>
      )
    }
    return (
      <div className={classes.smallRow}>
        <div className={classes.column}>
          <p className={classes.columnLabel}>{this.getLabel()}</p>
          <p className={classes.value}>{this.getSelectedText()}</p>
        </div>
      </div>
    )
  }

  render () {
    const { classes, header, editing } = this.props
    return (
      <div className={classes.container}>
        <p className={classes.header}>
          {header}
          {editing && <span className={classes.asterisk}>*</span>}
        </p>
        {this.renderContent()}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(AssetHolderSelector))
