import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import CircularProgress from '@material-ui/core/CircularProgress'
import TableFooter from '../TableFooter'
import AssetStorageAssetTableHeader from './AssetStorageAssetTableHeader'
import AssetStorageAssetTableRow from './AssetStorageAssetTableRow'
import TableMessage from '../TableMessage'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  tableContainer: {
    backgroundColor: COLORS.darkBackground,
    position: 'relative'
  },
  root: {
    alignSelf: 'center',
    width: '100%',
    overflowX: 'auto',
    maxWidth: 1200,
    backgroundColor: COLORS.darkBackground,
    position: 'relative'
  },
  table: {
    minWidth: 600
  },
  rounded: {
    border: `0.5px solid ${COLORS.border}`
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: COLORS.white
  }
})

class AssetStorageAssetTable extends React.PureComponent {
  renderRows = () => {
    const { assets, editAsset } = this.props
    return assets.map((asset) => (
      <AssetStorageAssetTableRow key={asset.id} asset={asset} editAsset={editAsset} />
    ))
  }

  renderFooter = () => {
    const { total, page, pageSize, onChangePage } = this.props
    if (total < pageSize) return null
    return (
      <TableFooter page={page} pageSize={pageSize} onChangePage={onChangePage} total={total} />
    )
  }

  renderTable = () => {
    const { t, classes, assets, loading } = this.props
    if (assets && assets.length) {
      return (
        <Paper className={classes.root} classes={{ rounded: classes.rounded }} elevation={0}>
          <Table className={classes.table} padding='none'>
            <AssetStorageAssetTableHeader />
            <TableBody>
              {this.renderRows()}
            </TableBody>
          </Table>
        </Paper>
      )
    }
    if (loading) return <CircularProgress className={classes.loading} size={30} />
    return <TableMessage message={t('no_asset_storage_assets')} smallFont />
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.tableContainer}>
        {this.renderTable()}
        {this.renderFooter()}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(AssetStorageAssetTable))
