import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import CircularProgress from '@material-ui/core/CircularProgress'
import TableFooter from '../TableFooter'
import PropertyTableHeader from './PropertyTableHeader'
import PropertyTableRow from './PropertyTableRow'
import TableMessage from '../TableMessage'
import { COLORS } from '../../utils/colors'
import Input from '../Input'

const styles = theme => ({
  tableContainer: {
    backgroundColor: COLORS.darkBackground,
    position: 'relative'
  },
  root: {
    alignSelf: 'center',
    width: '100%',
    overflowX: 'auto',
    maxWidth: 1200,
    backgroundColor: COLORS.darkBackground,
    position: 'relative'
  },
  table: {
    minWidth: 600
  },
  rounded: {
    border: `0.5px solid ${COLORS.border}`
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: COLORS.white
  },
  search: {
    width: 270,
    marginBottom: 24
  }
})

class PropertyTable extends React.PureComponent {
  renderSearch = () => {
    const { t, classes, search, handleSearchChange } = this.props
    return (
      <div className={classes.search}>
        <Input value={search} onChange={handleSearchChange} placeholder={t('search_property')} search />
      </div>
    )
  }

  renderRows = () => {
    const { properties, toSingle } = this.props
    return properties.map((property) => (
      <PropertyTableRow key={property.id} property={property} toSingle={toSingle} />
    ))
  }

  renderFooter = () => {
    const { total, page, pageSize, onChangePage } = this.props
    if (total < pageSize) return null
    return (
      <TableFooter page={page} pageSize={pageSize} onChangePage={onChangePage} total={total} />
    )
  }

  renderTable = () => {
    const { t, classes, properties, loading } = this.props
    if (properties && properties.length) {
      return (
        <Paper className={classes.root} classes={{ rounded: classes.rounded }} elevation={0}>
          <Table className={classes.table} padding='none'>
            <PropertyTableHeader />
            <TableBody>
              {this.renderRows()}
            </TableBody>
          </Table>
        </Paper>
      )
    }
    if (loading) return <CircularProgress className={classes.loading} size={30} />
    return <TableMessage message={t('no_properties')} smallFont />
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.tableContainer}>
        {this.renderSearch()}
        {this.renderTable()}
        {this.renderFooter()}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(PropertyTable))
