import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../utils/withRoot'
import CustomerActions from '../redux/CustomerRedux'
import DomainActions from '../redux/DomainRedux'
import PropertyActions from '../redux/PropertyRedux'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import CustomerInfoForm from '../components/Customer/CustomerInfoForm'
import CustomerContactInfoForm from '../components/Customer/CustomerContactInfoForm'
import CustomerDomainForm from '../components/Customer/CustomerDomainForm'
import { BIG_LIMIT, FIELDS } from '../utils/constants'
import CustomerProperties from '../components/Property/CustomerProperties'
import { isAdminLevelUser, isEditDisabled } from '../utils/permissions'

const styles = theme => ({
})

class CustomerScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: get(props, 'customer.name') || '',
      businessId: get(props, 'customer.businessId') || '',
      contactPerson: get(props, 'customer.contactPerson') || '',
      phone: get(props, 'customer.phone') || '',
      email: get(props, 'customer.email') || '',
      url: get(props, 'customer.url') || '',
      address: get(props, 'customer.address') || '',
      city: get(props, 'customer.city') || '',
      postalCode: get(props, 'customer.postalCode') || '',
      description: get(props, 'customer.description') || '',
      editing: props.location.state && props.location.state.edit
    }
  }

  componentWillReceiveProps (nextProps) {
    const { customer, currentOrganizationId } = this.props
    // Received customer props from api
    if (!customer && nextProps.customer) {
      this.setState({
        name: get(nextProps, 'customer.name') || '',
        businessId: get(nextProps, 'customer.businessId') || '',
        contactPerson: get(nextProps, 'customer.contactPerson') || '',
        phone: get(nextProps, 'customer.phone') || '',
        email: get(nextProps, 'customer.email') || '',
        url: get(nextProps, 'customer.url') || '',
        address: get(nextProps, 'customer.address') || '',
        city: get(nextProps, 'customer.city') || '',
        postalCode: get(nextProps, 'customer.postalCode') || '',
        description: get(nextProps, 'customer.description') || ''
      })
    }
    if (!currentOrganizationId && nextProps.currentOrganizationId) {
      this.props.getAllDomains(nextProps.currentOrganizationId)
    }
  }

  componentDidMount () {
    const { customer, match, currentOrganizationId } = this.props
    if (!customer) this.props.getCustomer(match.params.customerId)
    if (currentOrganizationId) this.props.getAllDomains(currentOrganizationId)
    this.props.getProperties(match.params.customerId)
  }

  setName = (evt) => this.setState({ name: evt.target.value })

  setBusinessId = (evt) => this.setState({ businessId: evt.target.value })

  setContactPerson = (evt) => this.setState({ contactPerson: evt.target.value })

  setPhone = (evt) => this.setState({ phone: evt.target.value })

  setEmail = (evt) => this.setState({ email: evt.target.value })

  setUrl = (evt) => this.setState({ url: evt.target.value })

  setAddress = (evt) => this.setState({ address: evt.target.value })

  setCity = (evt) => this.setState({ city: evt.target.value })

  setPostalCode = (evt) => this.setState({ postalCode: evt.target.value })

  setDescription = (evt) => this.setState({ description: evt.target.value })

  setEditing = () => this.setState({ editing: true })

  canEditDomains = () => isAdminLevelUser(this.props.user)

  updateCustomer = () => {
    const { id } = this.props.customer
    const fields = {
      name: this.state.name.trim(),
      businessId: this.state.businessId.trim() || null,
      contactPerson: this.state.contactPerson.trim() || null,
      phone: this.state.phone.trim() || null,
      email: this.state.email.trim() || null,
      url: this.state.url.trim() || null,
      address: this.state.address.trim() || null,
      city: this.state.city.trim() || null,
      postalCode: this.state.postalCode.trim() || null,
      description: this.state.description.trim() || null
    }
    this.props.updateCustomer(id, fields)
    this.setState({ editing: false })
  }

  isUpdateCustomerDisabled = () => {
    const { editing } = this.state
    if (!editing) return false
    const required = [
      FIELDS.NAME,
      FIELDS.ADDRESS,
      FIELDS.POSTAL_CODE,
      FIELDS.CITY,
      FIELDS.BUSINESS_ID,
      FIELDS.CONTACT_PERSON,
      FIELDS.PHONE,
      FIELDS.EMAIL
    ]
    return isEditDisabled(this.props.customer, this.state, required)
  }

  renderPage = () => {
    const {
      t, fetching, domains, customer, addCustomerDomain, removeCustomerDomain,
      properties, totalProperties, getProperties, setProperty, ...rest
    } = this.props
    const allDomains = domains ? domains.results : null
    const {
      name, businessId, contactPerson, phone, email, url, address, city, postalCode, description, editing
    } = this.state

    return (
      <Layout>
        <PageHeader
          title={name}
          onClick={this.updateCustomer}
          disabled={this.isUpdateCustomerDisabled()}
          loading={fetching}
          editing={editing}
          setEditing={this.setEditing}
        />
        <CustomerInfoForm
          name={name}
          businessId={businessId}
          description={description}
          setName={this.setName}
          setBusinessId={this.setBusinessId}
          setDescription={this.setDescription}
          editing={editing}
        />
        <CustomerContactInfoForm
          contactPerson={contactPerson}
          phone={phone}
          email={email}
          url={url}
          address={address}
          city={city}
          postalCode={postalCode}
          setContactPerson={this.setContactPerson}
          setPhone={this.setPhone}
          setEmail={this.setEmail}
          setUrl={this.setUrl}
          setAddress={this.setAddress}
          setCity={this.setCity}
          setPostalCode={this.setPostalCode}
          editing={editing}
        />
        <CustomerDomainForm
          editEnabled={this.canEditDomains()}
          allDomains={allDomains}
          customer={customer}
          addCustomerDomain={addCustomerDomain}
          removeCustomerDomain={removeCustomerDomain}
          editing={editing}
        />
        <CustomerProperties
          properties={properties}
          total={totalProperties}
          getProperties={getProperties}
          setProperty={setProperty}
          {...rest}
        />
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: state.user.fetching,
    error: state.notification.error,
    customer: state.customer.customer,
    domains: state.domain.domains,
    currentOrganizationId: state.user.currentOrganizationId,
    properties: state.property.properties,
    totalProperties: state.property.total,
    user: state.user.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomer: (customerId) => dispatch(CustomerActions.getCustomer(customerId)),
    updateCustomer: (customerId, fields) => dispatch(CustomerActions.updateCustomer(customerId, fields)),
    getAllDomains: (organizationId) => dispatch(DomainActions.getDomains(organizationId, { offset: 0, limit: BIG_LIMIT })),
    addCustomerDomain: (customerId, domainId) => dispatch(CustomerActions.addCustomerDomain(customerId, domainId)),
    removeCustomerDomain: (customerId, domainId) => dispatch(CustomerActions.removeCustomerDomain(customerId, domainId)),
    getProperties: (customerId, params) => dispatch(PropertyActions.getProperties(customerId, params)),
    setProperty: (property) => dispatch(PropertyActions.setProperty(property))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRoot(withStyles(styles)(withTranslation()(CustomerScreen)))
)
