import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import withRoot from '../utils/withRoot'
import MAPS from '../services/mapsApi'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import AssetTransactionDetails from '../components/AssetTransaction/AssetTransactionDetails'
import AssetTransactionMap from '../components/AssetTransaction/AssetTransactionMap'

const styles = theme => ({})

const api = MAPS.create()

class AssetTransactionScreen extends React.Component {
  state = {
    address: ''
  }

  componentDidMount () {
    this.getAddress()
  }

  getAddress = async () => {
    const { transaction } = this.props
    try {
      if (transaction && transaction.latitude && transaction.longitude) {
        const response = await api.getPlaceByCoords(transaction.latitude, transaction.longitude)
        if (response.ok) {
          const formattedAddress = response.data.results[0].formatted_address
          const snippedCountry = formattedAddress.split(', ').slice(0, -1).join(', ')
          this.setState({ address: snippedCountry })
        }
      } else {
        this.setState({ address: '-' })
      }
    } catch (e) {
      this.setState({ address: '-' })
    }
  }

  formatTransactionTime = () => {
    const { t, transaction } = this.props
    return moment(transaction.createdAt).format(`DD.MM.YYYY [${t('at')}] HH:mm`)
  }

  renderMap = () => {
    const { transaction } = this.props
    if (transaction.longitude && transaction.latitude) {
      return <AssetTransactionMap longitude={transaction.longitude} latitude={transaction.latitude} />
    }
    return null
  }

  render () {
    const { transaction } = this.props
    if (!transaction) return <Redirect to='/dashboard' />
    return (
      <Layout>
        <PageHeader title={this.formatTransactionTime()} hideButton />
        {this.renderMap()}
        <AssetTransactionDetails transaction={transaction} address={this.state.address} />
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    transaction: state.assetTransaction.assetTransaction
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoot(withStyles(styles)(withTranslation()(AssetTransactionScreen))))
