import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getUserAssetTransactions: ['userId', 'params'],
  getAssetAssetTransactions: ['assetId', 'params'],
  getAssetTransactionsSuccess: ['assetTransactions', 'total'],
  getAssetTransactionsFailure: null,
  setAssetTransaction: ['assetTransaction']
})

export const AssetTransactionTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  assetTransaction: null,
  assetTransactions: null,
  total: null
})

/* ------------- Reducers ------------- */

const getUserAssetTransactions = state => state.merge({ fetching: true })
const getAssetAssetTransactions = state => state.merge({ fetching: true })
const getAssetTransactionsSuccess = (state, { assetTransactions, total }) => state.merge({ assetTransactions, total, fetching: false })
const getAssetTransactionsFailure = state => state.merge({ fetching: false })

const setAssetTransaction = (state, { assetTransaction }) => state.merge({ assetTransaction })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USER_ASSET_TRANSACTIONS]: getUserAssetTransactions,
  [Types.GET_ASSET_ASSET_TRANSACTIONS]: getAssetAssetTransactions,
  [Types.GET_ASSET_TRANSACTIONS_SUCCESS]: getAssetTransactionsSuccess,
  [Types.GET_ASSET_TRANSACTIONS_FAILURE]: getAssetTransactionsFailure,
  [Types.SET_ASSET_TRANSACTION]: setAssetTransaction
})
