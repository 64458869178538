import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '../static/haku.svg'
import { COLORS } from '../utils/colors'

const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  label: {
    margin: 0,
    marginLeft: 15,
    marginBottom: 10,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 10,
      marginLeft: 5
    }
  },
  input: {
    height: 50,
    fontFamily: 'Roboto',
    fontSize: 16,
    color: COLORS.white,
    backgroundColor: COLORS.dark,
    borderRadius: 3,
    border: `1px solid ${COLORS.border}`,
    padding: '0px 15px',
    boxSizing: 'border-box',
    '&::placeholder': {
      color: COLORS.inputPlaceholder
    },
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 40,
      fontSize: 14
    }
  },
  multiline: {
    minHeight: 180,
    fontFamily: 'Roboto',
    fontSize: 18,
    color: COLORS.white,
    backgroundColor: COLORS.dark,
    borderRadius: 3,
    border: `1px solid ${COLORS.border}`,
    padding: 15,
    boxSizing: 'border-box',
    '&::placeholder': {
      color: COLORS.placeholder
    },
    [theme.breakpoints.down('sm')]: {
      height: 80,
      fontSize: 14
    }
  },
  asterisk: {
    color: COLORS.red
  },
  search: {
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 14,
    color: COLORS.white,
    backgroundColor: COLORS.dark,
    borderRadius: 3,
    border: `1px solid ${COLORS.border}`,
    padding: '0px 15px',
    boxSizing: 'border-box',
    paddingRight: 40,
    '&::placeholder': {
      color: COLORS.inputPlaceholder
    },
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 30,
      fontSize: 12
    }
  },
  searchAdornment: {
    position: 'absolute',
    right: 6,
    bottom: -3
  },
  icon: {
    height: 20
  }
})

class Input extends React.PureComponent {
  getInputClassName = () => {
    const { classes, whiteBackground, multiline, search } = this.props
    if (search) return classes.search
    if (whiteBackground) {
      return multiline ? classes.whiteMultiline : classes.whiteInput
    }
    return multiline ? classes.multiline : classes.input
  }

  renderLabel = () => {
    const { required, label, classes, search } = this.props
    const asterisk = required ? '*' : null
    if (!search) {
      return (
        <p className={classes.label}>
          {label} <span className={classes.asterisk}>{asterisk}</span>
        </p>
      )
    }
    return null
  }

  renderAdornment = () => {
    const { t, classes, search } = this.props
    if (search) {
      return (
        <InputAdornment position='end'>
          <IconButton className={classes.searchAdornment} aria-label={t('search')}>
            <img className={classes.icon} src={SearchIcon} alt={t('search')} />
          </IconButton>
        </InputAdornment>
      )
    }
    return null
  }

  render () {
    const {
      classes, id, label, value, onChange, placeholder, width, type, rows, rowsMax,
      multiline, maxLength, max, min, autoFocus, required, disabled
    } = this.props
    const styles = width ? { width } : {}
    return (
      <div className={classes.container} style={styles}>
        {this.renderLabel()}
        <TextField
          id={id}
          value={value}
          onChange={onChange}
          placeholder={placeholder || label}
          type={type || 'text'}
          rows={rows}
          rowsMax={rowsMax}
          multiline={multiline}
          autoFocus={autoFocus}
          required={required}
          disabled={disabled}
          InputProps={{
            disableUnderline: true,
            classes: { input: this.getInputClassName() },
            endAdornment: (this.renderAdornment()),
            maxLength,
            max,
            min
          }}
        />
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(Input))
