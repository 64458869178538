import { call, put, select } from 'redux-saga/effects'
import PropertyActions from '../redux/PropertyRedux'
import NotificationActions from '../redux/NotificationRedux'
import { composeError } from '../utils/transforms'

export function * createProperty (api, action) {
  const { customerId, fields } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.createProperty, token, customerId, fields)
  if (response.ok) {
    yield put(PropertyActions.createPropertyFinished())
    yield put(NotificationActions.setSuccess('create_property_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(PropertyActions.createPropertyFinished())
  }
}

export function * getProperties (api, action) {
  const { customerId, params } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.getProperties, token, customerId, params)
  if (response.ok) {
    const properties = response.data.results
    const total = response.data.total
    yield put(PropertyActions.getPropertiesSuccess(properties, total))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(PropertyActions.getPropertiesFailure())
  }
}

export function * getProperty (api, action) {
  const { propertyId } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.getProperty, token, propertyId)
  if (response.ok) {
    const property = response.data
    yield put(PropertyActions.getPropertySuccess(property))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(PropertyActions.getPropertyFailure())
  }
}

export function * updateProperty (api, action) {
  const { propertyId, fields } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.updateProperty, token, propertyId, fields)
  if (response.ok) {
    const property = response.data
    yield put(PropertyActions.updatePropertySuccess(property))
    yield put(NotificationActions.setSuccess('update_property_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(PropertyActions.updatePropertyFailure())
  }
}

export function * deactivateProperty (api, action) {
  const { propertyId } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.deactivateProperty, token, propertyId)
  if (response.ok) {
    const property = response.data
    yield put(PropertyActions.deactivatePropertySuccess(property))
    yield put(NotificationActions.setSuccess('deactivate_property_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(PropertyActions.deactivatePropertyFailure())
  }
}
