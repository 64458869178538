import Immutable from 'seamless-immutable'
import i18next from 'i18next'
import { languages } from '../i18n'
import { CLIENT_ERROR, CLIENT_CONNECTION_ERRORS, ROLES } from './constants'
import capitalize from 'lodash/capitalize'

/** Return formatted error object based on API response */
export const composeError = (response) => {
  const error = { status: response.status }
  if (response.status === 401) {
    error.key = 'unauthorized'
    error.message = 'try_again'
  }
  if (response.problem === CLIENT_ERROR) {
    error.key = 'client_error'
    error.message = 'try_again'
  } else if (CLIENT_CONNECTION_ERRORS.includes(response.problem)) {
    error.key = 'network_error'
    error.message = 'general_error_message'
  } else {
    error.key = 'server_error'
    error.message = 'general_error_message'
  }
  return error
}

/** Get current language */
export const getCurrentLang = () => {
  const availableLanguages = languages.map(({ value }) => value)

  if (i18next.language !== undefined) {
    const currentLang = i18next.language.substr(0, 2)
    if (availableLanguages.includes(currentLang)) {
      return currentLang
    }
  }
  return languages[0].value // Fallback to default lang
}

export const buildAddress = (object) => {
  if (!object.address && !object.postalCode && !object.city) return '-'
  return `${capitalize(object.address || '-')}, ${capitalize(object.postalCode || '-')} ${capitalize(object.city || '-')}`
}

export const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email)
export const isValidBusinessId = (businessId) => /^\d{7}-\d{1}$/.test(businessId)

export const isDomainAdmin = (user) => {
  if (!user || !user.domains || !user.domains.length) return false
  return user.domains.some((domain) => domain.role === ROLES.ADMIN)
}

export const addValueAndName = (array) => {
  if (!array.length) return array
  if (array[0].name) return array.map(item => ({ ...item, value: item.id }))
  return array.map(item => ({ ...item, value: item.id, name: `${item.firstName} ${item.lastName}` }))
}

export const isEqualArray = (arr1, arr2) => {
  if (arr1 && arr2 && arr1.length && arr2.length) {
    return arr1.length === arr2.length && Immutable.asMutable(arr1).sort().every((value, index) => value === Immutable.asMutable(arr2).sort()[index])
  }
  return true
}

export const formGooglePlacesAddress = (streetAddress, postalCode, city) => {
  return `${streetAddress.replace(' ', '+')},+${postalCode},+${city}`
}
