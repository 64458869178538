import { call, put, select } from 'redux-saga/effects'
import DomainActions from '../redux/DomainRedux'
import NotificationActions from '../redux/NotificationRedux'
import { composeError } from '../utils/transforms'

export function * getDomains (api, action) {
  const { organizationId, params } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.getDomains, token, organizationId, params)
  if (response.ok) {
    const domains = response.data
    yield put(DomainActions.getDomainsSuccess(domains))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(DomainActions.getDomainsFailure())
  }
}

export function * refreshDomains (api, action) {
  const { previousParams, previousOrganizationId } = yield select((state) => state.domain)
  yield put(DomainActions.getDomains(previousOrganizationId, previousParams))
}

export function * createDomain (api, action) {
  const { organizationId, fields } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.createDomain, token, organizationId, fields)
  console.log(response)
  if (response.ok) {
    yield put(DomainActions.createDomainSuccess())
    yield put(NotificationActions.setSuccess('create_domain_success'))
    yield put(DomainActions.refreshDomains())
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(DomainActions.createDomainFailure())
  }
}

export function * updateDomain (api, action) {
  const { domainId, fields } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.updateDomain, token, domainId, fields)
  console.log(response)
  if (response.ok) {
    yield put(DomainActions.updateDomainSuccess())
    yield put(NotificationActions.setSuccess('update_domain_success'))
    yield put(DomainActions.refreshDomains())
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(DomainActions.updateDomainFailure())
  }
}
