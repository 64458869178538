import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import LoginInput from './LoginInput'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  container: {
    maxWidth: 600
  },
  title: {
    color: COLORS.white,
    margin: 0,
    marginBottom: 30,
    fontSize: 42,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
      marginBottom: 20
    }
  },
  infoText: {
    margin: 0,
    marginBottom: 30,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 20
    }
  },
  button: {
    color: COLORS.white,
    background: COLORS.violet, // `radial-gradient(${COLORS.violetGradientStart} 0%, ${COLORS.violetGradientEnd} 100%)`,
    '&:hover': {
      background: darken(COLORS.violet, 0.2)
    },
    width: 200,
    height: 56,
    marginTop: 10,
    borderRadius: 5,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 48,
      width: 150,
      borderRadius: 5
    }
  },
  buttonText: {
    margin: 0,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: '700',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  disabledButton: {
    background: COLORS.disabledButton
  },
  loading: {
    color: COLORS.white
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  link: {
    marginTop: 15,
    marginRight: 30,
    fontFamily: 'Roboto',
    fontSize: 16,
    color: COLORS.dark
  }
})

class ResetPasswordForm extends React.PureComponent {
  renderButtonContent = () => {
    const { classes, t, loading } = this.props
    if (loading) return <CircularProgress className={classes.loading} size={22} />
    return <p className={classes.buttonText}>{t('save')}</p>
  }

  isDisabled = () => {
    const { password, passwordAgain } = this.props
    return !password || !passwordAgain || password !== passwordAgain || password.length < 8
  }

  render () {
    const { classes, t, onSubmit, password, passwordAgain, setPassword, setPasswordAgain } = this.props
    return (
      <div className={classes.container}>
        <h2 className={classes.title}>{t('set_password')}</h2>
        <p className={classes.infoText}>{t('change_password_info')}</p>
        <LoginInput
          placeholder={t('password')}
          value={password}
          onChange={setPassword}
          type='password'
          autoFocus
        />
        <LoginInput
          placeholder={t('password_again')}
          value={passwordAgain}
          onChange={setPasswordAgain}
          type='password'
        />
        <div className={classes.bottomContainer}>
          <Button
            className={classes.button}
            onClick={onSubmit}
            disabled={this.isDisabled()}
            classes={{ disabled: classes.disabledButton }}
          >
            {this.renderButtonContent()}
          </Button>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(ResetPasswordForm))
