import apisauce from 'apisauce'
import { formGooglePlacesAddress } from '../utils/transforms'

const AUTH = 'key=AIzaSyAOSHg6Tora8RKmzFrP-8rEqRxRq829gZE'
const LANG = 'language=fi'

const create = (baseURL = 'https://maps.googleapis.com/maps/api/') => {
  const api = apisauce.create({
    baseURL,
    headers: {
    },
    timeout: 10000
  })

  const getPlaceByCoords = (lat, lng) => api.get(
    `geocode/json?latlng=${lat},${lng}&${LANG}&${AUTH}`,
    {},
    {}
  )

  const getCoordsByPlace = (streetAddress, postalCode, city) => api.get(
    `geocode/json?address=${formGooglePlacesAddress(streetAddress, postalCode, city)}&${AUTH}`,
    {},
    {}
  )

  return {
    getPlaceByCoords,
    getCoordsByPlace
  }
}

export default {
  create
}
