import { call, put, select } from 'redux-saga/effects'
import AssetStorageActions from '../redux/AssetStorageRedux'
import NotificationActions from '../redux/NotificationRedux'
import { composeError } from '../utils/transforms'

export function * getAssetStorages (api, action) {
  const { organizationId, params } = action
  const { token } = yield select(state => state.user)
  // TODO: In case user is superadmin, use the currently selected Organization ID instead
  const response = yield call(api.getAssetStorages, token, organizationId, params)
  console.log(response)
  if (response.ok) {
    const assetStorages = response.data.results
    const total = response.data.total
    yield put(AssetStorageActions.getAssetStoragesSuccess(assetStorages, total))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetStorageActions.getAssetStoragesFailure())
  }
}

export function * getAssetStorage (api, action) {
  const { id } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.getAssetStorage, token, id)
  console.log(response)
  if (response.ok) {
    const assetStorage = response.data
    yield put(AssetStorageActions.getAssetStorageSuccess(assetStorage))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetStorageActions.getAssetStorageFailure())
  }
}

export function * createAssetStorage (api, action) {
  const { organizationId, fields } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.createAssetStorage, token, organizationId, fields)
  console.log(response)
  if (response.ok) {
    yield put(AssetStorageActions.createAssetStorageFinished())
    yield put(NotificationActions.setSuccess('create_asset_storage_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetStorageActions.createAssetStorageFinished())
  }
}

export function * updateAssetStorage (api, action) {
  const { id, fields } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.updateAssetStorage, token, id, fields)
  console.log(response)
  if (response.ok) {
    const assetStorage = response.data
    yield put(AssetStorageActions.updateAssetStorageSuccess(assetStorage))
    yield put(NotificationActions.setSuccess('update_asset_storage_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetStorageActions.updateAssetStorageFailure())
  }
}

export function * deactivateAssetStorage (api, action) {
  const { id } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.deactivateAssetStorage, token, id)
  console.log(response)
  if (response.ok) {
    const assetStorage = response.data
    yield put(AssetStorageActions.deactivateAssetStorageSuccess(assetStorage))
    yield put(NotificationActions.setSuccess('deactivate_asset_storage_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetStorageActions.deactivateAssetStorageFailure())
  }
}
