import React from 'react'
import i18next from 'i18next'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import LogoutIcon from '../static/kirjaudu-ulos.svg'
import { getCurrentLang, isDomainAdmin } from '../utils/transforms'
import { COLORS } from '../utils/colors'

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  navItem: {
    borderRadius: 0,
    minWidth: 66,
    height: 56,
    padding: '0px 20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderLeft: `0.5px solid ${COLORS.border}`
  },
  lang: {
    color: COLORS.white,
    fontFamily: 'Chivo',
    fontSize: 14,
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  menuItem: {
    color: COLORS.dark,
    fontFamily: 'Chivo',
    fontSize: 14,
    fontWeight: '700'
  },
  user: {
    margin: 0,
    marginBottom: 2,
    color: COLORS.white,
    fontFamily: 'Chivo',
    fontSize: 14,
    fontWeight: '700'
  },
  role: {
    margin: 0,
    color: COLORS.white,
    fontFamily: 'Chivo',
    fontSize: 12
  },
  logoutButton: {
    borderRadius: 0,
    height: 56,
    width: 66
  },
  icon: {
    color: COLORS.white
  },
  currentUser: {
    cursor: 'pointer'
  }
})

class Header extends React.PureComponent {
  state = {
    anchorEl: null
  }

  handleMenuClick = (event) => this.setState({ anchorEl: event.currentTarget })

  handleMenuClose = () => this.setState({ anchorEl: null })

  changeLanguage = (lang) => {
    this.handleMenuClose()
    i18next.changeLanguage(lang, (err) => console.log(err))
  }

  changeLangFi = () => this.changeLanguage('fi')

  changeLangEn = () => this.changeLanguage('en')

  toUserScreen = () => this.props.toUserScreen(this.props.user)

  getRole = () => {
    const { t, user } = this.props
    if (isDomainAdmin(user)) return t('domain_admin')
    return t(user.role)
  }

  renderLangMenu = () => {
    const { classes, t } = this.props
    const { anchorEl } = this.state
    return (
      <Menu id='lang-menu' anchorEl={anchorEl} open={!!anchorEl} onClose={this.handleMenuClose}>
        <MenuItem classes={{ root: classes.menuItem }} onClick={this.changeLangFi}>
          {t('fi')}
        </MenuItem>
        <MenuItem classes={{ root: classes.menuItem }} onClick={this.changeLangEn}>
          {t('en')}
        </MenuItem>
      </Menu>
    )
  }

  render () {
    const { classes, t, user, logout } = this.props
    const { anchorEl } = this.state
    return (
      <div className={classes.root}>
        <IconButton
          aria-owns={anchorEl ? 'lang-menu' : null}
          aria-haspopup='true'
          onClick={this.handleMenuClick}
          className={classes.navItem}
        >
          <p className={classes.lang}>{getCurrentLang()}</p>
        </IconButton>
        {this.renderLangMenu()}
        <div className={classes.navItem}>
          <div className={classes.currentUser} onClick={this.toUserScreen} role='button'>
            <p className={classes.user}>{user.firstName} {user.lastName}</p>
            <p className={classes.role}>{this.getRole()}</p>
          </div>
          <IconButton className={classes.logoutButton} onClick={logout}>
            <img src={LogoutIcon} className={classes.icon} alt={t('logout')} />
          </IconButton>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(Header))
