import React from 'react'
import { withTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
import Input from '../Input'
import { COLORS } from '../../utils/colors'
import { isValidEmail } from '../../utils/transforms'

const styles = theme => ({
  container: {
    maxWidth: 1000,
    padding: '0 60px 0'
  },
  header: {
    color: COLORS.white,
    fontSize: 24,
    marginTop: 40,
    marginBottom: 30,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  row: {
    display: 'flex',
    marginBottom: 30
  },
  spacer: {
    minWidth: 60
  },
  placeholder: {
    width: '100%',
    display: 'flex'
  },
  select: {
    backgroundColor: COLORS.white
  },
  button: {
    color: COLORS.white,
    background: COLORS.violet, // `radial-gradient(${COLORS.violetGradientStart} 0%, ${COLORS.violetGradientEnd} 100%)`,
    '&:hover': {
      background: darken(COLORS.violet, 0.2)
    },
    width: 200,
    height: 50,
    borderRadius: 5,
    alignItems: 'center',
    marginTop: 16
  },
  disabledButton: {
    background: COLORS.disabledButton
  },
  buttonText: {
    margin: 0,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: '700',
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  infoText: {
    color: COLORS.white,
    fontSize: 18,
    fontFamily: 'Roboto',
    marginBottom: 10
  }
})

class CreateUserEmailForm extends React.PureComponent {
  nextDisabled = () => {
    const { email } = this.props
    return !isValidEmail(email)
  }

  renderMoveToEditUserButton = () => {
    const { classes, t, existingUser, toUser } = this.props
    const handleClick = () => toUser(existingUser.id)
    return (
      <Button
        className={classes.button}
        classes={{ disabled: classes.disabledButton }}
        onClick={handleClick}
      >
        <p className={classes.buttonText}>
          {t('edit_user')}
        </p>
      </Button>
    )
  }

  renderExistingUser = () => {
    const { t, classes, existingUserChecked, existingUser } = this.props
    if (!existingUserChecked || !existingUser) return null
    return (
      <div>
        <p className={classes.header}>{t('user_exists')}</p>
        <p className={classes.infoText}>{t('user_already_exists_info')}</p>
        {this.renderMoveToEditUserButton()}
      </div>
    )
  }

  handleGetExistingUser = () => {
    const { email } = this.props
    this.props.getExistingUserByEmail(email)
  }

  renderFirstStep = () => {
    const { classes, t, email, setEmail, existingUserChecked } = this.props
    if (existingUserChecked) return null
    return (
      <div>
        <p className={classes.header}>{t('enter_email')}</p>
        <div className={classes.row}>
          <Input label={t('email')} value={email} onChange={setEmail} required />
          <div className={classes.spacer} />
          <div className={classes.placeholder} />
        </div>
        <Button
          className={classes.button}
          classes={{ disabled: classes.disabledButton }}
          onClick={this.handleGetExistingUser}
          disabled={this.nextDisabled()}
        >
          <p className={classes.buttonText}>
            {t('next')}
          </p>
        </Button>
      </div>
    )
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        {this.renderFirstStep()}
        {this.renderExistingUser()}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(CreateUserEmailForm))
