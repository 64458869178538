import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getCustomerAssets: ['customerId', 'params'],
  getPropertyAssets: ['propertyId', 'params'],
  getUserAssets: ['userId', 'params'],
  getAssetStorageAssets: ['assetStorageId', 'params'],
  getMyAssets: ['params'],
  getAssetsSuccess: ['assets', 'total'],
  getAssetsFailure: null,
  getAsset: ['id'],
  getAssetSuccess: ['asset'],
  getAssetFailure: null,
  createAsset: ['fields'],
  createAssetFinished: null,
  updateAsset: ['id', 'fields'],
  updateAssetSuccess: ['asset'],
  updateAssetFailure: null,
  deactivateAsset: ['id'],
  deactivateAssetSuccess: ['asset'],
  deactivateAssetFailure: null,
  activateAsset: ['id'],
  activateAssetSuccess: ['asset'],
  activateAssetFailure: null,
  setAsset: ['asset'],
  clearAssets: null
})

export const AssetTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  asset: null,
  assets: null,
  total: null,
  deactivateFetching: null
})

/* ------------- Reducers ------------- */

const getCustomerAssets = state => state.merge({ fetching: true })
const getPropertyAssets = state => state.merge({ fetching: true })
const getUserAssets = state => state.merge({ fetching: true })
const getAssetStorageAssets = state => state.merge({ fetching: true })
const getMyAssets = state => state.merge({ fetching: true })
const getAssetsSuccess = (state, { assets, total }) => state.merge({ assets, total, fetching: false })
const getAssetsFailure = state => state.merge({ fetching: false })

const getAsset = state => state.merge({ fetching: true })
const getAssetSuccess = (state, { asset }) => state.merge({ asset, fetching: false })
const getAccessStorageFailure = state => state.merge({ fetching: false })

const createAsset = (state, { fields }) => state.merge({ fetching: true })
const createAssetFinished = (state) => state.merge({ fetching: false })

const updateAsset = (state, { id, fields }) => state.merge({ fetching: true })
const updateAssetSuccess = (state, { asset }) => state.merge({ asset, fetching: false })
const updateAssetFailure = state => state.merge({ fetching: false })

const deactivateAsset = (state, { id }) => state.merge({ deactivateFetching: true })
const deactivateAssetSuccess = (state, { asset }) => state.merge({ asset, deactivateFetching: false })
const deactivateAssetFailure = state => state.merge({ deactivateFetching: false })

const activateAsset = (state, { id }) => state.merge({ activateFetching: true })
const activateAssetSuccess = (state, { asset }) => state.merge({ asset, activateFetching: false })
const activateAssetFailure = state => state.merge({ activateFetching: false })

const setAsset = (state, { asset }) => state.merge({ asset })
const clearAssets = (state) => state.merge({ assets: null })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CUSTOMER_ASSETS]: getCustomerAssets,
  [Types.GET_PROPERTY_ASSETS]: getPropertyAssets,
  [Types.GET_USER_ASSETS]: getUserAssets,
  [Types.GET_ASSET_STORAGE_ASSETS]: getAssetStorageAssets,
  [Types.GET_MY_ASSETS]: getMyAssets,
  [Types.GET_ASSETS_SUCCESS]: getAssetsSuccess,
  [Types.GET_ASSETS_FAILURE]: getAssetsFailure,
  [Types.GET_ASSET]: getAsset,
  [Types.GET_ASSET_SUCCESS]: getAssetSuccess,
  [Types.GET_ASSET_FAILURE]: getAccessStorageFailure,
  [Types.CREATE_ASSET]: createAsset,
  [Types.CREATE_ASSET_FINISHED]: createAssetFinished,
  [Types.UPDATE_ASSET]: updateAsset,
  [Types.UPDATE_ASSET_SUCCESS]: updateAssetSuccess,
  [Types.UPDATE_ASSET_FAILURE]: updateAssetFailure,
  [Types.DEACTIVATE_ASSET]: deactivateAsset,
  [Types.DEACTIVATE_ASSET_SUCCESS]: deactivateAssetSuccess,
  [Types.DEACTIVATE_ASSET_FAILURE]: deactivateAssetFailure,
  [Types.ACTIVATE_ASSET]: activateAsset,
  [Types.ACTIVATE_ASSET_SUCCESS]: activateAssetSuccess,
  [Types.ACTIVATE_ASSET_FAILURE]: activateAssetFailure,
  [Types.SET_ASSET]: setAsset,
  [Types.CLEAR_ASSETS]: clearAssets
})
