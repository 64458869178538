import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import ArrowIcon from '@material-ui/icons/ArrowDropDown'
import { COLORS } from '../utils/colors'

const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative'
  },
  label: {
    margin: 0,
    marginLeft: 15,
    marginBottom: 10,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 10,
      marginLeft: 5
    }
  },
  input: {
    height: 50,
    fontFamily: 'Roboto',
    fontSize: 16,
    color: COLORS.white,
    backgroundColor: COLORS.dark,
    borderRadius: 3,
    border: `1px solid ${COLORS.border}`,
    padding: '0px 15px',
    cursor: 'pointer',
    boxSizing: 'border-box',
    '&::placeholder': {
      color: COLORS.inputPlaceholder
    },
    [theme.breakpoints.down('sm')]: {
      height: 40,
      fontSize: 14
    }
  },
  menuItem: {
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 18,
    color: COLORS.dark,
    backgroundColor: COLORS.white
  },
  selectRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    height: 40,
    backgroundColor: COLORS.dark
  },
  selectDisabled: {
    color: COLORS.lightGray,
    cursor: 'not-allowed',
    fontSize: 16
  },
  icon: {
    color: COLORS.white,
    zIndex: 10000,
    position: 'absolute',
    top: 44,
    right: 16,
    pointerEvents: 'none'
  },
  menuRoot: {
    maxHeight: '50%'
  }
})

class SelectInput extends React.PureComponent {
  renderOptions = () => this.props.options.map((option) => (
    <MenuItem
      key={option.value}
      value={option.value}
      classes={{ root: this.props.classes.menuItem }}
    >
      {option.name}
    </MenuItem>
  ))

  render () {
    const { classes, width, label, placeholder, value, onChange, disabled } = this.props
    const styles = width ? { width } : {}
    return (
      <div className={classes.container} style={styles}>
        <p className={classes.label}>{label}</p>
        {!disabled && <ArrowIcon className={classes.icon} />}
        <Select
          value={value}
          onChange={onChange}
          className={classes.input}
          classes={{ select: classes.selectRoot, disabled: classes.selectDisabled }}
          IconComponent={() => null}
          disabled={disabled}
          displayEmpty
          disableUnderline
          MenuProps={{ MenuListProps: { classes: { root: classes.menuRoot } } }}
        >
          <MenuItem value='' classes={{ root: classes.menuItem }} disabled>
            { placeholder || label }
          </MenuItem>
          { this.renderOptions() }
        </Select>
      </div>
    )
  }
}

export default withStyles(styles)(SelectInput)
