import { call, put, select, all } from 'redux-saga/effects'
import UserActions from '../redux/UserRedux'
import OrganizationActions from '../redux/OrganizationRedux'
import NotificationActions from '../redux/NotificationRedux'
import { AUTH_TOKEN, ORGANIZATION_ID, readStorage, writeStorage } from '../utils/storage'
import { ROLES, EXTENDED_ROLES } from '../utils/constants'
import { composeError } from '../utils/transforms'

export function * login (api, action) {
  const { email, password } = action
  const response = yield call(api.login, email, password)
  console.log(response)
  if (response.ok) {
    const user = response.data
    const currentOrganizationId = user.organizationId || null
    yield put(UserActions.loginSuccess(user, user.token, currentOrganizationId))
    writeStorage(AUTH_TOKEN, user.token)
    if (currentOrganizationId) {
      yield put(OrganizationActions.setOrganization(user.organization))
    }
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(UserActions.loginFailure(error))
  }
}

export function * logout () {
  writeStorage(AUTH_TOKEN, null)
  writeStorage(ORGANIZATION_ID, null)
  yield put(UserActions.logoutSuccess())
}

export function * getMe (api, action) {
  const { token } = action
  const response = yield call(api.getMe, token)
  console.log(response)
  if (response.ok) {
    const user = response.data
    let currentOrganizationId = user.organizationId || null
    if (!currentOrganizationId && user.role === ROLES.SUPERADMIN) {
      const id = readStorage(ORGANIZATION_ID)
      if (id) {
        currentOrganizationId = id
        yield put(OrganizationActions.getOrganization(id))
      }
    }
    yield put(UserActions.getMeSuccess(user, currentOrganizationId))
    if (user.role !== ROLES.SUPERADMIN) {
      yield put(OrganizationActions.setOrganization(user.organization))
    }
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(UserActions.logout())
  }
}

export function * updateUser (api, action) {
  const { id, fields } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.updateUser, token, id, fields)
  console.log(response)
  if (response.ok) {
    const user = response.data
    yield put(UserActions.updateUserSuccess(user))
    yield put(NotificationActions.setSuccess('update_user_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(UserActions.updateUserFailure())
  }
}

export function * changePassword (api, action) {
  const { oldPassword, newPassword } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.changePassword, token, oldPassword, newPassword)
  console.log(response)
  if (response.ok) {
    const user = response.data
    yield put(UserActions.changePasswordSuccess(user, user.token))
    yield put(NotificationActions.setSuccess('change_password_success'))
    writeStorage(AUTH_TOKEN, user.token)
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(UserActions.changePasswordFailure())
  }
}

export function * requestPasswordReset (api, action) {
  const { email } = action
  const response = yield call(api.requestPasswordReset, email)
  console.log(response)
  if (response.ok) {
    yield put(UserActions.requestPasswordResetFinished())
    yield put(NotificationActions.setSuccess())
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(UserActions.requestPasswordResetFinished())
  }
}

export function * checkCodeValidity (api, action) {
  const { code, email } = action
  const response = yield call(api.checkCodeValidity, code, email)
  console.log(response)
  if (!response.ok) {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
  }
}

export function * resetPassword (api, action) {
  const { code, email, password } = action
  console.log(code, email, password)
  const response = yield call(api.resetPassword, code, email, password)
  console.log(response)
  if (response.ok) {
    yield put(UserActions.resetPasswordFinished())
    yield put(NotificationActions.setSuccess('set_password_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(UserActions.resetPasswordFinished())
  }
}

export function * getUsers (api, action) {
  const { organizationId, params } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.getUsers, token, organizationId, params)
  console.log(response)
  if (response.ok) {
    const users = response.data
    yield put(UserActions.getUsersSuccess(users))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(UserActions.getUsersFailure())
  }
}

export function * getTargetUser (api, action) {
  const { id } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.getUser, token, id)
  if (response.ok) {
    const user = response.data
    yield put(UserActions.getTargetUserSuccess(user))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(UserActions.getTargetUserFailure())
  }
}
export function * createUser (api, action) {
  const { fields } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.createUser, token, fields)
  if (response.ok) {
    const user = response.data
    yield put(NotificationActions.setSuccess('create_user_success'))
    yield put(UserActions.createUserSuccess(user))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(UserActions.createUserFailure())
  }
}

export function * updateTargetUser (api, action) {
  const { id, fields } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.updateUser, token, id, fields)
  console.log(response)
  if (response.ok) {
    yield put(UserActions.updateTargetUserFinished())
    yield put(NotificationActions.setSuccess('update_user_success'))
    // Refresh user
    yield put(UserActions.getTargetUser(id))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(UserActions.updateTargetUserFinished())
  }
}

export function * addUserDomain (api, action) {
  const { userId, domainIds, role } = action
  const { token } = yield select(state => state.user)
  const responses = yield all(domainIds.map(domainId => call(api.addUserDomain, token, userId, domainId, role)))
  console.log(responses)
  if (responses.every(response => response.ok)) {
    yield put(UserActions.addUserDomainFinished())
    yield put(NotificationActions.setSuccess('update_user_success'))
    // Refresh user
    yield put(UserActions.getTargetUser(userId))
  } else {
    const error = composeError(responses.find(response => response.error))
    yield put(NotificationActions.setError(error))
    yield put(UserActions.addUserDomainFinished())
  }
}

export function * removeUserDomain (api, action) {
  const { userId, domainIds } = action
  const { token } = yield select(state => state.user)
  const responses = yield all(domainIds.map(domainId => call(api.removeUserDomain, token, userId, domainId)))
  console.log(responses)
  if (responses.every(response => response.ok)) {
    yield put(UserActions.removeUserDomainFinished())
    yield put(NotificationActions.setSuccess('update_user_success'))
    // Refresh user
    yield put(UserActions.getTargetUser(userId))
  } else {
    const error = composeError(responses.find(response => response.error))
    yield put(NotificationActions.setError(error))
    yield put(UserActions.removeUserDomainFinished())
  }
}

export function * updateUserDomains (api, action) {
  const { userId, oldDomainIds, newDomainIds, role } = action
  const { token } = yield select(state => state.user)
  // Domain admins have the role 'admin' on domains, users have the role 'user'
  const domainRole = role === EXTENDED_ROLES.DOMAIN_ADMIN ? ROLES.ADMIN : role
  // Remove all old domains
  const oldResponses = yield all(oldDomainIds.map(domainId => call(api.removeUserDomain, token, userId, domainId)))
  let newResponses
  // Only add new domains if user is not an admin
  if (role !== ROLES.ADMIN) {
    newResponses = yield all(newDomainIds.map(domainId => call(api.addUserDomain, token, userId, domainId, domainRole)))
  }
  // Check if all responses are ok, depending on whether or not domains were added or removed
  if (
    (oldDomainIds.length && oldResponses.every(response => response.ok) && !newDomainIds.length) ||
    (newDomainIds.length && newResponses.every(response => response.ok) && !oldDomainIds.length) ||
    (oldDomainIds.length &&
      oldResponses.every(response => response.ok) &&
      newDomainIds.length &&
      newResponses.every(response => response.ok))
  ) {
    yield put(UserActions.updateUserDomainsFinished())
    yield put(NotificationActions.setSuccess('update_user_success'))
    // Refresh user
    yield put(UserActions.getTargetUser(userId))
  } else {
    const responseError =
      (newResponses && newResponses.find(response => !response.ok)) ||
      (oldResponses && oldResponses.find(response => !response.ok))
    const error = composeError(responseError)
    yield put(NotificationActions.setError(error))
    yield put(UserActions.updateUserDomainsFinished())
  }
}

export function * getExistingUserByEmail (api, action) {
  const { email } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.getUserByEmail, token, email)
  if (response.ok) {
    const user = response.data
    yield put(UserActions.getExistingUserByEmailSuccess(user))
  } else {
    yield put(UserActions.getExistingUserByEmailFailure())
  }
}
