import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { COLORS } from '../utils/colors'

const styles = theme => ({
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  message: {
    color: COLORS.white,
    fontSize: 36,
    margin: 0,
    marginBottom: 24,
    fontFamily: 'Chivo',
    fontWeight: '700'
  },
  smMessage: {
    color: COLORS.white,
    fontSize: 24,
    margin: 0,
    fontFamily: 'Chivo',
    fontWeight: '700'
  },
  messageSub: {
    color: COLORS.white,
    fontSize: 16,
    margin: 0,
    fontFamily: 'Roboto'
  }
})

class TableMessage extends React.PureComponent {
  render () {
    const { classes, message, messageSub, smallFont } = this.props
    const messageClass = smallFont ? classes.smMessage : classes.message
    return (
      <div className={classes.messageContainer}>
        <p className={messageClass}>{message}</p>
        <p className={classes.messageSub}>{messageSub}</p>
      </div>
    )
  }
}

export default withStyles(styles)(TableMessage)
