import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  column: {
    flexDirection: 'column',
    flex: 1,
    backgroundColor: COLORS.transaction,
    borderRadius: 5,
    padding: 20
  },
  label: {
    margin: 0,
    marginLeft: 5,
    marginBottom: 10,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  value: {
    margin: 0,
    marginLeft: 5,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 22,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  }
})

class AssetTransactionDetailsColumn extends React.PureComponent {
  render () {
    const { classes, label, value } = this.props
    return (
      <div className={classes.column}>
        <p className={classes.label}>{label}</p>
        <p className={classes.value}>{value}</p>
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(AssetTransactionDetailsColumn))
