import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import CircularProgress from '@material-ui/core/CircularProgress'
import DomainTableHeader from './DomainTableHeader'
import DomainTableRow from './DomainTableRow'
import TableFooter from '../TableFooter'
import TableMessage from '../TableMessage'
import { COLORS } from '../../utils/colors'
import { isAdminLevelUser } from '../../utils/permissions'

const styles = theme => ({
  tableContainer: {
    padding: '60px 60px',
    backgroundColor: COLORS.darkBackground,
    position: 'relative'
  },
  root: {
    alignSelf: 'center',
    width: '100%',
    overflowX: 'auto',
    maxWidth: 1000,
    backgroundColor: COLORS.darkBackground,
    position: 'relative'
  },
  table: {
    minWidth: 600
  },
  rounded: {
    border: `0.5px solid ${COLORS.border}`
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: COLORS.white
  }
})

class DomainTable extends React.PureComponent {
  canEditDomain = () => isAdminLevelUser(this.props.user)

  renderRows = () => {
    const { domains, editDomain, showEdit } = this.props
    return domains.map((domain) => (
      <DomainTableRow
        key={domain.id}
        domain={domain}
        editDomain={editDomain}
        editable={this.canEditDomain()}
        showEdit={showEdit}
      />
    ))
  }

  renderFooter = () => {
    const { total, page, pageSize, onChangePage } = this.props
    if (total < pageSize) return null
    return (
      <TableFooter page={page} pageSize={pageSize} onChangePage={onChangePage} total={total} />
    )
  }

  renderTable = () => {
    const { t, classes, domains, loading, showEdit } = this.props
    if (domains && domains.length) {
      return (
        <Paper className={classes.root} classes={{ rounded: classes.rounded }} elevation={0}>
          <Table className={classes.table} padding='none'>
            <DomainTableHeader showEdit={showEdit} />
            <TableBody>
              {this.renderRows()}
            </TableBody>
          </Table>
        </Paper>
      )
    }
    if (loading) return <CircularProgress className={classes.loading} size={30} />
    return <TableMessage message={t('no_domains')} messageSub={t('no_domains_sub')} />
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.tableContainer}>
        {this.renderTable()}
        {this.renderFooter()}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(DomainTable))
