import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import withRoot from '../utils/withRoot'
import AssetStorageActions from '../redux/AssetStorageRedux'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import AssetStorageTable from '../components/AssetStorage/AssetStorageTable'

const styles = theme => ({})

class AssetStoragesScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      pageSize: 10,
      page: 0,
      search: ''
    }
    this.timeout = 0
  }

  componentDidMount () {
    const { getAssetStorages, currentOrganizationId } = this.props
    const { pageSize, page } = this.state
    if (currentOrganizationId) {
      getAssetStorages(currentOrganizationId, { offset: page, limit: pageSize })
    }
  }

  componentDidUpdate (prevProps) {
    const { getAssetStorages, assetStorages, currentOrganizationId } = this.props
    const { pageSize, page } = this.state
    if (currentOrganizationId !== prevProps.currentOrganizationId && !assetStorages) {
      getAssetStorages(currentOrganizationId, { offset: page, limit: pageSize })
    }
  }

  handleSearchChange = evt => {
    const { page, pageSize } = this.state
    const searchValue = evt.target.value
    this.setState({ search: searchValue })

    if (this.timeout) clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.props.getAssetStorages(this.props.currentOrganizationId, {
        limit: pageSize,
        offset: page * pageSize,
        search: searchValue
      })
    }, 300)
  }

  toCreateAssetStorage = () => this.props.history.push('/uusi-avainkaappi')

  toAssetStorage = (assetStorage, edit) => {
    this.props.setAssetStorage(assetStorage)
    this.props.history.push({
      pathname: `/avainkaappi/${assetStorage.id}`,
      state: {
        edit
      }
    })
  }

  setPage = (event, nextPage) => {
    const { getAssetStorages, currentOrganizationId } = this.props
    const { pageSize } = this.state
    const offset = nextPage * pageSize
    const limit = pageSize
    getAssetStorages(currentOrganizationId, { offset, limit })
    this.setState({ page: nextPage })
  }

  renderPage = () => {
    const { t, fetching, assetStorages, total } = this.props
    const { page, pageSize, search } = this.state
    return (
      <Layout>
        <PageHeader
          title={t('asset_storages')}
          onClick={this.toCreateAssetStorage}
          buttonText={t('create_asset_storage')}
          listScreen
        />
        <AssetStorageTable
          assetStorages={assetStorages}
          toSingle={this.toAssetStorage}
          loading={fetching}
          onChangePage={this.setPage}
          total={total}
          page={page}
          pageSize={pageSize}
          search={search}
          handleSearchChange={this.handleSearchChange}
        />
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = state => {
  return {
    assetStorages: state.assetStorage.assetStorages,
    fetching: state.assetStorage.fetching,
    total: state.assetStorage.total,
    currentOrganizationId: state.user.currentOrganizationId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAssetStorages: (organizationId, params) =>
      dispatch(AssetStorageActions.getAssetStorages(organizationId, params)),
    setAssetStorage: assetStorage => dispatch(AssetStorageActions.setAssetStorage(assetStorage))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoot(withStyles(styles)(withTranslation()(AssetStoragesScreen))))
