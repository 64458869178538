import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { COLORS } from '../../utils/colors'
import { ASSET_TRANSACTION_TYPES } from '../../utils/constants'
import Icon from '../../static/avaimet.svg'

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: COLORS.transaction,
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    border: 'none',
    outline: 'none',
    width: '100%',
    cursor: 'pointer',
    transition: 'all 100ms',
    '&:hover': {
      backgroundColor: COLORS.dark
    }
  },
  content: {
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left'
  },
  mainText: {
    fontSize: 18,
    fontFamily: 'Chivo',
    fontWeight: 'bold',
    color: COLORS.white,
    padding: 0,
    margin: 0,
    marginBottom: 5
  },
  subText: {
    fontSize: 12,
    fontFamily: 'Roboto',
    color: COLORS.white,
    padding: 0,
    margin: 0
  },
  icon: {
    padding: 5,
    width: 40,
    height: 40,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

class AssetTransaction extends React.PureComponent {
  toTransactionDetails = () => this.props.onClick(this.props.transaction)

  renderMainText = () => {
    const { t, classes, transaction } = this.props
    // TODO: Replace "other" with possible other asset types, move asset types to constants
    const assetType = transaction.asset.type === 'key' ? t('key') : t('asset')
    let transactionType = ''
    if (transaction.type === ASSET_TRANSACTION_TYPES.CREATE) {
      transactionType = t('transaction_type_crete')
    }
    if (transaction.type === ASSET_TRANSACTION_TYPES.ACCEPT) {
      transactionType = t('transaction_type_accept')
    }
    if (transaction.type === ASSET_TRANSACTION_TYPES.REJECT) {
      transactionType = t('transaction_type_reject')
    }
    // Asset storage transfers
    if (transaction.toAssetStorageId || transaction.fromAssetStorageId) {
      transactionType = t('transaction_type_asset_storage')
    }

    const returnString = `
      ${moment(transaction.createdAt).format(`DD.MM.YYYY [${t('at')}] HH:mm`)} -
      ${assetType} ${transaction.asset.name} : ${transactionType} `
    return <p className={classes.mainText}>{returnString}</p>
  }

  renderFrom = () => {
    const { t, transaction } = this.props
    if (transaction.fromUserId) {
      return `${t('from_user')} ${transaction.fromUser.firstName} ${transaction.fromUser.lastName}`
    }
    return `${t('from_asset_storage')} ${transaction.fromAssetStorage.name}`
  }

  renderTo = () => {
    const { t, transaction } = this.props
    if (transaction.toUserId) {
      return `${t('to_user')} ${transaction.toUser.firstName} ${transaction.toUser.lastName}`
    }
    return `${t('to_asset_storage')} ${transaction.toAssetStorage.name}`
  }

  renderIcon = () => {
    const { t, classes } = this.props
    return (
      <div className={classes.icon}>
        <img src={Icon} alt={t('edit')} />
      </div>
    )
  }

  renderContent = () => {
    const { classes } = this.props
    return (
      <div className={classes.content}>
        <div>{this.renderMainText()}</div>
        <div className={classes.subText}>{`${this.renderFrom()} ${this.renderTo()}`}</div>
      </div>
    )
  }

  render () {
    const { classes } = this.props
    return (
      <button className={classes.container} onClick={this.toTransactionDetails}>
        {this.renderIcon()}
        {this.renderContent()}
      </button>
    )
  }
}

export default withStyles(styles)(withTranslation()(AssetTransaction))
