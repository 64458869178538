import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import QRCode from 'qrcode.react'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  hidden: {
    display: 'none'
  },
  link: {
    margin: 0,
    marginLeft: 15,
    marginBottom: 10,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 16,
    cursor: 'pointer',
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 10,
      marginLeft: 5
    }
  }
})

class QrCode extends React.PureComponent {
  download = () => {
    const { name } = this.props
    const canvas = document.querySelector('.qrCode')
    if (canvas) {
      this.downloadRef.href = canvas.toDataURL()
      this.downloadRef.download = name + '-QR.png'
    }
  }

  render () {
    const { qrCode, text, classes } = this.props
    return (
      <div>
        {/* Don't display the qr code */}
        <div className={classes.hidden}>
          <QRCode value={qrCode} size={256} level='H' className='qrCode' />
        </div>
        <a className={classes.link} ref={ref => (this.downloadRef = ref)} onClick={this.download}>
          {text}
        </a>
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(QrCode))
