import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import MUITableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { COLORS } from '../utils/colors'

const actionsStyles = theme => ({
  actionsRoot: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  leftIconButton: {
    padding: 0,
    height: 40,
    width: 40,
    border: `1px solid ${COLORS.border}`,
    borderRight: 'none',
    borderRadius: 3,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  rightIconButton: {
    padding: 0,
    height: 40,
    width: 40,
    border: `1px solid ${COLORS.border}`,
    borderRadius: 3,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  icon: {
    color: COLORS.white
  },
  disabledIcon: {
    color: COLORS.disabledButton
  }
})

class TablePaginationActions extends React.PureComponent {
  handleBackButtonClick = (event) => this.props.onChangePage(event, this.props.page - 1)

  handleNextButtonClick = (event) => this.props.onChangePage(event, this.props.page + 1)

  isNextDisabled = () => this.props.page >= Math.ceil(this.props.count / this.props.rowsPerPage) - 1

  render () {
    const { classes, page } = this.props
    const leftArrowClasses = { root: page !== 0 ? classes.icon : classes.disabledIcon }
    const rightArrowClasses = { root: this.isNextDisabled() ? classes.disabledIcon : classes.icon }
    return (
      <div className={classes.actionsRoot}>
        <IconButton
          className={classes.leftIconButton}
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
        >
          <KeyboardArrowLeft classes={leftArrowClasses} />
        </IconButton>
        <IconButton
          className={classes.rightIconButton}
          onClick={this.handleNextButtonClick}
          disabled={this.isNextDisabled()}
        >
          <KeyboardArrowRight classes={rightArrowClasses} />
        </IconButton>
      </div>
    )
  }
}

const TablePaginationActionsWrapped = withStyles(actionsStyles)(TablePaginationActions)

export default class TableFooter extends React.PureComponent {
  render () {
    const { total, page, pageSize, onChangePage } = this.props
    return (
      <MUITableFooter component='div'>
        <TableRow component='div'>
          <TablePagination
            rowsPerPageOptions={[]}
            count={total}
            rowsPerPage={pageSize}
            page={page}
            SelectProps={{ native: true }}
            onChangePage={onChangePage}
            ActionsComponent={TablePaginationActionsWrapped}
            labelDisplayedRows={() => null}
            component='div'
          />
        </TableRow>
      </MUITableFooter>
    )
  }
}
