import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  createCustomer: ['organizationId', 'fields'],
  createCustomerSuccess: ['customer'],
  createCustomerFailure: null,
  getCustomer: ['customerId'],
  getCustomerSuccess: ['customer'],
  getCustomerFailure: null,
  getCustomers: ['organizationId', 'params'],
  getCustomersSuccess: ['customers', 'total'],
  getCustomersFailure: null,
  setCustomer: ['customer'],
  updateCustomer: ['customerId', 'fields'],
  updateCustomerSuccess: ['customer'],
  updateCustomerFailure: null,
  addCustomerDomain: ['customerId', 'domainId'],
  addCustomerDomainFinished: null,
  removeCustomerDomain: ['customerId', 'domainId'],
  removeCustomerDomainFinished: null
})

export const CustomerTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  customer: null,
  customers: null
})

/* ------------- Reducers ------------- */

const createCustomer = (state) => state.merge({ fetching: true })
const createCustomerSuccess = (state, { customer }) => state.merge({ customer, fetching: false })
const createCustomerFailure = (state) => state.merge({ fetching: false })

const getCustomer = (state) => state.merge({ fetching: true })
const getCustomerSuccess = (state, { customer }) => state.merge({ customer, fetching: false })
const getCustomerFailure = (state) => state.merge({ fetching: false })

const getCustomers = (state) => state.merge({ fetching: true })
const getCustomersSuccess = (state, { customers, total }) => state.merge({ customers, fetching: false })
const getCustomersFailure = (state) => state.merge({ fetching: false })

const setCustomer = (state, { customer }) => state.merge({ customer })

const updateCustomer = (state) => state.merge({ fetching: true })
const updateCustomerSuccess = (state) => state.merge({ fetching: false })
const updateCustomerFailure = (state) => state.merge({ fetching: false })

const addCustomerDomain = (state) => state.merge({ fetching: true })
const addCustomerDomainFinished = (state) => state.merge({ fetching: false })

const removeCustomerDomain = (state) => state.merge({ fetching: true })
const removeCustomerDomainFinished = (state) => state.merge({ fetching: false })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_CUSTOMER]: createCustomer,
  [Types.CREATE_CUSTOMER_SUCCESS]: createCustomerSuccess,
  [Types.CREATE_CUSTOMER_FAILURE]: createCustomerFailure,
  [Types.GET_CUSTOMER]: getCustomer,
  [Types.GET_CUSTOMER_SUCCESS]: getCustomerSuccess,
  [Types.GET_CUSTOMER_FAILURE]: getCustomerFailure,
  [Types.GET_CUSTOMERS]: getCustomers,
  [Types.GET_CUSTOMERS_SUCCESS]: getCustomersSuccess,
  [Types.GET_CUSTOMERS_FAILURE]: getCustomersFailure,
  [Types.SET_CUSTOMER]: setCustomer,
  [Types.UPDATE_CUSTOMER]: updateCustomer,
  [Types.UPDATE_CUSTOMER_SUCCESS]: updateCustomerSuccess,
  [Types.UPDATE_CUSTOMER_FAILURE]: updateCustomerFailure,
  [Types.ADD_CUSTOMER_DOMAIN]: addCustomerDomain,
  [Types.ADD_CUSTOMER_DOMAIN_FINISHED]: addCustomerDomainFinished,
  [Types.REMOVE_CUSTOMER_DOMAIN]: removeCustomerDomain,
  [Types.REMOVE_CUSTOMER_DOMAIN_FINISHED]: removeCustomerDomainFinished
})
