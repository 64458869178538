import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import Input from '../Input'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  container: {
    maxWidth: 1000,
    padding: '40px 60px 0px 60px'
  },
  header: {
    color: COLORS.white,
    fontSize: 24,
    margin: 0,
    marginBottom: 30,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  row: {
    display: 'flex',
    marginBottom: 30
  },
  spacer: {
    minWidth: 60
  },
  placeholder: {
    width: '100%',
    display: 'flex'
  },
  select: {
    backgroundColor: COLORS.white
  },
  column: {
    flexDirection: 'column',
    minWidth: 'calc(50% - 30px)'
  },
  label: {
    margin: 0,
    marginLeft: 15,
    marginBottom: 15,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 10,
      marginLeft: 5
    }
  },
  value: {
    margin: 0,
    marginLeft: 15,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginLeft: 5
    }
  }
})

class UserContactInfoForm extends React.PureComponent {
  renderContent = () => {
    const {
      classes,
      t,
      email,
      firstName,
      lastName,
      phone,
      setEmail,
      setFirstName,
      setLastName,
      setPhone,
      emailDisabled,
      editing
    } = this.props
    if (editing) {
      return (
        <div>
          <div className={classes.row}>
            <Input label={t('first_name')} value={firstName} onChange={setFirstName} required />
            <div className={classes.spacer} />
            <Input label={t('last_name')} value={lastName} onChange={setLastName} required />
          </div>
          <div className={classes.row}>
            <Input label={t('email')} value={email} onChange={setEmail} required disabled={emailDisabled} />
            <div className={classes.spacer} />
            <Input label={t('phone')} value={phone} onChange={setPhone} />
          </div>
        </div>
      )
    }
    return (
      <div>
        <div className={classes.row}>
          <div className={classes.column}>
            <p className={classes.label}>{t('first_name')}</p>
            <p className={classes.value}>{firstName}</p>
          </div>
          <div className={classes.spacer} />
          <div className={classes.column}>
            <p className={classes.label}>{t('last_name')}</p>
            <p className={classes.value}>{lastName}</p>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.column}>
            <p className={classes.label}>{t('email')}</p>
            <p className={classes.value}>{email}</p>
          </div>
          <div className={classes.spacer} />
          <div className={classes.column}>
            <p className={classes.label}>{t('phone')}</p>
            <p className={classes.value}>{phone}</p>
          </div>
        </div>
      </div>
    )
  }

  render () {
    const { classes, t } = this.props
    return (
      <div className={classes.container}>
        <p className={classes.header}>{t('contact_info')}</p>
        {this.renderContent()}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(UserContactInfoForm))
