import React, { Component } from 'react'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import './i18n'
import 'moment/locale/fi'
import routes from './routes'
import NotificationActions from './redux/NotificationRedux'
import { getCurrentLang } from './utils/transforms'

class App extends Component {
  generateRoutes = () => routes.map((item, index) => {
    return <Route key={index} path={item.path} component={item.component} exact={item.exact} />
  })

  unauthorizedRedirect = () => {
    // Handle unauthorized errors
    const { error } = this.props
    if (error && error.key === 'unauthorized') {
      this.props.clearNotifications()
      return <Redirect to='/logout' />
    }
    return null
  }

  render () {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={getCurrentLang()}>
        <BrowserRouter>
          <Switch>
            {this.unauthorizedRedirect()}
            {this.generateRoutes()}
            { /* Catch-all redirect for paths that don't match the ones specified in routes.js */ }
            <Redirect to='/' />
          </Switch>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.notification.error,
    user: state.user.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearNotifications: () => dispatch(NotificationActions.clearNotifications())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
