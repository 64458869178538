import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setError: ['error'],
  setSuccess: ['success'],
  clearNotifications: null
})

export const NotificationTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  error: null,
  success: null
})

/* ------------- Reducers ------------- */

const setError = (state, { error }) => state.merge({ error })
const setSuccess = (state, { success }) => state.merge({ success })
const clearNotifications = (state) => state.merge({ error: null, success: null })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ERROR]: setError,
  [Types.SET_SUCCESS]: setSuccess,
  [Types.CLEAR_NOTIFICATIONS]: clearNotifications
})
