import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import withRoot from '../utils/withRoot'
import UserActions from '../redux/UserRedux'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import UserTable from '../components/User/UserTable'

const styles = theme => ({})

class UsersScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      pageSize: 10,
      page: 0,
      search: ''
    }
    this.timeout = 0
  }

  componentDidMount () {
    const { currentOrganizationId } = this.props
    const { pageSize } = this.state
    // Load users if user is ready
    if (currentOrganizationId) {
      this.props.getUsers(currentOrganizationId, { limit: pageSize, offset: 0 })
    }
  }

  componentWillReceiveProps (nextProps) {
    const { users, currentOrganizationId } = this.props
    const { pageSize } = this.state
    // Load users when user is loaded
    if (!users && !currentOrganizationId && nextProps.currentOrganizationId) {
      this.props.getUsers(nextProps.currentOrganizationId, { limit: pageSize, offset: 0 })
    }
  }

  toCreateUser = () => this.props.history.push('/uusi-kayttaja')

  setPage = (evt, page) => {
    const { currentOrganizationId } = this.props
    const { pageSize, search } = this.state
    this.props.getUsers(currentOrganizationId, { limit: pageSize, offset: page * pageSize, search: search })
    this.setState({ page })
  }

  handleSearchChange = evt => {
    const { page, pageSize } = this.state
    const searchValue = evt.target.value
    this.setState({ search: searchValue })

    if (this.timeout) clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.props.getUsers(this.props.currentOrganizationId, {
        limit: pageSize,
        offset: page * pageSize,
        search: searchValue
      })
    }, 300)
  }

  toUser = (user, edit) => {
    this.props.setTargetUser(user)
    this.props.history.push({
      pathname: `/kayttaja/${user.id}`,
      state: {
        edit
      }
    })
  }

  renderPage = () => {
    const { t, users, fetching, user } = this.props
    const { page, pageSize, search } = this.state
    const totalCount = users ? users.total : 0
    const visibleUsers = users ? users.results : null

    return (
      <Layout>
        <PageHeader
          title={t('users')}
          onClick={this.toCreateUser}
          loading={fetching}
          buttonText={t('create_user')}
          listScreen
        />
        <UserTable
          users={visibleUsers}
          toSingle={this.toUser}
          loading={fetching}
          onChangePage={this.setPage}
          total={totalCount}
          page={page}
          pageSize={pageSize}
          user={user}
          search={search}
          handleSearchChange={this.handleSearchChange}
        />
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = state => {
  return {
    fetching: state.user.fetching,
    users: state.user.users,
    error: state.notification.error,
    currentOrganizationId: state.user.currentOrganizationId,
    user: state.user.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUsers: (organizationId, params) => dispatch(UserActions.getUsers(organizationId, params)),
    setTargetUser: user => dispatch(UserActions.setTargetUser(user))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoot(withStyles(styles)(withTranslation()(UsersScreen))))
