import React from 'react'
import { withStyles, Button } from '@material-ui/core'
import { darken } from '@material-ui/core/styles/colorManipulator'
import withRoot from '../../utils/withRoot'
import { withTranslation } from 'react-i18next'
import { COLORS } from '../../utils/colors'
import UserAssetTable from './UserAssetTable'

const styles = theme => ({
  container: {
    maxWidth: 1000,
    padding: '40px 60px 0px 60px',
    clear: 'both'
  },
  header: {
    color: COLORS.white,
    fontSize: 24,
    margin: 0,
    marginBottom: 30,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: 30
  },
  button: {
    color: COLORS.white,
    background: COLORS.violet, // `radial-gradient(${COLORS.violetGradientStart} 0%, ${COLORS.violetGradientEnd} 100%)`,
    '&:hover': {
      background: darken(COLORS.violet, 0.2)
    },
    width: 200,
    height: 50,
    borderRadius: 5,
    alignItems: 'center',
    boxShadow: `0px 0px 40px ${COLORS.dark}`
  },
  buttonText: {
    margin: 0,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: '700',
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  }
})

class UserAssets extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      pageSize: 10,
      page: 0
    }
  }

  setPage = (evt, page) => {
    const { userId } = this.props.match.params
    const { pageSize } = this.state
    this.props.getUserAssets(userId, { limit: pageSize, offset: page * pageSize })
    this.setState({ page })
  }

  toCreateAsset = () => {
    const { userId } = this.props.match.params
    this.props.history.push('/uusi-avain', { user: userId })
  }

  toEditAsset = (asset, edit) => {
    this.props.setAsset(asset)
    this.props.history.push(`/avain/${asset.id}`, {
      state: {
        edit
      }
    })
  }

  render () {
    const { t, classes, assets, total, loading, disabled } = this.props
    const { page, pageSize } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.row}>
          <p className={classes.header}>{t('assets')}</p>
          <Button className={classes.button} onClick={this.toCreateAsset} disabled={disabled} >
            <p className={classes.buttonText}>{t('create_asset')}</p>
          </Button>
        </div>
        <UserAssetTable
          assets={assets}
          total={total}
          page={page}
          pageSize={pageSize}
          onChangePage={this.setPage}
          editAsset={this.toEditAsset}
          loading={loading}
        />
      </div>
    )
  }
}

export default withRoot(withStyles(styles)(withTranslation()(UserAssets)))
