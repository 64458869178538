import React from 'react'
import * as moment from 'moment'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import QrCode from './QrCode'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  container: {
    maxWidth: 1000,
    padding: '40px 60px 0px 60px'
  },
  header: {
    color: COLORS.white,
    fontSize: 24,
    margin: 0,
    marginBottom: 30,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    flexDirection: 'column',
    marginRight: 50
  },
  label: {
    margin: 0,
    marginLeft: 15,
    marginBottom: 15,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 10,
      marginLeft: 5
    }
  },
  value: {
    margin: 0,
    marginLeft: 15,
    marginBottom: 10,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 10,
      marginLeft: 5
    }
  }
})

class AssetStorageInfo extends React.PureComponent {
  renderContent = () => {
    const { classes, t, assetStorage } = this.props
    if (!assetStorage) return null
    const { name, active, createdAt, qrCode } = assetStorage
    return (
      <div className={classes.container}>
        <p className={classes.header}>{t('other_info')}</p>
        <div className={classes.row}>
          <div className={classes.column}>
            <p className={classes.label}>{t('status')}</p>
            <p className={classes.value}>{active ? t('active') : t('deactive')}</p>
          </div>
          <div className={classes.column}>
            <p className={classes.label}>{t('created')}</p>
            <p className={classes.value}>{moment(createdAt).format(`DD.MM.YYYY [${t('at')}] HH:mm`)}</p>
          </div>
          <div className={classes.column}>
            <p className={classes.label}>{t('qr_code')}</p>
            <QrCode name={name} qrCode={qrCode} text={t('download')} />
          </div>
        </div>
      </div>
    )
  }

  render () {
    return this.renderContent()
  }
}

export default withStyles(styles)(withTranslation()(AssetStorageInfo))
