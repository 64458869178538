import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  createProperty: ['customerId', 'fields'],
  createPropertyFinished: null,
  getProperty: ['propertyId'],
  getPropertySuccess: ['property'],
  getPropertyFailure: null,
  getProperties: ['customerId', 'params'],
  getPropertiesSuccess: ['properties', 'total'],
  getPropertiesFailure: null,
  setProperty: ['property'],
  updateProperty: ['propertyId', 'fields'],
  updatePropertySuccess: ['property'],
  updatePropertyFailure: null,
  deactivateProperty: ['propertyId'],
  deactivatePropertySuccess: ['property'],
  deactivatePropertyFailure: null
})

export const PropertyTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  deactivateFetching: null,
  property: null,
  properties: null,
  total: null
})

/* ------------- Reducers ------------- */

const createProperty = (state) => state.merge({ fetching: true })
const createPropertyFinished = (state) => state.merge({ fetching: false })

const getProperty = (state) => state.merge({ fetching: true })
const getPropertySuccess = (state, { property }) => state.merge({ property, fetching: false })
const getPropertyFailure = (state) => state.merge({ fetching: false })

const getProperties = (state) => state.merge({ fetching: true })
const getPropertiesSuccess = (state, { properties, total }) => state.merge({ properties, total, fetching: false })
const getPropertiesFailure = (state) => state.merge({ fetching: false })

const setProperty = (state, { property }) => state.merge({ property })

const updateProperty = (state) => state.merge({ fetching: true })
const updatePropertySuccess = (state, { property }) => state.merge({ property, fetching: false })
const updatePropertyFailure = (state) => state.merge({ fetching: false })

const deactivateProperty = (state) => state.merge({ deactivateFetching: true })
const deactivatePropertySuccess = (state, { property }) => state.merge({ property, deactivateFetching: false })
const deactivatePropertyFailure = (state) => state.merge({ deactivateFetching: false })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_PROPERTY]: createProperty,
  [Types.CREATE_PROPERTY_FINISHED]: createPropertyFinished,
  [Types.GET_PROPERTY]: getProperty,
  [Types.GET_PROPERTY_SUCCESS]: getPropertySuccess,
  [Types.GET_PROPERTY_FAILURE]: getPropertyFailure,
  [Types.GET_PROPERTIES]: getProperties,
  [Types.GET_PROPERTIES_SUCCESS]: getPropertiesSuccess,
  [Types.GET_PROPERTIES_FAILURE]: getPropertiesFailure,
  [Types.SET_PROPERTY]: setProperty,
  [Types.UPDATE_PROPERTY]: updateProperty,
  [Types.UPDATE_PROPERTY_SUCCESS]: updatePropertySuccess,
  [Types.UPDATE_PROPERTY_FAILURE]: updatePropertyFailure,
  [Types.DEACTIVATE_PROPERTY]: deactivateProperty,
  [Types.DEACTIVATE_PROPERTY_SUCCESS]: deactivatePropertySuccess,
  [Types.DEACTIVATE_PROPERTY_FAILURE]: deactivatePropertyFailure
})
