export const CLIENT_ERROR = 'CLIENT_ERROR'
export const NETWORK_ERROR = 'NETWORK_ERROR'
export const TIMEOUT_ERROR = 'TIMEOUT_ERROR'
export const CONNECTION_ERROR = 'CONNECTION_ERROR'
export const CLIENT_CONNECTION_ERRORS = [NETWORK_ERROR, TIMEOUT_ERROR, CONNECTION_ERROR]

export const ROLES = {
  SUPERADMIN: 'superadmin',
  ADMIN: 'admin',
  USER: 'user'
}

export const EXTENDED_ROLES = {
  ...ROLES,
  DOMAIN_ADMIN: 'domain_admin'
}

export const LOCALES = {
  FI: 'fi',
  EN: 'en'
}

export const ASSET_TRANSACTION_TYPES = {
  CREATE: 'create',
  ACCEPT: 'accept',
  REJECT: 'reject'
}

export const ASSET_HOLDERS = {
  USER: 'user',
  ASSET_STORAGE: 'asset_storage'
}

export const BIG_LIMIT = 1000000000

export const FIELDS = {
  ID: 'id',
  ORGANIZATION_ID: 'organizationId',
  CUSTOMER_ID: 'customerId',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE: 'phone',
  CONTACT_PERSON: 'contactPerson',
  BUSINESS_ID: 'businessId',
  ADDRESS: 'address',
  POSTAL_CODE: 'postalCode',
  CITY: 'city',
  NAME: 'name',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  DOMAINS: 'domains',
  ACTIVE: 'active',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt'
}
