import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../utils/withRoot'
import UserActions from '../redux/UserRedux'
import DomainActions from '../redux/DomainRedux'
import AssetActions from '../redux/AssetRedux'
import AssetTransactionActions from '../redux/AssetTransactionRedux'
import CustomerActions from '../redux/CustomerRedux'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import UserInfoForm from '../components/User/UserInfoForm'
import UserContactInfoForm from '../components/User/UserContactInfoForm'
import { ROLES, LOCALES, BIG_LIMIT, EXTENDED_ROLES, FIELDS } from '../utils/constants'
import { isDomainAdmin } from '../utils/transforms'
import UserDomainForm from '../components/User/UserDomainForm'
import AssetTransactionLog from '../components/AssetTransaction/AssetTransactionLog'
import { isUser, isEditDisabled } from '../utils/permissions'
import UserAssets from '../components/User/UserAssets'

const styles = theme => ({
})

class UserScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = this.generateState(props)
  }

  componentDidMount () {
    const { currentOrganizationId, user, match, customers } = this.props
    if (currentOrganizationId && user && !isUser(user)) {
      this.props.getAllDomains(currentOrganizationId)
    }
    if (currentOrganizationId && !customers) {
      this.props.getCustomers(currentOrganizationId)
    }
    this.props.getTargetUser(match.params.userId)
    this.props.getUserAssets(match.params.userId)
  }

  componentWillReceiveProps (nextProps) {
    const { currentOrganizationId, user, targetUser } = nextProps
    // Get all domains user when current user is set and user is admin/superadmin
    if (!this.props.currentOrganizationId && currentOrganizationId && user && !isUser(user)) {
      this.props.getAllDomains(currentOrganizationId)
    }
    // Populate fields when target user is set
    if (!this.props.targetUser && targetUser) {
      this.setState(this.generateState(nextProps))
    }
  }

  generateState = (props) => {
    let role = get(props, 'targetUser.role', '')
    if (role === ROLES.USER) {
      // Check if user is domain admin
      if (isDomainAdmin(props.targetUser)) {
        role = EXTENDED_ROLES.DOMAIN_ADMIN
      }
    }
    return {
      email: get(props, 'targetUser.email') || '',
      role,
      firstName: get(props, 'targetUser.firstName') || '',
      lastName: get(props, 'targetUser.lastName') || '',
      title: get(props, 'targetUser.title') || '',
      phone: get(props, 'targetUser.phone') || '',
      locale: get(props, 'targetUser.locale') || LOCALES.FI,
      domains: get(props, 'targetUser.domains', []).map((domain) => domain.id),
      editing: props.location.state && props.location.state.edit
    }
  }

  setEmail = (evt) => this.setState({ email: evt.target.value })

  setRole = (evt) => {
    console.log(evt.target.value)
    this.setState({ role: evt.target.value, domains: [] })
  }

  setFirstName = (evt) => this.setState({ firstName: evt.target.value })

  setLastName = (evt) => this.setState({ lastName: evt.target.value })

  setTitle = (evt) => this.setState({ title: evt.target.value })

  setPhone = (evt) => this.setState({ phone: evt.target.value })

  setLocale = (evt) => this.setState({ locale: evt.target.value })

  setDomain = (domainId) => {
    let domains = this.state.domains
    if (domains.includes(domainId)) {
      domains = domains.filter(domain => domain !== domainId)
      this.setState({ domains })
    } else {
      domains = domains.concat(domainId)
      this.setState({ domains })
    }
  }

  setEditing = () => this.setState({ editing: true })

  updateUser = async () => {
    const { targetUser, updateTargetUser } = this.props
    const { role, domains } = this.state
    const userRole = role === EXTENDED_ROLES.DOMAIN_ADMIN ? ROLES.USER : role
    let targetRole = targetUser.role
    if (targetRole === ROLES.USER) {
      // Check if user is domain admin
      if (isDomainAdmin(targetUser)) {
        targetRole = EXTENDED_ROLES.DOMAIN_ADMIN
      }
    }

    if (targetRole !== role) {
      // If role changed, redo all user domains
      const targetUserDomainIds = targetUser.domains.map(domain => domain.id)
      this.props.updateUserDomains(targetUser.id, targetUserDomainIds, this.state.domains, role)
    } else {
      // If role was not changed, add/remove domains that were changed
      const previousDomainIds = targetUser.domains.map(domain => domain.id)
      const newDomains = domains.filter(domain => !previousDomainIds.includes(domain))
      const removedDomains = previousDomainIds.filter(domain => !domains.includes(domain))
      if (newDomains.length || removedDomains.length) {
        this.props.updateUserDomains(targetUser.id, removedDomains, newDomains, role)
      }
    }
    const fields = {
      email: this.state.email.trim(),
      role: userRole,
      firstName: this.state.firstName.trim(),
      lastName: this.state.lastName.trim(),
      title: this.state.title.trim() || null,
      phone: this.state.phone.trim() || null,
      locale: this.state.locale.trim()
    }

    updateTargetUser(targetUser.id, fields)
    this.setState({ editing: false })
  }

  isEditUserDisabled = () => {
    // Check required fields
    const { role, domains, editing } = this.state
    const { targetUser } = this.props
    if (!editing) return false
    if (targetUser) {
      const required = [FIELDS.FIRST_NAME, FIELDS.LAST_NAME, FIELDS.EMAIL]
      if (role !== ROLES.ADMIN && !targetUser.domains.length && domains.length) {
        return false
      } else if (role !== ROLES.ADMIN && !domains.length) {
        return true
      }
      let targetUserWithFlatDomains
      let targetRole = targetUser.role
      if (targetRole === ROLES.USER) {
        // Check if user is domain admin
        if (isDomainAdmin(targetUser)) {
          targetRole = EXTENDED_ROLES.DOMAIN_ADMIN
        }
      }

      if (targetRole !== role) {
        targetUserWithFlatDomains = { ...targetUser, role: targetRole, domains: [] }
      } else {
        targetUserWithFlatDomains = { ...targetUser, role: targetRole, domains: targetUser.domains.map(domain => domain.id) }
      }
      return isEditDisabled(targetUserWithFlatDomains, this.state, required)
    }
    return true
  }

  isCreateAssetDisabled = () => {
    const visibleCustomers = this.props.customers ? this.props.customers.results : 0
    return Boolean(visibleCustomers && !visibleCustomers.length)
  }

  getAllDomains = () => {
    const { user, allDomains } = this.props
    if (!user) return []
    if (isUser(user)) {
      // Return all domains where user is domain admin
      return user.domains.filter((userDomain) => userDomain.role === ROLES.ADMIN)
    }
    return allDomains ? allDomains.results : []
  }

  renderUserDomainForm = () => {
    const { user, targetUser } = this.props
    const { role, domains, editing } = this.state
    if (!targetUser) return null
    // Disable role for domain admin
    const roleDisabled = !user || isUser(user)
    const disabledForSelf = user && user.id === targetUser.id
    return (
      <UserDomainForm
        allDomains={this.getAllDomains()}
        user={targetUser}
        currentUser={user}
        role={role}
        roleDisabled={roleDisabled}
        disabledForSelf={disabledForSelf}
        setRole={this.setRole}
        domains={domains}
        setDomain={this.setDomain}
        editing={editing}
      />
    )
  }

  getTransactions = (params) => this.props.getUserTransactions(this.props.match.params.userId, params)

  getTitle = () => {
    const { targetUser } = this.props
    if (targetUser) return (`${targetUser.firstName} ${targetUser.lastName}`)
    return null
  }

  renderPage = () => {
    const {
      t, fetching, user, transactions, totalTransactions, transactionsFetching, setTransaction,
      assets, totalAssets, assetsFetching, setAsset, targetUser, ...rest
    } = this.props
    const { email, firstName, lastName, title, phone, locale, editing } = this.state
    return (
      <Layout>
        <PageHeader
          title={this.getTitle()}
          onClick={this.updateUser}
          disabled={this.isEditUserDisabled()}
          loading={fetching}
          editing={editing}
          setEditing={this.setEditing}
        />
        <UserContactInfoForm
          email={email}
          firstName={firstName}
          lastName={lastName}
          phone={phone}
          setEmail={this.setEmail}
          setFirstName={this.setFirstName}
          setLastName={this.setLastName}
          setPhone={this.setPhone}
          editing={editing}
        />
        <UserInfoForm
          title={title}
          locale={locale}
          setTitle={this.setTitle}
          setLocale={this.setLocale}
          user={user}
          editing={editing}
        />
        {this.renderUserDomainForm()}
        <UserAssets
          assets={assets}
          total={totalAssets}
          loading={assetsFetching}
          setAsset={setAsset}
          disabled={this.isCreateAssetDisabled()}
          {...rest}
        />
        <AssetTransactionLog
          transactions={transactions}
          loading={transactionsFetching}
          getTransactions={this.getTransactions}
          setTransaction={setTransaction}
          total={totalTransactions}
          {...rest}
        />
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = (state) => {
  return {
    currentOrganizationId: state.user.currentOrganizationId,
    fetching: state.user.fetching,
    error: state.notification.error,
    user: state.user.user,
    allDomains: state.domain.domains,
    targetUser: state.user.targetUser,
    transactions: state.assetTransaction.assetTransactions,
    totalTransactions: state.assetTransaction.total,
    transactionsFetching: state.assetTransaction.fetching,
    assets: state.asset.assets,
    assetsFetching: state.asset.fetching,
    totalAssets: state.asset.total,
    customers: state.customer.customers
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTargetUser: (userId) => dispatch(UserActions.getTargetUser(userId)),
    updateTargetUser: (userId, fields) => dispatch(UserActions.updateTargetUser(userId, fields)),
    getAllDomains: (organizationId) => dispatch(DomainActions.getDomains(organizationId, { offset: 0, limit: BIG_LIMIT })),
    getUserTransactions: (userId, params) => dispatch(AssetTransactionActions.getUserAssetTransactions(userId, params)),
    setTransaction: (assetTransaction) => dispatch(AssetTransactionActions.setAssetTransaction(assetTransaction)),
    getUserAssets: (id, params) => dispatch(AssetActions.getUserAssets(id, params)),
    setAsset: (asset) => dispatch(AssetActions.setAsset(asset)),
    updateUserDomains: (userId, oldDomainIds, newDomainIds, role) => dispatch(UserActions.updateUserDomains(userId, oldDomainIds, newDomainIds, role)),
    getCustomers: (organizationId, params) => dispatch(CustomerActions.getCustomers(organizationId, params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRoot(withStyles(styles)(withTranslation()(UserScreen)))
)
