import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from '../Input'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  paper: {
    maxWidth: 600,
    borderRadius: 5,
    margin: 10
  },
  modalContent: {
    backgroundColor: COLORS.darkBackground,
    border: `1px solid ${COLORS.border}`,
    width: 600,
    borderRadius: 5,
    padding: '60px !important',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '20px !important'
    }
  },
  title: {
    color: COLORS.white,
    margin: 0,
    marginBottom: 20,
    fontSize: 24,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    }
  },
  infoText: {
    margin: 0,
    marginBottom: 20,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 20
    }
  },
  bottomContainer: {
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    }
  },
  button: {
    color: COLORS.white,
    background: COLORS.violet, // `radial-gradient(${COLORS.violetGradientStart} 0%, ${COLORS.violetGradientEnd} 100%)`,
    '&:hover': {
      background: darken(COLORS.violet, 0.2)
    },
    width: 200,
    height: 50,
    marginTop: 10,
    borderRadius: 5,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 40,
      width: 120,
      borderRadius: 5
    }
  },
  outlinedButton: {
    color: COLORS.white,
    background: COLORS.transparent, // `radial-gradient(${COLORS.violetGradientStart} 0%, ${COLORS.violetGradientEnd} 100%)`,
    '&:hover': {
      background: COLORS.violet
    },
    border: `1px solid ${COLORS.violet}`,
    width: 200,
    height: 50,
    marginTop: 10,
    marginLeft: 30,
    borderRadius: 5,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 15,
      height: 40,
      width: 120,
      borderRadius: 5
    }
  },
  buttonText: {
    margin: 0,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 18,
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  loading: {
    color: COLORS.white
  },
  spacer: {
    height: 10
  }
})

class ChangePasswordModal extends React.PureComponent {
  componentWillReceiveProps (nextProps) {
    if (this.props.loading && !nextProps.loading && !this.props.error) {
      this.props.handleClose()
    }
  }

  renderButtonContent = () => {
    const { classes, t, loading } = this.props
    if (loading) return <CircularProgress className={classes.loading} size={22} />
    return <p className={classes.buttonText}>{t('save')}</p>
  }

  renderModal = () => {
    const {
      classes, visible, handleClose, t, onSubmit, currentPassword, newPassword,
      setCurrentPassword, setNewPassword
    } = this.props
    return (
      <Dialog open={visible} onClose={handleClose} classes={{ paper: classes.paper }}>
        <DialogContent className={classes.modalContent}>
          <h2 className={classes.title}>{t('change_password')}</h2>
          <p className={classes.infoText}>{t('new_password_info')}</p>
          <Input
            placeholder={t('current_password')}
            value={currentPassword}
            onChange={setCurrentPassword}
            type='password'
            autoFocus
          />
          <div className={classes.spacer} />
          <Input
            placeholder={t('new_password')}
            value={newPassword}
            onChange={setNewPassword}
            type='password'
          />
          <div className={classes.bottomContainer}>
            <Button className={classes.button} onClick={onSubmit}>
              {this.renderButtonContent()}
            </Button>
            <Button className={classes.outlinedButton} onClick={handleClose}>
              <p className={classes.buttonText}>{t('cancel')}</p>
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  render () {
    return this.renderModal()
  }
}

export default withStyles(styles)(withTranslation()(ChangePasswordModal))
