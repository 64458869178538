import SettingsIcon from '../static/asetukset.svg'
import UsersIcon from '../static/kayttajat.svg'
import CompanyInfoIcon from '../static/yrityksen-tiedot.svg'
import KeysIcon from '../static/avaimet.svg'
import StoragesIcon from '../static/kaapit.svg'
import DomainsIcon from '../static/toimialat.svg'
import OrganizationsIcon from '../static/organisaatiot.svg'
import CustomersIcon from '../static/asiakkaat.svg'

export const MENU_ITEMS = [
  {
    header: 'general',
    items: [
      { title: 'company_info', icon: CompanyInfoIcon, path: '/yrityksen-tiedot' },
      { title: 'settings', icon: SettingsIcon, path: '/asetukset' }
    ]
  },
  {
    header: 'resources',
    items: [
      { title: 'users', icon: UsersIcon, path: '/kayttajat' },
      { title: 'customers', icon: CustomersIcon, path: '/asiakkaat' },
      { title: 'domains', icon: DomainsIcon, path: '/toimialat' },
      { title: 'key_storages', icon: StoragesIcon, path: '/avainkaapit' },
      { title: 'keys', icon: KeysIcon, path: '/avaimet' }
    ]
  }
]

export const SUPERADMIN_ALL_MENU_ITEMS = [
  {
    header: 'general',
    items: [
      { title: 'organizations', icon: OrganizationsIcon, path: '/organisaatiot' },
      { title: 'settings', icon: SettingsIcon, path: '/asetukset' }
    ]
  },
  {
    header: 'resources',
    items: [
      { title: 'company_info', icon: CompanyInfoIcon, path: '/yrityksen-tiedot' },
      { title: 'users', icon: UsersIcon, path: '/kayttajat' },
      { title: 'customers', icon: CustomersIcon, path: '/asiakkaat' },
      { title: 'domains', icon: DomainsIcon, path: '/toimialat' },
      { title: 'key_storages', icon: StoragesIcon, path: '/avainkaapit' },
      { title: 'keys', icon: KeysIcon, path: '/avaimet' }
    ]
  }
]

export const SUPERADMIN_LIMITED_MENU_ITEMS = [
  {
    header: 'general',
    items: [
      { title: 'organizations', icon: OrganizationsIcon, path: '/organisaatiot' },
      { title: 'settings', icon: SettingsIcon, path: '/asetukset' }
    ]
  }
]
