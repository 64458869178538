import { call, put, select } from 'redux-saga/effects'
import UserActions from '../redux/UserRedux'
import OrganizationActions from '../redux/OrganizationRedux'
import NotificationActions from '../redux/NotificationRedux'
import { ORGANIZATION_ID, writeStorage } from '../utils/storage'
import { composeError } from '../utils/transforms'

export function * setOrganization (action) {
  const { organization } = action
  yield put(UserActions.setCurrentOrganizationId(organization.id))
  writeStorage(ORGANIZATION_ID, organization.id)
}

export function * getOrganization (api, action) {
  const { id } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.getOrganization, token, id)
  console.log(response)
  if (response.ok) {
    const organization = response.data
    yield put(OrganizationActions.getOrganizationSuccess(organization))
  } else {
    yield put(OrganizationActions.getOrganizationFailure())
  }
}

export function * getOrganizations (api, action) {
  const { params } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.getOrganizations, token, params)
  console.log(response)
  if (response.ok) {
    const organizations = response.data.results
    const total = response.data.total
    yield put(OrganizationActions.getOrganizationsSuccess(organizations, total))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(OrganizationActions.getOrganizationsFailure())
  }
}

export function * createOrganization (api, action) {
  const { organization } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.createOrganization, token, organization)
  console.log(response)
  if (response.ok) {
    yield put(OrganizationActions.createOrganizationFinished())
    yield put(NotificationActions.setSuccess('create_organization_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(OrganizationActions.createOrganizationFinished())
  }
}

export function * updateOrganization (api, action) {
  const { id, fields } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.updateOrganization, token, id, fields)
  console.log(response)
  if (response.ok) {
    const organization = response.data
    yield put(OrganizationActions.updateOrganizationSuccess(organization))
    yield put(NotificationActions.setSuccess('update_organization_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(OrganizationActions.updateOrganizationFailure())
  }
}

export function * deactivateOrganization (api, action) {
  const { id } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.deactivateOrganization, token, id)
  console.log(response)
  if (response.ok) {
    yield put(OrganizationActions.deactivateOrganizationFinished())
    yield put(NotificationActions.setSuccess('deactivate_organization_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(OrganizationActions.deactivateOrganizationFinished())
  }
}
