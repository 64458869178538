import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import BackgroundImage from '../../static/bg-image.png'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  container: {
    fontSize: '1.5vw',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  cornerBgInner: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'absolute',
    height: '31em',
    width: '37em',
    top: 0,
    right: 0
  },
  triangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '31em 0 0 37em',
    borderColor: `transparent transparent transparent ${COLORS.darkBackground}`
  },
  shade: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '31em 0 0 37em',
    borderColor: `rgba(0,0,0,.3) transparent transparent transparent`,
    position: 'absolute',
    top: 0,
    right: 0
  }
})

class LoginCornerBg extends React.PureComponent {
  render () {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.cornerBgInner}>
          <div className={classes.triangle} />
          <div className={classes.shade} />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(LoginCornerBg)
