import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import withRoot from '../utils/withRoot'
import UserActions from '../redux/UserRedux'
import NotificationActions from '../redux/NotificationRedux'
import Notification from '../components/Notification'
import ResetPasswordForm from '../components/Auth/ResetPasswordForm'
import { COLORS } from '../utils/colors'

const styles = theme => ({
  container: {
    backgroundColor: COLORS.darkBackground,
    minHeight: '100vh',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    padding: '80px 60px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 20px'
    }
  }
})

class ResetPasswordScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      password: '',
      passwordAgain: '',
      code: '',
      email: ''
    }
  }

  componentDidMount () {
    const values = queryString.parse(this.props.location.search)
    if (values && values.code && values.email) {
      this.setState({ code: values.code, email: decodeURIComponent(values.email) })
      return this.props.checkCodeValidity(values.code, values.email)
    }
    return this.props.setError()
  }

  getRedirectPath = () => {
    const { error, success, token, user } = this.props
    const { password } = this.state

    if (token && user) return '/dashboard'
    if ((error && !password) || success) {
      this.props.clearNotifications()
      return '/'
    }
    return null
  }

  setPassword = (evt) => this.setState({ password: evt.target.value })

  setPasswordAgain = (evt) => this.setState({ passwordAgain: evt.target.value })

  resetPassword = () => {
    const { code, email, password } = this.state
    this.props.resetPassword(code, email, password)
  }

  renderSuccessNotification = () => {
    const { success, t, clearNotifications } = this.props
    const message = success ? t('password_reset_success') : null
    return <Notification type='success' message={message} onClose={clearNotifications} />
  }

  renderErrorNotification = () => {
    const { error, t, clearNotifications } = this.props
    const message = (error && error.message) ? t(error.message) : null
    return <Notification type='error' message={message} onClose={clearNotifications} />
  }

  render () {
    const { classes, fetching } = this.props
    const { password, passwordAgain } = this.state

    // Redirect when certain conditions fulfill
    const path = this.getRedirectPath()
    if (path) return <Redirect to={path} />

    return (
      <div className={classes.container}>
        <ResetPasswordForm
          password={password}
          passwordAgain={passwordAgain}
          setPassword={this.setPassword}
          setPasswordAgain={this.setPasswordAgain}
          onSubmit={this.resetPassword}
          loading={fetching}
        />
        {this.renderSuccessNotification()}
        {this.renderErrorNotification()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    user: state.user.user,
    fetching: state.user.fetching,
    success: state.notification.success,
    error: state.notification.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (code, email, password) => dispatch(UserActions.resetPassword(code, email, password)),
    checkCodeValidity: (code, email) => dispatch(UserActions.checkCodeValidity(code, email)),
    setError: () => dispatch(NotificationActions.setError(true)),
    clearNotifications: () => dispatch(NotificationActions.clearNotifications())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRoot(withStyles(styles)(withTranslation()(ResetPasswordScreen)))
)
