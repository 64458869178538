import React from 'react'
import { withStyles, CircularProgress } from '@material-ui/core'
import withRoot from '../../utils/withRoot'
import { withTranslation } from 'react-i18next'
import throttle from 'lodash/throttle'
import { COLORS } from '../../utils/colors'
import AssetTransaction from './AssetTransaction'

const styles = theme => ({
  container: {
    maxWidth: 1000,
    padding: '40px 60px 0px 60px',
    clear: 'both'
  },
  header: {
    color: COLORS.white,
    fontSize: 24,
    margin: 0,
    marginBottom: 30,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: 30
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: COLORS.white
  },
  empty: {
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 16
  }
})

class AssetTransactionLog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      pageSize: 10,
      page: 0,
      items: []
    }
    this.throttledOnScroll = throttle(this.onScroll, 100)
  }

  componentDidMount () {
    window.addEventListener('scroll', this.throttledOnScroll, false)
    this.fetchItems()
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.throttledOnScroll, false)
  }

  componentWillReceiveProps (nextProps) {
    // Push received items into state
    if (nextProps.transactions && this.props.transactions !== nextProps.transactions) {
      const newItems = this.state.items
      nextProps.transactions.forEach(transaction => {
        if (!newItems.some(item => item.id === transaction.id)) {
          newItems.push(transaction)
        }
      })
      this.setState({ items: newItems })
    }
  }

  fetchItems = () => {
    const { getTransactions, total } = this.props
    const { page, pageSize, items } = this.state

    if (total >= items.length || !items.length) {
      getTransactions({ limit: pageSize, offset: page * pageSize })
      this.setState({ page: page + 1 })
    }
  }

  onScroll = () => {
    const { transactions, loading } = this.props
    // Fetch more if close to bottom and previous fetch returned something
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 &&
      transactions &&
      transactions.length &&
      !loading
    ) {
      this.fetchItems()
    }
  }

  toAssetTransactionPage = transaction => {
    this.props.setTransaction(transaction)
    this.props.history.push(`/tapahtuma/${transaction.id}`)
  }

  renderLoading = () => {
    const { classes, loading } = this.props
    if (loading) return <CircularProgress className={classes.loading} size={30} />
  }

  renderItems = () => {
    const { t, classes, loading } = this.props
    const { items } = this.state
    if (items && items.length) {
      return items.map(item => <AssetTransaction key={item.id} transaction={item} onClick={this.toAssetTransactionPage} />)
    }
    // Render nothing if no items and loading - loading indicator is rendered under this
    if (!items.length && loading) return null
    return <p className={classes.empty}>{t('transactions_empty')}</p>
  }

  render () {
    const { t, classes } = this.props
    return (
      <div className={classes.container}>
        <p className={classes.header}>{t('transactions')}</p>
        {this.renderItems()}
        {this.renderLoading()}
      </div>
    )
  }
}

export default withRoot(withStyles(styles)(withTranslation()(AssetTransactionLog)))
