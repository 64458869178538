import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '../../static/muokkaa.svg'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  root: {
    backgroundColor: COLORS.dark,
    height: 50
  },
  hover: {
    '&$hover:hover': {
      backgroundColor: COLORS.darkBackground
    }
  },
  cell: {
    fontSize: 16,
    fontFamily: 'Roboto',
    color: COLORS.white,
    textAlign: 'center',
    border: `0.5px solid ${COLORS.border}`
  },
  editBtn: {
    padding: 0,
    width: 40,
    height: 40,
    borderRadius: 20
  }
})

class DomainTableRow extends React.PureComponent {
  editDomain = () => this.props.editDomain(this.props.domain)

  renderEditCell = () => {
    const { classes, showEdit } = this.props
    if (!showEdit) return null
    return <TableCell className={classes.cell}>{this.renderEditButton()}</TableCell>
  }

  renderEditButton = () => {
    const { classes, t, editable } = this.props
    if (!editable) return null
    return (
      <IconButton onClick={this.editDomain} className={classes.editBtn} aria-label={t('edit')}>
        <img src={EditIcon} alt={t('edit')} />
      </IconButton>
    )
  }

  render () {
    const { classes, domain } = this.props
    return (
      <TableRow classes={{ root: classes.root, hover: classes.hover }} hover>
        <TableCell className={classes.cell}>{domain.name}</TableCell>
        {this.renderEditCell()}
      </TableRow>
    )
  }
}

export default withStyles(styles)(withTranslation()(DomainTableRow))
