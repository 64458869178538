import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import withRoot from '../utils/withRoot'
import AssetStorageActions from '../redux/AssetStorageRedux'
import NotificationActions from '../redux/NotificationRedux'
import MAPS from '../services/mapsApi'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import AssetStorageForm from '../components/AssetStorage/AssetStorageForm'
import AssetStorageLocationForm from '../components/AssetStorage/AssetStorageLocationForm'

const styles = theme => ({})
const api = MAPS.create()

class CreateAssetStorageScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = this.getInitialFields()
  }

  componentWillReceiveProps (nextProps) {
    // Clear fields on success
    const { fetching, error } = this.props
    if (fetching && !nextProps.fetching && !error) {
      this.resetFields()
    }
  }

  getInitialFields = () => ({
    name: '',
    description: '',
    address: '',
    postalCode: '',
    city: '',
    latitude: '',
    longitude: ''
  })

  resetFields = () => this.setState(this.getInitialFields())

  setName = evt => this.setState({ name: evt.target.value })

  setDescription = evt => this.setState({ description: evt.target.value })

  setAddress = evt => this.setState({ address: evt.target.value })

  setPostalCode = evt => this.setState({ postalCode: evt.target.value })

  setCity = evt => this.setState({ city: evt.target.value })

  setLatitude = evt => this.setState({ latitude: evt.target.value })

  setLongitude = evt => this.setState({ longitude: evt.target.value })

  isCreateAssetStorageDisabled = () => {
    const { name, address, postalCode, city } = this.state
    if (!name.trim() || !address.trim() || !postalCode.trim() || !city.trim()) return true
  }

  getLatLng = async () => {
    const { address, city, postalCode } = this.state
    if (address && city && postalCode) {
      const response = await api.getCoordsByPlace(address, city, postalCode)
      if (response.ok && response.data.results[0]) {
        const { lat, lng } = response.data.results[0].geometry.location
        this.setState({ latitude: lat, longitude: lng })
      } else {
        this.props.setError({ key: 'client_error', message: 'try_again' })
      }
    }
  }

  createAssetStorage = () => {
    const { name, address, postalCode, city, description } = this.state
    const { createAssetStorage, currentOrganizationId } = this.props
    const fields = {}
    if (name) fields.name = name
    if (address) fields.address = address
    if (postalCode) fields.postalCode = postalCode
    if (city) fields.city = city
    if (description) fields.description = description
    createAssetStorage(currentOrganizationId, fields)
  }

  renderPage = () => {
    const { t, fetching } = this.props
    const { name, description, address, postalCode, city, latitude, longitude } = this.state
    return (
      <Layout>
        <PageHeader
          title={t('create_asset_storage')}
          onClick={this.createAssetStorage}
          disabled={this.isCreateAssetStorageDisabled()}
          loading={fetching}
          editing
        />
        <AssetStorageForm
          name={name}
          description={description}
          setName={this.setName}
          setDescription={this.setDescription}
          editing
        />
        <AssetStorageLocationForm
          address={address}
          postalCode={postalCode}
          city={city}
          latitude={latitude}
          longitude={longitude}
          setAddress={this.setAddress}
          setPostalCode={this.setPostalCode}
          setCity={this.setCity}
          setLatitude={this.setLatitude}
          setLongitude={this.setLongitude}
          getLatLng={this.getLatLng}
          editing
        />
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = state => {
  return {
    currentOrganizationId: state.user.currentOrganizationId,
    fetching: state.assetStorage.fetching,
    token: state.user.token,
    error: state.notification.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createAssetStorage: (organizationId, fields) => dispatch(AssetStorageActions.createAssetStorage(organizationId, fields)),
    setError: (error) => dispatch(NotificationActions.setError(error))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoot(withStyles(styles)(withTranslation()(CreateAssetStorageScreen))))
