import React from 'react'
import { withTranslation } from 'react-i18next'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import SelectInput from '../SelectInput'
import { COLORS } from '../../utils/colors'
import { EXTENDED_ROLES } from '../../utils/constants'

const styles = theme => ({
  container: {
    maxWidth: 1000,
    padding: '20px 60px 0'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  header: {
    color: COLORS.white,
    fontSize: 24,
    margin: 0,
    marginTop: 20,
    marginBottom: 30,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  asterisk: {
    color: COLORS.red
  },
  row: {
    display: 'flex',
    marginBottom: 30
  },
  column: {
    flexDirection: 'column',
    minWidth: 'calc(50% - 30px)',
    alignSelf: 'center'
  },
  spacer: {
    minWidth: 60
  },
  label: {
    margin: 0,
    marginLeft: 15,
    marginBottom: 15,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 10,
      marginLeft: 5
    }
  },
  value: {
    margin: 0,
    marginLeft: 15,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginLeft: 5
    }
  },
  removeButton: {
    marginLeft: 20,
    padding: 0,
    width: 24,
    height: 24,
    color: COLORS.white
  },
  text: {
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 18,
    paddingTop: 22
  },
  domain: {
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 16,
    // padding: 16,
    lineHeight: 'normal',
    width: 200
  },
  domainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    listStyle: 'none',
    marginTop: 20,
    marginBottom: 30,
    paddingInlineStart: 0,
    maxHeight: 300,
    maxWidth: 1000
  },
  domainContainer: {
    marginRight: 30,
    backgroundColor: COLORS.dark,
    borderRadius: 3,
    paddingLeft: 20,
    marginBottom: 10,
    // boxShadow: `0 2px 5px ${COLORS.dark}`,
    border: `1px solid ${COLORS.border}`,
    width: 'min-content'
  },
  topMargin: {
    marginTop: 30
  }
})

const { USER, ADMIN, DOMAIN_ADMIN, SUPERADMIN } = EXTENDED_ROLES

class UserDomainForm extends React.PureComponent {
  handleSetDomain = domainId => {
    this.props.setDomain(domainId)
  }

  userHasDomain = domainId => {
    const { domains } = this.props
    if (domains) {
      return domains.some(domain => domain === domainId)
    }
    return false
  }

  sortCompare = (a, b) => {
    if (!a || !b || !a.name || !b.name) return 0
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    return 0
  }

  renderRemoveButton = domainId => {
    const { classes, t, user, currentUser, allDomains } = this.props
    const disabled = !allDomains.find(domain => domain.id === domainId)
    if (user.id !== currentUser.id) {
      return (
        <IconButton
          onClick={() => this.props.removeUserDomain(user.id, domainId)}
          className={classes.removeButton}
          aria-label={t('remove')}
          disabled={disabled}
        >
          <DeleteIcon />
        </IconButton>
      )
    }
    return null
  }

  renderReminder = () => {
    const { t, classes, role, domains } = this.props
    if ((role === DOMAIN_ADMIN || role === USER) && (domains && !domains.length)) {
      return (
        <div className={classes.column}>
          <p className={classes.text}>{t('select_domain')}</p>
        </div>
      )
    }
    return null
  }

  renderRoleSelect = () => {
    const { t, classes, role, setRole, currentUser, roleDisabled, disabledForSelf } = this.props

    let roleOptions = [USER]
    if (currentUser.role === SUPERADMIN || currentUser.role === ADMIN || roleDisabled) {
      // Admin and super admin can also create admins and domain admins
      roleOptions = [...roleOptions, DOMAIN_ADMIN, ADMIN]
    }
    roleOptions = roleOptions.map((value, index) => ({ name: t(value), value }))

    return (
      <div className={classes.column}>
        <SelectInput
          label={t('role')}
          placeholder={t('role')}
          value={role}
          options={roleOptions}
          onChange={setRole}
          disabled={roleDisabled || disabledForSelf}
        />
      </div>
    )
  }

  renderDomainList = () => {
    const { t, allDomains, classes, role, setDomain, disabledForSelf, domains, editing } = this.props
    if (allDomains && (role === USER || role === DOMAIN_ADMIN)) {
      if (editing) {
        return (
          <div>
            <p className={classes.label}>{t('role_domains')}</p>
            <div className={classes.domainWrapper}>
              {[...allDomains].sort(this.sortCompare).map(domain => {
                const isChecked = this.userHasDomain(domain.id)
                const onChange = () => setDomain(domain.id)
                return (
                  <div key={domain.id} className={classes.domainContainer}>
                    <FormControlLabel
                      control={<Checkbox checked={isChecked} onChange={onChange} disabled={disabledForSelf} />}
                      label={domain.name}
                      classes={{ label: classes.domain }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        )
      }
      return (
        <div className={classes.topMargin}>
          <p className={classes.label}>{t('role_domains')}</p>
          <div className={classes.domainWrapper}>
            {domains.map(domain => {
              const assignedDomain = allDomains.find(d => d.id === domain)
              if (assignedDomain) {
                return (
                  <div key={domain} className={classes.domainContainer}>
                    <p className={classes.domain}>{assignedDomain.name}</p>
                  </div>
                )
              }
              return null
            })}
          </div>
        </div>
      )
    }
    return null
  }

  renderContent = () => {
    const { classes, t, editing, role } = this.props
    if (editing) {
      return (
        <div className={classes.row}>
          {this.renderRoleSelect()}
          <div className={classes.spacer} />
          {this.renderReminder()}
        </div>
      )
    }
    return (
      <div className={classes.column}>
        <p className={classes.label}>{t('role')}</p>
        <p className={classes.value}>{t(role)}</p>
      </div>
    )
  }

  render () {
    const { classes, t, editing } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <p className={classes.header}>
            {t('user_permissions')}
            {editing && <span className={classes.asterisk}>*</span>}
          </p>
        </div>
        {this.renderContent()}
        {this.renderDomainList()}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(UserDomainForm))
