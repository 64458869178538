import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './locales/en.json'
import fi from './locales/fi.json'

export const languages = [
  { name: 'Suomi', value: 'fi' }, // First item is used as a fallback language
  { name: 'English', value: 'en' }
]

export default i18n
  .use(initReactI18next)
  .init({
    fallbackLng: languages[0].value,
    resources: {
      en: {
        translation: en
      },
      fi: {
        translation: fi
      }
    }
  })
