import React from 'react'
import * as moment from 'moment'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  container: {
    maxWidth: 1000,
    padding: '0px 60px'
  },
  header: {
    color: COLORS.white,
    fontSize: 24,
    margin: 0,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 30
  },
  column: {
    flexDirection: 'column',
    marginRight: 50
  },
  label: {
    margin: 0,
    marginLeft: 15,
    marginBottom: 15,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 10,
      marginLeft: 5
    }
  },
  value: {
    margin: 0,
    marginLeft: 15,
    marginBottom: 10,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 10,
      marginLeft: 5
    }
  },
  domains: {
    display: 'flex',
    flexDirection: 'column'
  },
  list: {
    marginTop: 0
  },
  listItem: {
    color: COLORS.white,
    fontFamily: 'Roboto',
    paddingLeft: 0
  }
})

class ContactInfoForm extends React.PureComponent {
  renderDomains = () => {
    const { domains, classes, t } = this.props
    if (domains && domains.length) {
      return (
        <div className={`${classes.row} ${classes.domains}`}>
          <p className={classes.label}>{t('domains')}</p>
          <ul className={classes.list}>
            {domains.map(domain => (
              <li key={domain.id} className={classes.value}>{domain.name}</li>
            ))}
          </ul>
        </div>
      )
    }
    return null
  }

  render () {
    const { classes, t, role, title, createdAt } = this.props
    return (
      <div className={classes.container}>
        <p className={classes.header}>{t('user_info')}</p>
        <div className={classes.row}>
          <div className={classes.column}>
            <p className={classes.label}>{t('role')}</p>
            <p className={classes.value}>{role}</p>
          </div>
          <div className={classes.column}>
            <p className={classes.label}>{t('title')}</p>
            <p className={classes.value}>{title}</p>
          </div>
          <div className={classes.column}>
            <p className={classes.label}>{t('profile_created')}</p>
            <p className={classes.value}>
              {moment(createdAt).format(`DD.MM.YYYY [${t('at')}] HH:mm`)}
            </p>
          </div>
        </div>
        {this.renderDomains()}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(ContactInfoForm))
