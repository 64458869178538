import React from 'react'
import { withStyles } from '@material-ui/core'
import Map from 'pigeon-maps'
import Marker from 'pigeon-marker'
import withRoot from '../../utils/withRoot'

const styles = theme => ({
  container: {
    maxWidth: 1000,
    padding: '40px 60px 0px 60px',
    '&>div': {
      borderRadius: 10
    }
  }
})

class AssetTransactionMap extends React.Component {
  render () {
    const { classes, latitude, longitude } = this.props
    return (
      <div className={classes.container}>
        <Map center={[latitude, longitude]} zoom={15} height={400}>
          <Marker anchor={[latitude, longitude]} />
        </Map>
      </div>
    )
  }
}

export default withRoot(withStyles(styles)(AssetTransactionMap))
