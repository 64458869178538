import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { COLORS } from '../utils/colors'

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '40px 60px',
    backgroundColor: COLORS.dark
  },
  title: {
    color: COLORS.white,
    fontSize: 48,
    margin: 0,
    fontFamily: 'Chivo',
    fontWeight: '700'
  },
  button: {
    color: COLORS.white,
    background: COLORS.violet, // `radial-gradient(${COLORS.violetGradientStart} 0%, ${COLORS.violetGradientEnd} 100%)`,
    '&:hover': {
      background: darken(COLORS.violet, 0.2)
    },
    width: 200,
    height: 50,
    borderRadius: 5,
    alignItems: 'center',
    boxShadow: `0px 0px 40px ${COLORS.dark}`
  },
  buttonText: {
    margin: 0,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: '700',
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  disabledButton: {
    background: COLORS.disabledButton
  },
  loading: {
    color: COLORS.white
  }
})

class PageHeader extends React.PureComponent {
  getButtonText = () => {
    const { t, buttonText, editing } = this.props
    if (editing) return t('save')
    return buttonText || t('edit')
  }

  handleClick = () => {
    const { editing, listScreen, onClick, setEditing } = this.props
    if (listScreen || editing) return onClick()
    return setEditing()
  }

  renderSaveButtonContent = () => {
    const { classes, loading } = this.props
    if (loading) return <CircularProgress className={classes.loading} size={22} />
    return <p className={classes.buttonText}>{this.getButtonText()}</p>
  }

  renderSaveButton = () => {
    const { classes, disabled, hideButton, editing } = this.props
    if (hideButton) return null
    return (
      <Button
        className={classes.button}
        classes={{ disabled: classes.disabledButton }}
        onClick={this.handleClick}
        disabled={(editing && disabled) || (editing === undefined && disabled)}
      >
        {this.renderSaveButtonContent()}
      </Button>
    )
  }

  render () {
    const { t, classes, title, editing } = this.props
    return (
      <div className={classes.container}>
        <p className={classes.title}>{editing && !title ? t('edit') : title}</p>
        {this.renderSaveButton()}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(PageHeader))
