import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  root: {
    height: 50
  },
  tableHead: {
    textAlign: 'center',
    backgroundColor: COLORS.dark,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: '700',
    minWidth: 60,
    padding: 0,
    border: `0.5px solid ${COLORS.border}`,
    borderTopWidth: 0
  }
})

class PropertyTableHeader extends React.PureComponent {
  render () {
    const { classes, t } = this.props
    return (
      <TableHead>
        <TableRow classes={{ root: classes.root }}>
          <TableCell className={classes.tableHead}>{t('property')}</TableCell>
          <TableCell className={classes.tableHead}>{t('address')}</TableCell>
          <TableCell className={classes.tableHead}>{t('edit')}</TableCell>
        </TableRow>
      </TableHead>
    )
  }
}

export default withStyles(styles)(withTranslation()(PropertyTableHeader))
