import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { COLORS } from '../../utils/colors'
import { buildAddress } from '../../utils/transforms'

const styles = theme => ({
  container: {
    maxWidth: 1000,
    padding: '0px 60px 0px 60px'
  },
  header: {
    color: COLORS.white,
    fontSize: 24,
    margin: 0,
    marginBottom: 30,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    flexDirection: 'column',
    marginRight: 50
  },
  label: {
    margin: 0,
    marginLeft: 15,
    marginBottom: 15,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 10,
      marginLeft: 5
    }
  },
  value: {
    margin: 0,
    marginLeft: 15,
    marginBottom: 10,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 10,
      marginLeft: 5
    }
  }
})

class PropertyCustomerInfo extends React.PureComponent {
  renderContent = () => {
    const { classes, t, customer } = this.props
    if (!customer) return null
    return (
      <div className={classes.container}>
        <p className={classes.header}>{t('customer_info')}</p>
        <div className={classes.row}>
          <div className={classes.column}>
            <p className={classes.label}>{t('name')}</p>
            <p className={classes.value}>{customer.name}</p>
          </div>
          <div className={classes.column}>
            <p className={classes.label}>{t('address')}</p>
            <p className={classes.value}>{buildAddress(customer)}</p>
          </div>
          <div className={classes.column}>
            <p className={classes.label}>{t('contact_person')}</p>
            <p className={classes.value}>{customer.contactPerson || '-'}</p>
          </div>
        </div>
      </div>
    )
  }

  render () {
    return this.renderContent()
  }
}

export default withStyles(styles)(withTranslation()(PropertyCustomerInfo))
