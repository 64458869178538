import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '../../static/muokkaa.svg'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  root: {
    backgroundColor: COLORS.dark,
    height: 50,
    cursor: 'pointer'
  },
  hover: {
    '&$hover:hover': {
      backgroundColor: COLORS.darkBackground
    }
  },
  cell: {
    fontSize: 16,
    fontFamily: 'Roboto',
    color: COLORS.white,
    textAlign: 'center',
    border: `0.5px solid ${COLORS.border}`
  },
  editBtn: {
    padding: 0,
    width: 40,
    height: 40,
    borderRadius: 20
  }
})

class OrganizationTableRow extends React.PureComponent {
  getStatus = () => {
    const { t, organization } = this.props
    if (!organization.active) return t('deactive')
    return t('active')
  }

  editOrganization = (e) => {
    e.stopPropagation()
    this.props.toSingle(this.props.organization, true)
  }

  viewOrganization = () => this.props.toSingle(this.props.organization, false)

  render () {
    const { classes, t, organization } = this.props
    return (
      <TableRow classes={{ root: classes.root, hover: classes.hover }} onClick={this.viewOrganization} hover>
        <TableCell className={classes.cell}>{organization.name}</TableCell>
        <TableCell className={classes.cell}>{this.getStatus()}</TableCell>
        <TableCell className={classes.cell}>
          <IconButton onClick={this.editOrganization} className={classes.editBtn} aria-label={t('edit')}>
            <img src={EditIcon} alt={t('edit')} />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }
}

export default withStyles(styles)(withTranslation()(OrganizationTableRow))
