import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import withRoot from '../utils/withRoot'
import AssetActions from '../redux/AssetRedux'
import CustomerActions from '../redux/CustomerRedux'
import PropertyActions from '../redux/PropertyRedux'
import UserActions from '../redux/UserRedux'
import AssetStorageActions from '../redux/AssetStorageRedux'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import AssetCustomerSelector from '../components/Asset/AssetCustomerSelector'
import AssetForm from '../components/Asset/AssetForm'
import AssetHolderSelector from '../components/Asset/AssetHolderSelector'
import { ASSET_HOLDERS, BIG_LIMIT } from '../utils/constants'

const styles = theme => ({})

class CreateAssetScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = this.getInitialFields()
  }

  componentDidMount () {
    const { getCustomers, customers, currentOrganizationId, location, getAssetStorages, getUsers } = this.props
    window.scrollTo(0, 0)
    if (!customers && currentOrganizationId) getCustomers(currentOrganizationId)
    // If navigated from asset storage screen -> set it as the initial holder
    if (location.state && location.state.assetStorage && currentOrganizationId) {
      getAssetStorages(currentOrganizationId, { limit: BIG_LIMIT, offset: 0 })
      this.setState({ initialHolder: ASSET_HOLDERS.ASSET_STORAGE, selectedAssetStorage: location.state.assetStorage })
    }
    // If navigated from user screen -> set them as the initial holder
    if (location.state && location.state.user && currentOrganizationId) {
      getUsers(currentOrganizationId, { limit: BIG_LIMIT, offset: 0 })
      this.setState({ initialHolder: ASSET_HOLDERS.USER, selectedUser: location.state.user })
    }
  }

  componentWillReceiveProps (nextProps) {
    const { getCustomers, customers, currentOrganizationId, location, getAssetStorages, getUsers } = this.props
    if (currentOrganizationId !== nextProps.currentOrganizationId) {
      if (!customers) getCustomers(nextProps.currentOrganizationId)
      // If navigated from asset storage screen -> set it as the initial holder
      if (location.state && location.state.assetStorage) {
        getAssetStorages(nextProps.currentOrganizationId, { limit: BIG_LIMIT, offset: 0 })
        this.setState({ initialHolder: ASSET_HOLDERS.ASSET_STORAGE, selectedAssetStorage: location.state.assetStorage })
      }
      // If navigated from user screen -> set them as the initial holder
      if (location.state && location.state.user) {
        getUsers(nextProps.currentOrganizationId, { limit: BIG_LIMIT, offset: 0 })
        this.setState({ initialHolder: ASSET_HOLDERS.USER, selectedUser: location.state.user })
      }
    }
    // Navigate to asset list on success
    if (nextProps.success) {
      if (location.state && location.state.assetStorage) {
        this.props.history.push(`/avainkaappi/${location.state.assetStorage}`)
      } else {
        this.props.history.push('/avaimet')
      }
    }
  }

  getInitialFields = () => ({
    name: '',
    identifier: '',
    description: '',
    type: 'key',
    selectedCustomer: '',
    selectedProperty: '',
    initialHolder: '',
    selectedUser: '',
    selectedAssetStorage: ''
  })

  resetFields = () => this.setState(this.getInitialFields())

  setName = evt => this.setState({ name: evt.target.value })

  setIdentifier = evt => this.setState({ identifier: evt.target.value })

  setDescription = evt => this.setState({ description: evt.target.value })

  isCreateAssetDisabled = () => {
    const { name, selectedProperty, selectedUser, selectedAssetStorage } = this.state
    if (!name.trim() || !selectedProperty || !(selectedUser || selectedAssetStorage)) return true
  }

  createAsset = () => {
    const { name, identifier, description, type, selectedProperty, selectedUser, selectedAssetStorage } = this.state
    const { createAsset } = this.props
    const fields = {}
    if (name) fields.name = name
    if (identifier) fields.identifier = identifier
    if (description) fields.description = description
    if (type) fields.type = type
    if (selectedProperty) fields.propertyId = selectedProperty
    if (selectedUser) fields.userId = Number(selectedUser)
    if (selectedAssetStorage) fields.assetStorageId = selectedAssetStorage
    createAsset(fields)
  }

  onSelectCustomerChange = evt => {
    if (evt.target.value !== '') {
      this.props.getProperties(evt.target.value)
    }
    this.setState({ selectedCustomer: evt.target.value, selectedProperty: '' })
  }

  onSelectPropertyChange = evt => this.setState({ selectedProperty: evt.target.value })

  onSelectUserChange = evt => this.setState({ selectedUser: evt.target.value, selectedAssetStorage: '' })

  onSelectAssetStorageChange = evt => this.setState({ selectedAssetStorage: evt.target.value, selectedUser: '' })

  onInitialHolderChange = initialHolder => {
    const { getAssetStorages, getUsers, currentOrganizationId } = this.props
    if (initialHolder === ASSET_HOLDERS.ASSET_STORAGE) {
      getAssetStorages(currentOrganizationId, { limit: BIG_LIMIT, offset: 0 })
    }
    if (initialHolder === ASSET_HOLDERS.USER) {
      getUsers(currentOrganizationId, { limit: BIG_LIMIT, offset: 0, sort: 'last_name:asc' })
    }
    // Clear selected user and asset storage to avoid both having values at the same time
    this.setState({ initialHolder, selectedUser: '', selectedAssetStorage: '' })
  }

  renderPage = () => {
    const {
      t,
      fetching,
      properties,
      customers,
      assetStorages,
      users,
      assetStoragesFetching,
      usersFetching
    } = this.props
    const {
      name,
      description,
      selectedCustomer,
      selectedProperty,
      selectedAssetStorage,
      selectedUser,
      initialHolder
    } = this.state
    const visibleCustomers = customers ? customers.results : null
    const visibleUsers = users ? users.results : null

    return (
      <Layout>
        <PageHeader
          title={t('create_asset')}
          onClick={this.createAsset}
          disabled={this.isCreateAssetDisabled()}
          loading={fetching}
          editing
        />
        <AssetForm
          name={name}
          description={description}
          setName={this.setName}
          setDescription={this.setDescription}
          editing
        />
        <AssetCustomerSelector
          header={t('property')}
          customers={visibleCustomers}
          properties={properties}
          selectedCustomer={selectedCustomer}
          selectedProperty={selectedProperty}
          onSelectCustomerChange={this.onSelectCustomerChange}
          onSelectPropertyChange={this.onSelectPropertyChange}
          editing
        />
        <AssetHolderSelector
          header={t('initial_holder')}
          isChecked={initialHolder}
          toggleHolder={this.onInitialHolderChange}
          onSelectUserChange={this.onSelectUserChange}
          onSelectAssetStorageChange={this.onSelectAssetStorageChange}
          selected={selectedAssetStorage || selectedUser}
          assetStorages={assetStorages}
          users={visibleUsers}
          loading={assetStoragesFetching || usersFetching}
          editing
        />
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = state => {
  return {
    currentOrganizationId: state.user.currentOrganizationId,
    fetching: state.asset.fetching,
    user: state.user.user,
    token: state.user.token,
    customers: state.customer.customers,
    properties: state.property.properties,
    assetStorages: state.assetStorage.assetStorages,
    users: state.user.users,
    assetStoragesFetching: state.assetStorage.fetching,
    usersFetcing: state.user.fetching,
    success: state.notification.success
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createAsset: fields => dispatch(AssetActions.createAsset(fields)),
    getCustomers: (organizationId, params) => dispatch(CustomerActions.getCustomers(organizationId, params)),
    getProperties: (customerId, params) => dispatch(PropertyActions.getProperties(customerId, params)),
    getUsers: (organizationId, params) => dispatch(UserActions.getUsers(organizationId, params)),
    getAssetStorages: (organizationId, params) =>
      dispatch(AssetStorageActions.getAssetStorages(organizationId, params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoot(withStyles(styles)(withTranslation()(CreateAssetScreen))))
