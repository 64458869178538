import React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import withRoot from '../utils/withRoot'
import UserActions from '../redux/UserRedux'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import ContactInfoForm from '../components/Settings/ContactInfoForm'
import MyInfo from '../components/Settings/MyInfo'
import ChangePasswordModal from '../components/Settings/ChangePasswordModal'
import { isDomainAdmin } from '../utils/transforms'

const styles = theme => ({
})

class SettingsScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      firstName: get(props, 'user.firstName', '') || '',
      lastName: get(props, 'user.lastName', '') || '',
      email: get(props, 'user.email', '') || '',
      phone: get(props, 'user.phone', '') || '',
      role: get(props, 'user.role', '') || '',
      title: get(props, 'user.title', '') || '',
      createdAt: get(props, 'user.createdAt', '') || '',
      domains: get(props, 'user.domains', []) || [],
      currentPassword: '',
      newPassword: '',
      passwordModalVisible: false,
      editing: false
    }
  }

  componentWillReceiveProps (nextProps) {
    if (!this.props.user && nextProps.user) {
      this.setState({
        firstName: nextProps.user.firstName || '',
        lastName: nextProps.user.lastName || '',
        email: nextProps.user.email || '',
        phone: nextProps.user.phone || '',
        role: nextProps.user.role || '',
        title: nextProps.user.title || '',
        createdAt: nextProps.user.createdAt || '',
        domains: nextProps.user.domains || []
      })
    }
  }

  setFirstName = (evt) => this.setState({ firstName: evt.target.value })

  setLastName = (evt) => this.setState({ lastName: evt.target.value })

  setEmail = (evt) => this.setState({ email: evt.target.value })

  setPhone = (evt) => this.setState({ phone: evt.target.value })

  setCurrentPassword = (evt) => this.setState({ currentPassword: evt.target.value })

  setNewPassword = (evt) => this.setState({ newPassword: evt.target.value })

  handleOpenModal = () => this.setState({ passwordModalVisible: true })

  handleCloseModal = () => this.setState({ passwordModalVisible: false, currentPassword: '', newPassword: '' })

  setEditing = () => this.setState({ editing: true })

  getRole = () => {
    const { t, user } = this.props
    if (user) {
      if (isDomainAdmin(user)) return t('domain_admin')
      return t(user.role)
    }
  }

  isUpdateUserDisabled = () => {
    const { firstName, lastName, email, phone } = this.state
    const { user } = this.props
    if (user) {
      const fields = []
      if (!firstName || !lastName || !email) return true
      if (firstName !== user.firstName) fields.push(firstName)
      if (lastName !== user.lastName) fields.push(lastName)
      if (email !== user.email) fields.push(email)
      if (phone !== user.phone) fields.push(phone)
      return fields.length === 0
    }
  }

  updateUser = () => {
    const { firstName, lastName, email, phone } = this.state
    const { user } = this.props
    const fields = {}
    if (firstName !== user.firstName) fields.firstName = firstName
    if (lastName !== user.lastName) fields.lastName = lastName
    if (email !== user.email) fields.email = email
    if (phone !== user.phone) fields.phone = phone
    this.props.updateUser(user.id, fields)
  }

  changePassword = () => {
    const { currentPassword, newPassword } = this.state
    this.props.changePassword(currentPassword, newPassword)
  }

  renderChangePasswordModal = () => {
    const { fetching, error } = this.props
    const { passwordModalVisible, currentPassword, newPassword } = this.state
    return (
      <ChangePasswordModal
        visible={passwordModalVisible}
        handleClose={this.handleCloseModal}
        currentPassword={currentPassword}
        newPassword={newPassword}
        setCurrentPassword={this.setCurrentPassword}
        setNewPassword={this.setNewPassword}
        onSubmit={this.changePassword}
        loading={fetching}
        error={error}
      />
    )
  }

  renderPage = () => {
    const { t, fetching } = this.props
    const { firstName, lastName, email, phone, title, createdAt, domains, passwordModalVisible, editing } = this.state
    return (
      <Layout>
        <PageHeader
          title={t('settings')}
          onClick={this.updateUser}
          disabled={this.isUpdateUserDisabled()}
          loading={fetching && !passwordModalVisible}
          editing={editing}
          setEditing={this.setEditing}
        />
        <ContactInfoForm
          firstName={firstName}
          lastName={lastName}
          email={email}
          phone={phone}
          setFirstName={this.setFirstName}
          setLastName={this.setLastName}
          setEmail={this.setEmail}
          setPhone={this.setPhone}
          handleOpenModal={this.handleOpenModal}
          editing={editing}
        />
        <MyInfo role={this.getRole()} title={title} createdAt={createdAt} domains={domains} />
        {this.renderChangePasswordModal()}
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    fetching: state.user.fetching,
    error: state.notification.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (id, fields) => dispatch(UserActions.updateUser(id, fields)),
    changePassword: (currentPassword, newPassword) =>
      dispatch(UserActions.changePassword(currentPassword, newPassword))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRoot(withStyles(styles)(withTranslation()(SettingsScreen)))
)
