import store from 'store2'

// Storage keys
export const LAYOUT_SIDEBAR_OPEN = 'LAYOUT_SIDEBAR_OPEN'
export const AUTH_TOKEN = 'AUTH_TOKEN'
export const ORGANIZATION_ID = 'ORGANIZATION_ID'

export function readStorage (key) {
  if (window.localStorage) {
    return store.get(key)
  }
  return null
}

export function writeStorage (key, value) {
  if (window.localStorage) {
    store.set(key, value)
  }
}
