import React from 'react'
import { withTranslation } from 'react-i18next'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '../../static/muokkaa.svg'
import { withStyles } from '@material-ui/core/styles'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  container: {
    maxWidth: 1000,
    padding: '20px 60px 0'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  header: {
    color: COLORS.white,
    fontSize: 24,
    margin: 0,
    marginTop: 20,
    marginBottom: 20,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  text: {
    color: COLORS.white,
    fontSize: 16,
    fontFamily: 'Roboto'
  },
  editBtn: {
    marginLeft: '20px',
    padding: 0,
    width: 40,
    height: 40,
    borderRadius: 20
  },
  domain: {
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 16,
    padding: 16,
    lineHeight: 'normal',
    width: 200
  },
  domainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    listStyle: 'none',
    marginTop: 20,
    marginBottom: 30,
    paddingInlineStart: 0,
    maxHeight: 300,
    maxWidth: 1000
  },
  domainContainer: {
    marginRight: 30,
    backgroundColor: COLORS.dark,
    borderRadius: 3,
    paddingLeft: 16,
    marginBottom: 10,
    // boxShadow: `0 2px 5px ${COLORS.dark}`,
    border: `1px solid ${COLORS.border}`,
    width: 'min-content'
  }
})

class CustomerDomainForm extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      editMode: false
    }
  }

  toggleEdit = () => this.setState({ editMode: !this.state.editMode })

  customerHasDomain = domainId => {
    const { customer } = this.props
    if (customer && customer.domains) {
      return customer.domains.some(domain => domain.id === domainId)
    }
    return false
  }

  toggleDomain = (domainId, nextVal) => {
    const { customer } = this.props
    if (nextVal) {
      this.props.addCustomerDomain(customer.id, domainId)
    } else {
      this.props.removeCustomerDomain(customer.id, domainId)
    }
  }

  sortCompare = (a, b) => {
    if (!a || !b || !a.name || !b.name) return 0
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    return 0
  }

  renderEditableDomains = () => {
    const { allDomains, classes } = this.props
    if (allDomains) {
      return (
        <div className={classes.domainWrapper}>
          {[...allDomains].sort(this.sortCompare).map(domain => {
            const isChecked = this.customerHasDomain(domain.id)
            const onChange = () => this.toggleDomain(domain.id, !isChecked)
            return (
              <div key={domain.id} className={classes.domainContainer}>
                <FormControlLabel
                  control={<Checkbox checked={isChecked} onChange={onChange} />}
                  label={domain.name}
                  classes={{ label: classes.domain }}
                />
              </div>
            )
          })}
        </div>
      )
    }
    return null
  }

  renderDomainList = () => {
    const { classes, t, customer } = this.props
    if (!customer || !customer.domains || !customer.domains.length) {
      return <p className={classes.text}>{t('domains_empty')}</p>
    }
    return (
      <ul className={classes.domainWrapper}>
        {[...customer.domains].sort(this.sortCompare).map(domain => (
          <li key={domain.id} className={classes.domainContainer}>
            <div className={classes.domain}>{domain.name}</div>
          </li>
        ))}
      </ul>
    )
  }

  renderDomains () {
    const { editMode } = this.state
    if (editMode) return this.renderEditableDomains()
    return this.renderDomainList()
  }

  renderEditButton = () => {
    const { classes, t, editEnabled, editing } = this.props
    if (!editEnabled || !editing) return null
    return (
      <IconButton onClick={this.toggleEdit} className={classes.editBtn} aria-label={t('edit')}>
        <img src={EditIcon} alt={t('edit')} />
      </IconButton>
    )
  }

  render () {
    const { classes, t } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <p className={classes.header}>{t('domains')}</p>
          {this.renderEditButton()}
        </div>
        {this.renderDomains()}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(CustomerDomainForm))
