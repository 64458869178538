import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import withRoot from '../utils/withRoot'
import AssetActions from '../redux/AssetRedux'
import CustomerActions from '../redux/CustomerRedux'
import PropertyActions from '../redux/PropertyRedux'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import AssetTable from '../components/Asset/AssetTable'
import AssetCustomerSelector from '../components/Asset/AssetCustomerSelector'
import AssetTableMessage from '../components/Asset/AssetTableMessage'

const styles = theme => ({})

class AssetsScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      pageSize: 10,
      page: 0,
      selectedCustomer: '',
      selectedProperty: '',
      search: ''
    }
    this.timeout = 0
  }

  componentDidMount () {
    const { getCustomers, currentOrganizationId } = this.props
    if (currentOrganizationId) getCustomers(currentOrganizationId)
    this.props.clearAssets()
  }

  componentDidUpdate (prevProps) {
    const { getCustomers, customers, currentOrganizationId } = this.props
    if (currentOrganizationId !== prevProps.currentOrganizationId && !customers) {
      getCustomers(currentOrganizationId)
    }
  }

  componentWillUnmount () {
    this.props.clearAssets()
  }

  handleSearchChange = evt => {
    const { page, pageSize, selectedCustomer, selectedProperty } = this.state
    const searchValue = evt.target.value
    this.setState({ search: searchValue })

    if (this.timeout) clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      if (selectedProperty) {
        this.props.getPropertyAssets(selectedProperty, {
          limit: pageSize,
          offset: page * pageSize,
          search: searchValue
        })
      }
      if (selectedCustomer) {
        this.props.getCustomerAssets(selectedCustomer, {
          limit: pageSize,
          offset: page * pageSize,
          search: searchValue
        })
      }
    }, 300)
  }

  toCreateAsset = () => this.props.history.push('/uusi-avain')

  toAsset = (asset, edit) => {
    this.props.setAsset(asset)
    this.props.history.push({
      pathname: `/avain/${asset.id}`,
      state: {
        edit
      }
    })
  }

  setPage = (event, nextPage) => {
    const { getAssets, currentOrganizationId } = this.props
    const { pageSize } = this.state
    const offset = nextPage * pageSize
    const limit = pageSize
    getAssets(currentOrganizationId, { offset, limit })
    this.setState({ page: nextPage })
  }

  onSelectCustomerChange = evt => {
    if (evt.target.value !== '') {
      this.props.getProperties(evt.target.value)
      this.props.getCustomerAssets(evt.target.value, {})
    }
    this.setState({ selectedCustomer: evt.target.value, selectedProperty: '' })
  }

  onSelectPropertyChange = evt => {
    if (evt.target.value !== '') {
      this.props.getPropertyAssets(evt.target.value)
    }
    this.setState({ selectedProperty: evt.target.value })
  }

  renderTableOrError = () => {
    const { t, customers, properties, assets, total, fetching } = this.props
    const { page, pageSize, search } = this.state
    const visibleCustomers = customers ? customers.results : null
    if (visibleCustomers && properties && !properties.length) {
      return (
        <AssetTableMessage
          message={t('customer_properties_empty')}
          messageSub={t('customer_properties_empty_sub')}
        />
      )
    }
    if (visibleCustomers && properties && assets && !assets.length) {
      return (
        <AssetTableMessage
          message={t('property_assets_empty')}
          messageSub={t('property_assets_empty_sub')}
        />
      )
    }
    if (assets && assets.length) {
      return (
        <AssetTable
          assets={assets}
          toSingle={this.toAsset}
          loading={fetching}
          onChangePage={this.setPage}
          total={total}
          page={page}
          pageSize={pageSize}
          search={search}
          handleSearchChange={this.handleSearchChange}
        />
      )
    }
    if (visibleCustomers && !properties) return null
    return <AssetTableMessage message={t('no_assets')} messageSub={t('no_assets_sub')} />
  }

  renderPage = () => {
    const { t, customers, properties } = this.props
    const { selectedCustomer, selectedProperty } = this.state
    const visibleCustomers = customers ? customers.results : null
    return (
      <Layout>
        <PageHeader
          title={t('assets')}
          onClick={this.toCreateAsset}
          buttonText={t('create_asset')}
          disabled={visibleCustomers ? !visibleCustomers.length : true}
          listScreen
        />
        <AssetCustomerSelector
          customers={visibleCustomers}
          properties={properties}
          selectedCustomer={selectedCustomer}
          selectedProperty={selectedProperty}
          onSelectCustomerChange={this.onSelectCustomerChange}
          onSelectPropertyChange={this.onSelectPropertyChange}
        />
        {this.renderTableOrError()}
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = state => {
  return {
    assets: state.asset.assets,
    customers: state.customer.customers,
    properties: state.property.properties,
    fetching: state.asset.fetching,
    total: state.asset.total,
    currentOrganizationId: state.user.currentOrganizationId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCustomerAssets: (customerId, params) =>
      dispatch(AssetActions.getCustomerAssets(customerId, params)),
    getPropertyAssets: (propertyId, params) =>
      dispatch(AssetActions.getPropertyAssets(propertyId, params)),
    setAsset: asset => dispatch(AssetActions.setAsset(asset)),
    clearAssets: () => dispatch(AssetActions.clearAssets()),
    getCustomers: (organizationId, params) =>
      dispatch(CustomerActions.getCustomers(organizationId, params)),
    getProperties: (customerId, params) =>
      dispatch(PropertyActions.getProperties(customerId, params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoot(withStyles(styles)(withTranslation()(AssetsScreen))))
