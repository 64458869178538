import { ROLES, FIELDS } from './constants'
import { isValidEmail, isEqualArray } from './transforms'
import { omit, isNil } from 'lodash'

const ADMIN_LEVEL_USERS = [ROLES.SUPERADMIN, ROLES.ADMIN]

export const isUser = (user) => user && user.role === ROLES.USER
export const isSuperadmin = (user) => user && user.role === ROLES.SUPERADMIN

export const isAdminLevelUser = (user) => {
  if (user && ADMIN_LEVEL_USERS.includes(user.role)) return true
  return false
}

export const canDeactivateOrganization = (user, organization) => {
  if (user && organization) {
    return isSuperadmin(user) && organization.active
  }
  return false
}

export const canAccessCustomer = (user, customer) => {
  if (!user || !customer) return false

  if (isAdminLevelUser(user)) return true

  let hasPermission = false
  user.domains.forEach((userDomain) => {
    customer.domains.forEach((customerDomain) => {
      if (userDomain.role === ROLES.ADMIN && userDomain.id === customerDomain.id) {
        hasPermission = true
      }
    })
  })
  return hasPermission
}

export const canAccessUser = (user, targetUser) => {
  if (!user || !targetUser) return false

  if (isAdminLevelUser(user)) return true

  let hasPermission = false
  user.domains.forEach((userDomain) => {
    targetUser.domains.forEach((targetUserDomain) => {
      if (userDomain.role === ROLES.ADMIN && userDomain.id === targetUserDomain.id) {
        hasPermission = true
      }
    })
  })
  return hasPermission
}

export const getAdminDomainIds = userDomains => {
  if (!userDomains) return null

  const domainIds = []
  userDomains.forEach(userDomain => {
    if (userDomain.role === ROLES.ADMIN) {
      domainIds.push(userDomain.id)
    }
  })
  return domainIds
}

export const isEditDisabled = (object, state, required, excluded) => {
  if (object) {
    const fields = []
    const requiredFields = []
    const objectKeys = Object.keys(omit(object, excluded))

    // Check for email validity if present
    if (objectKeys.includes(FIELDS.EMAIL)) {
      if (!isValidEmail(state[FIELDS.EMAIL])) return true
    }

    // Check whether any fields have changed
    objectKeys.forEach(key => {
      if (!Array.isArray(object[key]) &&
      !isNil(state[key]) &&
      object[key] !== state[key] &&
      !(state[key] === '' && isNil(object[key]))) {
        fields.push(key)
      }
      if (Array.isArray(object[key]) && !isEqualArray(object[key], state[key])) fields.push(key)
    })

    // Check if any required fields are empty
    required.forEach(key => {
      if (!state[key]) requiredFields.push(key)
    })

    if (requiredFields.length) return true
    else return fields.length === 0
  }
  return true
}
