import React from 'react'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import SelectInput from '../SelectInput'
import { COLORS } from '../../utils/colors'
import { addValueAndName } from '../../utils/transforms'

const styles = theme => ({
  container: {
    maxWidth: 1000,
    padding: '60px 60px 0px 60px'
  },
  header: {
    color: COLORS.white,
    fontSize: 24,
    margin: 0,
    marginBottom: 30,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  row: {
    display: 'flex',
    marginBottom: 30
  },
  spacer: {
    minWidth: 60
  },
  asterisk: {
    color: COLORS.red
  }
})

class AssetCustomerSelector extends React.PureComponent {
  isPropertiesDisabled = () => {
    const { selectedCustomer, properties } = this.props
    return !selectedCustomer || !(properties && properties.length)
  }

  renderCustomerSelect = () => {
    const { t, customers, selectedCustomer, onSelectCustomerChange } = this.props
    if (customers && customers.length) {
      const transformedCustomers = addValueAndName(customers)
      return (
        <SelectInput
          label={t('select_customer')}
          placeholder={t('customer')}
          value={selectedCustomer}
          onChange={onSelectCustomerChange}
          options={transformedCustomers}
        />
      )
    }
    return null
  }

  renderPropertySelect = () => {
    const { t, properties, customers, selectedProperty, selectedCustomer, onSelectPropertyChange } = this.props
    if (customers && customers.length) {
      const transformedProperties = properties && addValueAndName(properties)
      return (
        <SelectInput
          label={t('select_property')}
          placeholder={selectedCustomer && properties && !properties.length ? t('properties_empty') : t('properties')}
          value={selectedProperty}
          onChange={onSelectPropertyChange}
          options={transformedProperties || []}
          disabled={this.isPropertiesDisabled()}
        />
      )
    }
    return null
  }

  renderHeader = () => {
    const { classes, header } = this.props
    if (header) {
      return (
        <p className={classes.header}>
          {header} <span className={classes.asterisk}>*</span>
        </p>
      )
    }
    return null
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        {this.renderHeader()}
        <div className={classes.row}>
          {this.renderCustomerSelect()}
          <div className={classes.spacer} />
          {this.renderPropertySelect()}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(AssetCustomerSelector))
