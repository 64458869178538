import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from '../Input'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  paper: {
    maxWidth: 600,
    borderRadius: 5,
    margin: 10
  },
  modalContent: {
    backgroundColor: COLORS.darkBackground,
    border: `1px solid ${COLORS.border}`,
    width: 600,
    borderRadius: 5,
    padding: '60px !important',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '20px !important'
    }
  },
  title: {
    color: COLORS.white,
    margin: 0,
    marginBottom: 30,
    fontSize: 24,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    }
  },
  bottomContainer: {
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    }
  },
  button: {
    color: COLORS.white,
    background: COLORS.violet, // `radial-gradient(${COLORS.violetGradientStart} 0%, ${COLORS.violetGradientEnd} 100%)`,
    '&:hover': {
      background: darken(COLORS.violet, 0.2)
    },
    width: 200,
    height: 50,
    marginTop: 10,
    borderRadius: 5,
    alignItems: 'center',
    boxShadow: `0px 0px 40px ${COLORS.dark}`,
    [theme.breakpoints.down('sm')]: {
      height: 40,
      width: 120,
      borderRadius: 5
    }
  },
  outlinedButton: {
    color: COLORS.white,
    background: COLORS.transparent, // `radial-gradient(${COLORS.violetGradientStart} 0%, ${COLORS.violetGradientEnd} 100%)`,
    '&:hover': {
      background: COLORS.violet
    },
    border: `1px solid ${COLORS.violet}`,
    width: 200,
    height: 50,
    marginTop: 10,
    marginLeft: 30,
    borderRadius: 5,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 15,
      height: 40,
      width: 120,
      borderRadius: 5
    }
  },
  buttonText: {
    margin: 0,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 18,
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  disabledButton: {
    background: COLORS.disabledButton,
    boxShadow: '0 0 0 0'
  },
  loading: {
    color: COLORS.white
  },
  spacer: {
    height: 10
  }
})

class DomainModal extends React.PureComponent {
  isDisabled = () => !this.props.name.trim()

  renderButtonContent = () => {
    const { classes, t, loading } = this.props
    if (loading) return <CircularProgress className={classes.loading} size={22} />
    return <p className={classes.buttonText}>{t('save')}</p>
  }

  renderModal = () => {
    const { classes, visible, onClose, t, title, onSubmit, name, setName } = this.props
    return (
      <Dialog open={visible} onClose={onClose} classes={{ paper: classes.paper }}>
        <DialogContent className={classes.modalContent}>
          <h2 className={classes.title}>{title}</h2>
          <Input label={t('name')} value={name} onChange={setName} required autoFocus />
          <div className={classes.bottomContainer}>
            <Button
              className={classes.button}
              onClick={onSubmit}
              disabled={this.isDisabled()}
              classes={{ disabled: classes.disabledButton }}
            >
              {this.renderButtonContent()}
            </Button>
            <Button className={classes.outlinedButton} onClick={onClose}>
              <p className={classes.buttonText}>{t('cancel')}</p>
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  render () {
    return this.renderModal()
  }
}

export default withStyles(styles)(withTranslation()(DomainModal))
