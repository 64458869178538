import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../utils/withRoot'
import OrganizationActions from '../redux/OrganizationRedux'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import OrganizationContactInfoForm from '../components/Organization/OrganizationContactInfoForm'
import OrganizationLocationForm from '../components/Organization/OrganizationLocationForm'
import OrganizationInfoForm from '../components/Organization/OrganizationInfoForm'
import { isSuperadmin } from '../utils/permissions'
import { isValidEmail } from '../utils/transforms'

const styles = theme => ({})

class CreateOrganizationScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      description: '',
      businessId: '',
      phone: '',
      email: '',
      address: '',
      postalCode: '',
      city: '',
      contactPerson: '',
      url: ''
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.success) {
      this.props.history.push('/organisaatiot')
    }
  }

  setName = evt => this.setState({ name: evt.target.value })

  setDescription = evt => this.setState({ description: evt.target.value })

  setBusinessId = evt => this.setState({ businessId: evt.target.value })

  setEmail = evt => this.setState({ email: evt.target.value })

  setPhone = evt => this.setState({ phone: evt.target.value })

  setAddress = evt => this.setState({ address: evt.target.value })

  setPostalCode = evt => this.setState({ postalCode: evt.target.value })

  setCity = evt => this.setState({ city: evt.target.value })

  setContactPerson = evt => this.setState({ contactPerson: evt.target.value })

  setUrl = evt => this.setState({ url: evt.target.value })

  isCreateOrganizationDisabled = () => {
    const { name, address, postalCode, city, businessId, contactPerson, phone, email } = this.state
    if (!isValidEmail(email)) return true
    if (
      !name.trim() ||
      !address.trim() ||
      !postalCode.trim() ||
      !city.trim() ||
      !businessId.trim() ||
      !contactPerson.trim() ||
      !phone.trim()
    ) {
      return true
    }
  }

  createOrganization = () => this.props.createOrganization(this.state)

  renderPage = () => {
    const { t, fetching, user } = this.props
    const {
      name,
      description,
      businessId,
      email,
      phone,
      address,
      postalCode,
      city,
      contactPerson,
      url
    } = this.state
    if (!isSuperadmin(user)) return <Redirect to='/dashboard' />
    return (
      <Layout>
        <PageHeader
          title={t('create_organization')}
          onClick={this.createOrganization}
          disabled={this.isCreateOrganizationDisabled()}
          loading={fetching}
          editing
        />
        <OrganizationInfoForm
          name={name}
          businessId={businessId}
          description={description}
          setName={this.setName}
          setBusinessId={this.setBusinessId}
          setDescription={this.setDescription}
          editing
        />
        <OrganizationContactInfoForm
          email={email}
          phone={phone}
          contactPerson={contactPerson}
          url={url}
          setEmail={this.setEmail}
          setPhone={this.setPhone}
          setContactPerson={this.setContactPerson}
          setUrl={this.setUrl}
          editing
        />
        <OrganizationLocationForm
          address={address}
          postalCode={postalCode}
          city={city}
          setAddress={this.setAddress}
          setPostalCode={this.setPostalCode}
          setCity={this.setCity}
          editing
        />
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    fetching: state.organization.fetching,
    success: state.notification.success
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createOrganization: organization =>
      dispatch(OrganizationActions.createOrganization(organization))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoot(withStyles(styles)(withTranslation()(CreateOrganizationScreen))))
