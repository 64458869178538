import { call, put, select } from 'redux-saga/effects'
import AssetActions from '../redux/AssetRedux'
import NotificationActions from '../redux/NotificationRedux'
import { composeError } from '../utils/transforms'

export function * getCustomerAssets (api, action) {
  const { customerId, params } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.getCustomerAssets, token, customerId, params)
  console.log(response)
  if (response.ok) {
    const assets = response.data.results
    const total = response.data.total
    yield put(AssetActions.getAssetsSuccess(assets, total))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetActions.getAssetsFailure())
  }
}

export function * getPropertyAssets (api, action) {
  const { propertyId, params } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.getPropertyAssets, token, propertyId, params)
  console.log(response)
  if (response.ok) {
    const assets = response.data.results
    const total = response.data.total
    yield put(AssetActions.getAssetsSuccess(assets, total))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetActions.getAssetsFailure())
  }
}

export function * getUserAssets (api, action) {
  const { userId, params } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.getUserAssets, token, userId, params)
  console.log(response)
  if (response.ok) {
    const assets = response.data.results
    const total = response.data.total
    yield put(AssetActions.getAssetsSuccess(assets, total))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetActions.getAssetsFailure())
  }
}

export function * getAssetStorageAssets (api, action) {
  const { assetStorageId, params } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.getAssetStorageAssets, token, assetStorageId, params)
  console.log(response)
  if (response.ok) {
    const assets = response.data.results
    const total = response.data.total
    yield put(AssetActions.getAssetsSuccess(assets, total))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetActions.getAssetsFailure())
  }
}

export function * getMyAssets (api, action) {
  const { params } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.getMyAssets, token, params)
  console.log(response)
  if (response.ok) {
    const assets = response.data.results
    const total = response.data.total
    yield put(AssetActions.getAssetsSuccess(assets, total))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetActions.getAssetsFailure())
  }
}

export function * getAsset (api, action) {
  const { id } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.getAsset, token, id)
  console.log(response)
  if (response.ok) {
    const asset = response.data
    yield put(AssetActions.getAssetSuccess(asset))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetActions.getAssetFailure())
  }
}

export function * createAsset (api, action) {
  const { fields } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.createAsset, token, fields)
  console.log(response)
  if (response.ok) {
    yield put(AssetActions.createAssetFinished())
    yield put(NotificationActions.setSuccess('create_asset_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetActions.createAssetFinished())
  }
}

export function * updateAsset (api, action) {
  const { id, fields } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.updateAsset, token, id, fields)
  console.log(response)
  if (response.ok) {
    const asset = response.data
    yield put(AssetActions.updateAssetSuccess(asset))
    yield put(NotificationActions.setSuccess('update_asset_storage_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetActions.updateAssetFailure())
  }
}

export function * deactivateAsset (api, action) {
  const { id } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.deactivateAsset, token, id)
  console.log(response)
  if (response.ok) {
    const asset = response.data
    yield put(AssetActions.deactivateAssetSuccess(asset))
    yield put(NotificationActions.setSuccess('deactivate_asset_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetActions.deactivateAssetFailure())
  }
}

export function * activateAsset (api, action) {
  const { id } = action
  const { token } = yield select(state => state.user)
  const response = yield call(api.activateAsset, token, id)
  console.log(response)
  if (response.ok) {
    const asset = response.data
    yield put(AssetActions.activateAssetSuccess(asset))
    yield put(NotificationActions.setSuccess('activate_asset_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(AssetActions.activateAssetFailure())
  }
}
