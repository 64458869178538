import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getDomains: ['organizationId', 'params'],
  getDomainsSuccess: ['domains'],
  getDomainsFailure: null,
  refreshDomains: null,
  createDomain: ['organizationId', 'fields'],
  createDomainSuccess: null,
  createDomainFailure: null,
  updateDomain: ['domainId', 'fields'],
  updateDomainSuccess: null,
  updateDomainFailure: null,
  cancelOk: null
})

export const DomainTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  domains: null,

  // Store previous getDomains call params
  previousOrganizationId: null,
  previousParams: {}
})

/* ------------- Reducers ------------- */

const getDomains = (state, { organizationId, params }) => state.merge({
  previousOrganizationId: organizationId,
  previousParams: params,
  fetching: true
})
const getDomainsSuccess = (state, { domains }) => state.merge({ domains, fetching: false })
const getDomainsFailure = (state) => state.merge({ fetching: false })

const createDomain = (state) => state.merge({ fetching: true })
const createDomainSuccess = (state) => state.merge({ fetching: false })
const createDomainFailure = (state) => state.merge({ fetching: false })

const updateDomain = (state) => state.merge({ fetching: true })
const updateDomainSuccess = (state) => state.merge({ fetching: false })
const updateDomainFailure = (state) => state.merge({ fetching: false })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_DOMAINS]: getDomains,
  [Types.GET_DOMAINS_SUCCESS]: getDomainsSuccess,
  [Types.GET_DOMAINS_FAILURE]: getDomainsFailure,
  [Types.CREATE_DOMAIN]: createDomain,
  [Types.CREATE_DOMAIN_SUCCESS]: createDomainSuccess,
  [Types.CREATE_DOMAIN_FAILURE]: createDomainFailure,
  [Types.UPDATE_DOMAIN]: updateDomain,
  [Types.UPDATE_DOMAIN_SUCCESS]: updateDomainSuccess,
  [Types.UPDATE_DOMAIN_FAILURE]: updateDomainFailure
})
