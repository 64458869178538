import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { COLORS } from '../../utils/colors'
import AssetTransactionDetailsColumn from './AssetTransactionDetailsColumn'

const styles = theme => ({
  container: {
    maxWidth: 1000,
    padding: '40px 60px 0px 60px',
    clear: 'both'
  },
  header: {
    color: COLORS.white,
    fontSize: 24,
    margin: 0,
    marginBottom: 30,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  row: {
    display: 'flex',
    marginBottom: 20
  },
  spacer: {
    minWidth: 20
  }
})

class AssetTransactionDetails extends React.PureComponent {
  renderFrom = () => {
    const { t, transaction } = this.props
    const from = transaction.fromAssetStorage
      ? transaction.fromAssetStorage.name
      : `${transaction.fromUser.firstName} ${transaction.fromUser.lastName}`
    return <AssetTransactionDetailsColumn label={t('from')} value={from} />
  }

  renderTo = () => {
    const { t, transaction } = this.props
    const to = transaction.toAssetStorage
      ? transaction.toAssetStorage.name
      : `${transaction.toUser.firstName} ${transaction.toUser.lastName}`
    return <AssetTransactionDetailsColumn label={t('to')} value={to} />
  }

  renderContent = () => {
    const { t, classes, transaction, address } = this.props
    return (
      <div>
        <div className={classes.row}>
          <AssetTransactionDetailsColumn label={t('asset')} value={transaction.asset.name} />
        </div>
        <div className={classes.row}>
          <AssetTransactionDetailsColumn
            label={t('time')}
            value={moment(transaction.createdAt).format(`DD.MM.YYYY [${t('at')}] HH:mm`)}
          />
          <div className={classes.spacer} />
          <AssetTransactionDetailsColumn label={t('place')} value={address} />
        </div>
        <div className={classes.row}>
          {this.renderFrom()}
          <div className={classes.spacer} />
          {this.renderTo()}
        </div>
      </div>
    )
  }

  render () {
    const { t, classes } = this.props
    return (
      <div className={classes.container}>
        <p className={classes.header}>{t('transaction_info')}</p>
        {this.renderContent()}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(AssetTransactionDetails))
