import React from 'react'
import * as moment from 'moment'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { darken } from '@material-ui/core/styles/colorManipulator'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  container: {
    maxWidth: 1000,
    padding: '40px 60px 0px 60px'
  },
  header: {
    color: COLORS.white,
    fontSize: 24,
    margin: 0,
    marginBottom: 30,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 30
  },
  column: {
    flexDirection: 'column',
    marginRight: 50
  },
  label: {
    margin: 0,
    marginLeft: 15,
    marginBottom: 15,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 10,
      marginLeft: 5
    }
  },
  value: {
    margin: 0,
    marginLeft: 15,
    marginBottom: 10,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 10,
      marginLeft: 5
    }
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: 20,
    minWidth: 'calc(50% - 30px)'
  },
  button: {
    color: COLORS.white,
    background: COLORS.red, // `radial-gradient(${COLORS.violetGradientStart} 0%, ${COLORS.violetGradientEnd} 100%)`,
    '&:hover': {
      background: darken(COLORS.red, 0.2)
    },
    width: 250,
    height: 50,
    borderRadius: 5,
    alignItems: 'center'
  },
  buttonText: {
    margin: 0,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: '700',
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  loading: {
    color: COLORS.white
  }
})

class AssetInfo extends React.PureComponent {
  deactivate = () => {
    const { deactivate, asset } = this.props
    deactivate(asset.id)
  }

  activate = () => {
    const { activate, asset } = this.props
    activate(asset.id)
  }


  canDeactivate = () => this.props.asset.active

  renderDeactivateButtonContent = () => {
    const { classes, t, loading } = this.props
    if (loading) return <CircularProgress className={classes.loading} size={22} />
    return <p className={classes.buttonText}>{t('deactivate_asset')}</p>
  }

  renderActivateButtonContent = () => {
    const { classes, t, loading } = this.props
    if (loading) return <CircularProgress className={classes.loading} size={22} />
    return <p className={classes.buttonText}>{t('activate_asset')}</p>
  }

  renderDeactivateAsset = () => {
    const { classes } = this.props
    if (this.canDeactivate()) {
      return (
        <div className={classes.buttonContainer}>
          <Button className={classes.button} onClick={this.deactivate}>
            {this.renderDeactivateButtonContent()}
          </Button>
        </div>
      )
    } else {
      return (
        <div className={classes.buttonContainer}>
          <Button className={classes.button} onClick={this.activate}>
            {this.renderActivateButtonContent()}
          </Button>
        </div>
      )
    }
  }

  renderContent = () => {
    const { classes, t, asset } = this.props
    if (!asset) return null
    const { active, createdAt } = asset
    return (
      <div className={classes.container}>
        <p className={classes.header}>{t('other_info')}</p>
        <div className={classes.row}>
          <div className={classes.column}>
            <p className={classes.label}>{t('status')}</p>
            <p className={classes.value}>{active ? t('active') : t('deactive')}</p>
          </div>
          <div className={classes.column}>
            <p className={classes.label}>{t('created')}</p>
            <p className={classes.value}>{moment(createdAt).format(`DD.MM.YYYY [${t('at')}] HH:mm`)}</p>
          </div>
          <div className={classes.column}>
            <p className={classes.label}>{t('type')}</p>
            <p className={classes.value}>{t(asset.type)}</p>
          </div>
        </div>
        {this.renderDeactivateAsset()}
      </div>
    )
  }

  render () {
    return this.renderContent()
  }
}

export default withStyles(styles)(withTranslation()(AssetInfo))
