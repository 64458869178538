import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setOrganization: ['organization'],
  getOrganization: ['id'],
  getOrganizationSuccess: ['organization'],
  getOrganizationFailure: null,
  getOrganizations: ['params'],
  getOrganizationsSuccess: ['organizations', 'total'],
  getOrganizationsFailure: null,
  createOrganization: ['organization'],
  createOrganizationFinished: null,
  updateOrganization: ['id', 'fields'],
  updateOrganizationSuccess: ['organization'],
  updateOrganizationFailure: null,
  deactivateOrganization: ['id'],
  deactivateOrganizationFinished: null
})

export const OrganizationTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  deactivateFetching: false,
  organization: null,
  organizations: null,
  total: null
})

/* ------------- Reducers ------------- */

const setOrganization = (state, { organization }) => state.merge({ organization })

const getOrganization = (state, { id }) => state.merge({ fetching: true })
const getOrganizationSuccess = (state, { organization }) => state.merge({ organization, fetching: false })
const getOrganizationFailure = (state) => state.merge({ fetching: false })

const getOrganizations = (state, { params }) => state.merge({ fetching: true })
const getOrganizationsSuccess = (state, { organizations, total }) => state.merge({ organizations, total, fetching: false })
const getOrganizationsFailure = (state) => state.merge({ fetching: false })

const createOrganization = (state, { organization }) => state.merge({ fetching: true })
const createOrganizationFinished = (state) => state.merge({ fetching: false })

const updateOrganization = (state, { id, fields }) => state.merge({ fetching: true })
const updateOrganizationSuccess = (state, { organization }) => state.merge({ organization, fetching: false })
const updateOrganizationFailure = (state) => state.merge({ fetching: false })

const deactivateOrganization = (state, { id }) => state.merge({ deactivateFetching: true })
const deactivateOrganizationFinished = (state) => state.merge({ deactivateFetching: false })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ORGANIZATION]: setOrganization,
  [Types.GET_ORGANIZATION]: getOrganization,
  [Types.GET_ORGANIZATION_SUCCESS]: getOrganizationSuccess,
  [Types.GET_ORGANIZATION_FAILURE]: getOrganizationFailure,
  [Types.GET_ORGANIZATIONS]: getOrganizations,
  [Types.GET_ORGANIZATIONS_SUCCESS]: getOrganizationsSuccess,
  [Types.GET_ORGANIZATIONS_FAILURE]: getOrganizationsFailure,
  [Types.CREATE_ORGANIZATION]: createOrganization,
  [Types.CREATE_ORGANIZATION_FINISHED]: createOrganizationFinished,
  [Types.UPDATE_ORGANIZATION]: updateOrganization,
  [Types.UPDATE_ORGANIZATION_SUCCESS]: updateOrganizationSuccess,
  [Types.UPDATE_ORGANIZATION_FAILURE]: updateOrganizationFailure,
  [Types.DEACTIVATE_ORGANIZATION]: deactivateOrganization,
  [Types.DEACTIVATE_ORGANIZATION_FINISHED]: deactivateOrganizationFinished
})
