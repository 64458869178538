export const COLORS = {
  transparent: 'rgba(0,0,0,0)',
  white: '#FFF',
  black: '#000',
  loginBackground: '#F4F4F4',
  grayBackground: '#F7F7F7',
  lightGray: '#828282',
  navGray: 'rgba(255,255,255,0.7)',
  inputPlaceholder: 'rgba(255,255,255,0.8)',
  border: 'rgba(255,255,255,0.3)',
  lightButton: 'rgba(255,255,255,0.3)',
  grayBorder: '#E0E0E0',
  text: '#343434',
  dark: '#172A35',
  darkBackground: '#2E3F49',
  darkGradientStart: '#001520',
  darkGradientEnd: 'rgba(0,21,32,0.8)',
  violetGradientStart: '#CD259B',
  violetGradientEnd: '#A90C7A',
  violet: '#A90C7A',
  disabledButton: '#c1c1c1',
  placeholder: '#BDBDBD',
  red: '#f50057',
  transaction: '#384851'
}
