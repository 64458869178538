import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import withRoot from '../utils/withRoot'
import UserActions from '../redux/UserRedux'
import NotificationActions from '../redux/NotificationRedux'
import LoginForm from '../components/Auth/LoginForm'
import LoginCornerBg from '../components/Auth/LoginCornerBg'
import Notification from '../components/Notification'
import ResetPasswordModal from '../components/Auth/ResetPasswordModal'
import { COLORS } from '../utils/colors'

const styles = theme => ({
  container: {
    backgroundColor: COLORS.darkBackground
  },
  innerContainer: {
    minHeight: '100vh',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    padding: '10px 20px'
  },
  loginFormContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    maxWidth: 600,
    zIndex: 2,
    paddingTop: 80,
    paddingLeft: 60,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20
    }
  }
})

class LoginScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      modalVisible: false,
      resetEmail: ''
    }
  }

  setEmail = (evt) => this.setState({ email: evt.target.value })

  setPassword = (evt) => this.setState({ password: evt.target.value })

  setResetEmail = (evt) => this.setState({ resetEmail: evt.target.value })

  handleOpenModal = () => this.setState({ modalVisible: true })

  handleCloseModal = () => this.setState({ modalVisible: false, resetEmail: '' })

  login = () => this.props.login(this.state.email, this.state.password)

  resetPassword = () => this.props.requestPasswordReset(encodeURIComponent(this.state.resetEmail))

  renderSuccessNotification = () => {
    const { success, t, clearNotifications } = this.props
    const message = success ? t('password_reset_success') : null
    return <Notification type='success' message={message} onClose={clearNotifications} />
  }

  renderErrorNotification = () => {
    const { error, t, clearNotifications } = this.props
    const message = (error && error.message) ? t(error.message) : null
    return <Notification type='error' message={message} onClose={clearNotifications} />
  }

  renderResetPasswordModal = () => {
    const { fetching } = this.props
    const { modalVisible, resetEmail } = this.state
    return (
      <ResetPasswordModal
        visible={modalVisible}
        handleClose={this.handleCloseModal}
        email={resetEmail}
        setEmail={this.setResetEmail}
        onSubmit={this.resetPassword}
        loading={fetching}
      />
    )
  }

  render () {
    const { classes, token, fetching } = this.props
    const { email, password, modalVisible } = this.state
    if (token) return <Redirect to='/dashboard' />
    return (
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.loginFormContainer}>
            <LoginForm
              email={email}
              password={password}
              setEmail={this.setEmail}
              setPassword={this.setPassword}
              onSubmit={this.login}
              loading={fetching && !modalVisible}
              handleOpenModal={this.handleOpenModal}
            />
          </div>
          <LoginCornerBg />
        </div>
        {this.renderSuccessNotification()}
        {this.renderErrorNotification()}
        {this.renderResetPasswordModal()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    user: state.user.user,
    fetching: state.user.fetching,
    success: state.notification.success,
    error: state.notification.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(UserActions.login(email, password)),
    requestPasswordReset: (email) => dispatch(UserActions.requestPasswordReset(email)),
    clearNotifications: () => dispatch(NotificationActions.clearNotifications())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRoot(withStyles(styles)(withTranslation()(LoginScreen)))
)
