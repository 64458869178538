import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../utils/withRoot'
import PropertyActions from '../redux/PropertyRedux'
import NotificationActions from '../redux/NotificationRedux'
import CustomerActions from '../redux/CustomerRedux'
import MAPS from '../services/mapsApi'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import PropertyInfoForm from '../components/Property/PropertyInfoForm'
import PropertyCustomerInfo from '../components/Property/PropertyCustomerInfo'
import { isEditDisabled } from '../utils/permissions'
import { FIELDS } from '../utils/constants'

const styles = theme => ({
})
const api = MAPS.create()

class PropertyScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: get(props, 'property.name', '') || '',
      address: get(props, 'property.address', '') || '',
      city: get(props, 'property.city', '') || '',
      postalCode: get(props, 'property.postalCode', '') || '',
      latitude: get(props, 'property.latitude', '') || '',
      longitude: get(props, 'property.longitude', '') || '',
      editing: props.location.state && props.location.state.edit
    }
  }

  componentWillReceiveProps (nextProps) {
    const { property, customer, getCustomer } = this.props
    // Received property props from api
    if (!property && nextProps.property) {
      this.setState({
        name: get(nextProps, 'property.name', '') || '',
        address: get(nextProps, 'property.address', '') || '',
        city: get(nextProps, 'property.city', '') || '',
        postalCode: get(nextProps, 'property.postalCode', '') || '',
        latitude: get(nextProps, 'property.latitude', '') || '',
        longitude: get(nextProps, 'property.longitude', '') || ''
      })
      if (!customer) {
        getCustomer(nextProps.property.customerId)
      }
    }
  }

  componentDidMount () {
    const { property, customer, getProperty, getCustomer, match } = this.props
    if (!property) {
      getProperty(match.params.propertyId)
    }
    if (!customer && property) {
      getCustomer(property.customerId)
    }
    console.log(this.props)
  }

  setName = (evt) => this.setState({ name: evt.target.value })

  setAddress = (evt) => this.setState({ address: evt.target.value })

  setCity = (evt) => this.setState({ city: evt.target.value })

  setPostalCode = (evt) => this.setState({ postalCode: evt.target.value })

  setLatitude = (evt) => this.setState({ latitude: evt.target.value })

  setLongitude = (evt) => this.setState({ longitude: evt.target.value })

  setEditing = () => this.setState({ editing: true })

  getLatLng = async () => {
    const { address, city, postalCode } = this.state
    if (address && city && postalCode) {
      const response = await api.getCoordsByPlace(address, city, postalCode)
      if (response.ok && response.data.results[0]) {
        const { lat, lng } = response.data.results[0].geometry.location
        this.setState({ latitude: lat, longitude: lng })
      } else {
        this.props.setError({ key: 'client_error', message: 'try_again' })
      }
    }
  }

  updateProperty = () => {
    const { property, updateProperty } = this.props
    const fields = {
      name: this.state.name.trim(),
      address: this.state.address.trim() || null,
      postalCode: this.state.postalCode.trim() || null,
      city: this.state.city.trim() || null,
      latitude: parseFloat(this.state.latitude) || null,
      longitude: parseFloat(this.state.longitude) || null
    }
    updateProperty(property.id, fields)
    this.setState({ editing: false })
  }

  isUpdatePropertyDisabled = () => {
    const { editing } = this.state
    if (!editing) return false
    const required = [FIELDS.NAME, FIELDS.ADDRESS, FIELDS.POSTAL_CODE, FIELDS.CITY]
    return isEditDisabled(this.props.property, this.state, required)
  }

  canDeactivate = () => {
    const { property } = this.props
    return property && property.active
  }

  deactivateProperty = () => {
    const { deactivateProperty, property } = this.props
    deactivateProperty(property.id)
  }

  renderPage = () => {
    const { fetching, deactivateFetching, customer } = this.props
    const { name, address, city, postalCode, latitude, longitude, editing } = this.state
    return (
      <Layout>
        <PageHeader
          title={name}
          onClick={this.updateProperty}
          disabled={this.isUpdatePropertyDisabled()}
          loading={fetching}
          editing={editing}
          setEditing={this.setEditing}
        />
        <PropertyInfoForm
          name={name}
          address={address}
          city={city}
          postalCode={postalCode}
          latitude={latitude}
          longitude={longitude}
          setName={this.setName}
          setAddress={this.setAddress}
          setCity={this.setCity}
          setPostalCode={this.setPostalCode}
          setLatitude={this.setLatitude}
          setLongitude={this.setLongitude}
          canDeactivate={this.canDeactivate()}
          deactivate={this.deactivateProperty}
          loading={deactivateFetching}
          getLatLng={this.getLatLng}
          editing={editing}
        />
        <PropertyCustomerInfo customer={customer} />
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = (state) => {
  return {
    property: state.property.property,
    customer: state.customer.customer,
    fetching: state.property.fetching,
    deactivateFetching: state.property.deactivateFetching
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProperty: (propertyId) => dispatch(PropertyActions.getProperty(propertyId)),
    updateProperty: (propertyId, fields) => dispatch(PropertyActions.updateProperty(propertyId, fields)),
    getCustomer: (customerId) => dispatch(CustomerActions.getCustomer(customerId)),
    deactivateProperty: (propertyId) => dispatch(PropertyActions.deactivateProperty(propertyId)),
    setError: (error) => dispatch(NotificationActions.setError(error))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRoot(withStyles(styles)(withTranslation()(PropertyScreen)))
)
