import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import LoginInput from './LoginInput'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  title: {
    margin: '30px 0 30px 0',
    color: COLORS.white,
    fontSize: 48,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px 40px 0',
      fontSize: 28
    }
  },
  button: {
    color: COLORS.white,
    background: COLORS.violet, // `radial-gradient(${COLORS.violetGradientStart} 0%, ${COLORS.violetGradientEnd} 100%)`,
    '&:hover': {
      background: darken(COLORS.violet, 0.2)
    },
    width: 180,
    height: 56,
    marginTop: 15,
    borderRadius: 5,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 48,
      width: 150,
      borderRadius: 5
    }
  },
  buttonText: {
    margin: 0,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: '700',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  disabledButton: {
    background: COLORS.disabledButton
  },
  loading: {
    color: COLORS.white
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  link: {
    marginTop: 20,
    marginRight: 25,
    fontFamily: 'Roboto',
    fontSize: 14,
    textTransform: 'none',
    textDecoration: 'underline',
    color: COLORS.white,
    [theme.breakpoints.down('sm')]: {
      marginRight: 10,
      fontSize: 12
    }
  }
})

class LoginForm extends React.PureComponent {
  isDisabled = () => !this.props.email || !this.props.password

  renderButtonContent = () => {
    const { classes, t, loading } = this.props
    if (loading) return <CircularProgress className={classes.loading} size={22} />
    return <p className={classes.buttonText}>{t('login')}</p>
  }

  handleKeyPress = (evt) => {
    const { email, password } = this.props
    if (evt.key === 'Enter' && email && password) {
      this.props.onSubmit()
    }
  }

  render () {
    const { classes, t, onSubmit, email, password, setEmail, setPassword, handleOpenModal } = this.props
    return (
      <div>
        <h2 className={classes.title}>{t('login_title')}</h2>
        <LoginInput label={t('email')} value={email} onChange={setEmail} autoFocus onKeyPress={this.handleKeyPress} />
        <LoginInput label={t('password')} value={password} onChange={setPassword} type='password' onKeyPress={this.handleKeyPress} />
        <div className={classes.bottomContainer}>
          <Button
            className={classes.button}
            onClick={onSubmit}
            disabled={this.isDisabled()}
            classes={{ disabled: classes.disabledButton }}
          >
            {this.renderButtonContent()}
          </Button>
          <Button onClick={handleOpenModal} className={classes.link}>
            {t('forgot_password')}
          </Button>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(LoginForm))
