import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 24
  },
  label: {
    margin: 0,
    marginLeft: 15,
    marginBottom: 15,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 10,
      marginLeft: 5
    }
  },
  input: {
    height: 56,
    fontFamily: 'Roboto',
    fontSize: 18,
    color: COLORS.white,
    backgroundColor: COLORS.dark,
    borderRadius: 3,
    border: `1px solid ${COLORS.border}`,
    padding: '0px 15px',
    boxSizing: 'border-box',
    '&::placeholder': {
      color: COLORS.inputPlaceholder
    },
    [theme.breakpoints.down('sm')]: {
      height: 48,
      fontSize: 14
    }
  }
})

class LoginInput extends React.PureComponent {
  render () {
    const { classes, label, value, onChange, placeholder, width, type, autoFocus, onKeyPress } = this.props
    const styles = width ? { width } : {}
    return (
      <div className={classes.container} style={styles}>
        <p className={classes.label}>{label}</p>
        <TextField
          value={value}
          onChange={onChange}
          placeholder={placeholder || label}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.input }
          }}
          type={type || 'text'}
          autoFocus={autoFocus}
          onKeyPress={onKeyPress}
        />
      </div>
    )
  }
}

export default withStyles(styles)(LoginInput)
