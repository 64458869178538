import LoginScreen from './containers/LoginScreen'
import DashboardScreen from './containers/DashboardScreen'
import ResetPasswordScreen from './containers/ResetPasswordScreen'
import SettingsScreen from './containers/SettingsScreen'
import AssetStorageScreen from './containers/AssetStorageScreen'
import CreateAssetStorageScreen from './containers/CreateAssetStorageScreen'
import AssetStoragesScreen from './containers/AssetStoragesScreen'
import CreateCustomerScreen from './containers/CreateCustomerScreen'
import CustomersScreen from './containers/CustomersScreen'
import CustomerScreen from './containers/CustomerScreen'
import DomainsScreen from './containers/DomainsScreen'
import OrganizationScreen from './containers/OrganizationScreen'
import OrganizationsScreen from './containers/OrganizationsScreen'
import CreateOrganizationScreen from './containers/CreateOrganizationScreen'
import AssetScreen from './containers/AssetScreen'
import AssetsScreen from './containers/AssetsScreen'
import CreateAssetScreen from './containers/CreateAssetScreen'
import UsersScreen from './containers/UsersScreen'
import UserScreen from './containers/UserScreen'
import CreatePropertyScreen from './containers/CreatePropertyScreen'
import PropertyScreen from './containers/PropertyScreen'
import CreateUserScreen from './containers/CreateUserScreen'
import AssetTransactionScreen from './containers/AssetTransactionScreen'

const routes = [
  { path: '/', component: LoginScreen, exact: true, public: true },
  { path: '/dashboard', component: DashboardScreen, exact: true },
  { path: '/vaihda-salasana', component: ResetPasswordScreen, exact: true },
  { path: '/asetukset', component: SettingsScreen, exact: true },
  { path: '/avaimet', component: AssetsScreen, exact: true },
  { path: '/avain/:assetId', component: AssetScreen, exact: true },
  { path: '/uusi-avain', component: CreateAssetScreen, exact: true },
  { path: '/avainkaapit', component: AssetStoragesScreen, exact: true },
  { path: '/avainkaappi/:assetStorageId', component: AssetStorageScreen, exact: true },
  { path: '/uusi-avainkaappi', component: CreateAssetStorageScreen, exact: true },
  { path: '/uusi-asiakas', component: CreateCustomerScreen, exact: true },
  { path: '/asiakkaat', component: CustomersScreen, exact: true },
  { path: '/asiakas/:customerId', component: CustomerScreen, exact: true },
  { path: '/toimialat', component: DomainsScreen, exact: true },
  { path: '/yrityksen-tiedot', component: OrganizationScreen, exact: true },
  { path: '/organisaatiot', component: OrganizationsScreen, exact: true },
  { path: '/uusi-organisaatio', component: CreateOrganizationScreen, exact: true },
  { path: '/kayttajat', component: UsersScreen, exact: true },
  { path: '/kayttaja/:userId', component: UserScreen, exact: true },
  { path: '/uusi-kayttaja', component: CreateUserScreen, exact: true },
  { path: '/asiakas/:customerId/uusi-kohde', component: CreatePropertyScreen, exact: true },
  { path: '/kohde/:propertyId', component: PropertyScreen, exact: true },
  { path: '/tapahtuma/:transactionId', component: AssetTransactionScreen, exact: true }
]

export default routes
