import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import withRoot from '../utils/withRoot'
import get from 'lodash/get'
import AssetStorageActions from '../redux/AssetStorageRedux'
import AssetActions from '../redux/AssetRedux'
import NotificationActions from '../redux/NotificationRedux'
import MAPS from '../services/mapsApi'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
// import DeactivateButton from '../components/DeactivateButton'
import AssetStorageForm from '../components/AssetStorage/AssetStorageForm'
import AssetStorageLocationForm from '../components/AssetStorage/AssetStorageLocationForm'
import AssetStorageInfo from '../components/AssetStorage/AssetStorageInfo'
import AssetStorageAssets from '../components/AssetStorage/AssetStorageAssets'
import { isEditDisabled } from '../utils/permissions'
import { FIELDS } from '../utils/constants'

const styles = theme => ({})
const api = MAPS.create()

class AssetStorageScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: get(props, 'assetStorage.name', '') || '',
      description: get(props, 'assetStorage.description', '') || '',
      address: get(props, 'assetStorage.address', '') || '',
      postalCode: get(props, 'assetStorage.postalCode', '') || '',
      city: get(props, 'assetStorage.city', '') || '',
      latitude: get(props, 'assetStorage.latitude', '') || '',
      longitude: get(props, 'assetStorage.longitude', '') || '',
      editing: props.location.state && props.location.state.edit
    }
  }

  componentDidMount () {
    const { match, getAssetStorage, assetStorage, getAssetStorageAssets } = this.props
    const { assetStorageId } = match.params
    if (!assetStorage) getAssetStorage(assetStorageId)
    getAssetStorageAssets(assetStorageId, { active: 'true' })
  }

  componentWillReceiveProps (nextProps) {
    if (!this.props.assetStorage && nextProps.assetStorage) {
      this.setState({
        name: get(nextProps, 'assetStorage.name', '') || '',
        description: get(nextProps, 'assetStorage.description', '') || '',
        address: get(nextProps, 'assetStorage.address', '') || '',
        postalCode: get(nextProps, 'assetStorage.postalCode', '') || '',
        city: get(nextProps, 'assetStorage.city', '') || '',
        latitude: get(nextProps, 'assetStorage.latitude', '') || '',
        longitude: get(nextProps, 'assetStorage.longitude', '') || ''
      })
    }
  }

  setName = evt => this.setState({ name: evt.target.value })

  setDescription = evt => this.setState({ description: evt.target.value })

  setAddress = evt => this.setState({ address: evt.target.value })

  setPostalCode = evt => this.setState({ postalCode: evt.target.value })

  setCity = evt => this.setState({ city: evt.target.value })

  setLatitude = evt => this.setState({ latitude: evt.target.value })

  setLongitude = evt => this.setState({ longitude: evt.target.value })

  setEditing = () => this.setState({ editing: true })

  isUpdateAssetStorageDisabled = () => {
    const { editing } = this.state
    if (!editing) return false
    const required = [FIELDS.NAME, FIELDS.ADDRESS, FIELDS.POSTAL_CODE, FIELDS.CITY]
    return isEditDisabled(this.props.assetStorage, this.state, required)
  }

  getLatLng = async () => {
    const { address, city, postalCode } = this.state
    if (address && city && postalCode) {
      const response = await api.getCoordsByPlace(address, city, postalCode)
      if (response.ok && response.data.results[0]) {
        const { lat, lng } = response.data.results[0].geometry.location
        this.setState({ latitude: lat, longitude: lng })
      } else {
        this.props.setError({ key: 'client_error', message: 'try_again' })
      }
    }
  }

  updateAssetStorage = () => {
    const { name, address, postalCode, city, description } = this.state
    const { assetStorage, updateAssetStorage } = this.props
    const fields = {}
    if (name !== assetStorage.firstName) fields.name = name
    if (address !== assetStorage.lastName) fields.address = address
    if (postalCode !== assetStorage.postalCode) fields.postalCode = postalCode
    if (city !== assetStorage.city) fields.city = city
    if (description !== assetStorage.description) fields.description = description
    updateAssetStorage(assetStorage.id, fields)
    this.setState({ editing: false })
  }

  canDeactivate = () => {
    const { assetStorage } = this.props
    return assetStorage && assetStorage.active
  }

  deactivateAssetStorage = () => {
    const { deactivateAssetStorage, assetStorage } = this.props
    deactivateAssetStorage(assetStorage.id)
  }

  renderPage = () => {
    const { t, fetching, assetStorage, assets, assetsFetching, totalAssets, setAsset, ...rest
      // deactivateFetching
    } = this.props
    const { name, description, address, postalCode, city, latitude, longitude, editing } = this.state
    return (
      <Layout>
        <PageHeader
          title={name}
          onClick={this.updateAssetStorage}
          disabled={this.isUpdateAssetStorageDisabled()}
          loading={fetching}
          editing={editing}
          setEditing={this.setEditing}
        />
        <AssetStorageForm
          name={name}
          description={description}
          setName={this.setName}
          setDescription={this.setDescription}
          editing={editing}
        />
        <AssetStorageLocationForm
          address={address}
          postalCode={postalCode}
          city={city}
          latitude={latitude}
          longitude={longitude}
          setAddress={this.setAddress}
          setPostalCode={this.setPostalCode}
          setCity={this.setCity}
          setLatitude={this.setLatitude}
          setLongitude={this.setLongitude}
          getLatLng={this.getLatLng}
          editing={editing}
        />
        <AssetStorageInfo assetStorage={assetStorage} />
        {/* <DeactivateButton
          canDeactivate={this.canDeactivate()}
          deactivate={this.deactivateAssetStorage}
          deactivateLoading={deactivateFetching}
          deactivateText={t('deactivate_asset_storage')}
        /> */}
        <AssetStorageAssets
          assets={assets}
          total={totalAssets}
          loading={assetsFetching}
          assetStorage={assetStorage}
          setAsset={setAsset}
          {...rest}
        />
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = state => {
  return {
    assetStorage: state.assetStorage.assetStorage,
    fetching: state.assetStorage.fetching,
    error: state.notification.error,
    deactivateFetching: state.assetStorage.deactivateFetching,
    assets: state.asset.assets,
    assetsFetching: state.asset.fetching,
    totalAssets: state.asset.total
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAssetStorage: id => dispatch(AssetStorageActions.getAssetStorage(id)),
    updateAssetStorage: (id, fields) => dispatch(AssetStorageActions.updateAssetStorage(id, fields)),
    deactivateAssetStorage: id => dispatch(AssetStorageActions.deactivateAssetStorage(id)),
    getAssetStorageAssets: (id, params) => dispatch(AssetActions.getAssetStorageAssets(id, params)),
    setAsset: (asset) => dispatch(AssetActions.setAsset(asset)),
    setError: (error) => dispatch(NotificationActions.setError(error))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoot(withStyles(styles)(withTranslation()(AssetStorageScreen))))
