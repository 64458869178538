import React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import withRoot from '../utils/withRoot'
import OrganizationActions from '../redux/OrganizationRedux'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
// import DeactivateButton from '../components/DeactivateButton'
import OrganizationContactInfoForm from '../components/Organization/OrganizationContactInfoForm'
import OrganizationLocationForm from '../components/Organization/OrganizationLocationForm'
import OrganizationInfoForm from '../components/Organization/OrganizationInfoForm'
import { isAdminLevelUser, canDeactivateOrganization, isEditDisabled } from '../utils/permissions'
import { FIELDS, ROLES } from '../utils/constants'

const styles = theme => ({})

class OrganizationScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: get(props, 'organization.name', '') || '',
      description: get(props, 'organization.description', '') || '',
      businessId: get(props, 'organization.businessId', '') || '',
      phone: get(props, 'organization.phone', '') || '',
      email: get(props, 'organization.email', '') || '',
      address: get(props, 'organization.address', '') || '',
      city: get(props, 'organization.city', '') || '',
      postalCode: get(props, 'organization.postalCode') || '',
      contactPerson: get(props, 'organization.contactPerson', '') || '',
      url: get(props, 'organization.url', '') || '',
      active: get(props, 'organization.active', '') || '',
      editing: props.location.state && props.location.state.edit
    }
  }

  componentWillReceiveProps (nextProps) {
    if (!this.props.organization && nextProps.organization) {
      this.setState({
        name: nextProps.organization.name || '',
        description: nextProps.organization.description || '',
        businessId: nextProps.organization.businessId || '',
        phone: nextProps.organization.phone || '',
        email: nextProps.organization.email || '',
        address: nextProps.organization.address || '',
        city: nextProps.organization.city || '',
        postalCode: nextProps.organization.postalCode || '',
        contactPerson: nextProps.organization.contactPerson || '',
        url: nextProps.organization.url || '',
        active: nextProps.organization.active || ''
      })
    }
  }

  setName = evt => this.setState({ name: evt.target.value })

  setDescription = evt => this.setState({ description: evt.target.value })

  setBusinessId = evt => this.setState({ businessId: evt.target.value })

  setEmail = evt => this.setState({ email: evt.target.value })

  setPhone = evt => this.setState({ phone: evt.target.value })

  setAddress = evt => this.setState({ address: evt.target.value })

  setPostalCode = evt => this.setState({ postalCode: evt.target.value })

  setCity = evt => this.setState({ city: evt.target.value })

  setContactPerson = evt => this.setState({ contactPerson: evt.target.value })

  setUrl = evt => this.setState({ url: evt.target.value })

  setEditing = () => this.setState({ editing: true })

  canUpdate = () => isAdminLevelUser(this.props.user)

  canDeactivate = () => canDeactivateOrganization(this.props.user, this.props.organization)

  isUpdateOrganizationDisabled = () => {
    const { editing } = this.state
    const { user } = this.props

    if (!!user && user.role === ROLES.USER) return true
    console.log(user)
    if (!editing) return false
    const required = [
      FIELDS.NAME,
      FIELDS.ADDRESS,
      FIELDS.POSTAL_CODE,
      FIELDS.CITY,
      FIELDS.BUSINESS_ID,
      FIELDS.CONTACT_PERSON,
      FIELDS.PHONE,
      FIELDS.EMAIL
    ]
    return isEditDisabled(this.props.organization, this.state, required)
  }

  updateOrganization = () => {
    this.props.updateOrganization(this.props.organization.id, this.state)
    this.setState({ editing: false })
  }

  deactivateOrganization = () => this.props.deactivateOrganization(this.props.organization.id)

  renderPage = () => {
    const {
      fetching
      //, t, deactivateFetching
    } = this.props
    const {
      name,
      description,
      businessId,
      email,
      phone,
      address,
      city,
      postalCode,
      contactPerson,
      url,
      editing
    } = this.state
    console.log(this.isUpdateOrganizationDisabled())
    return (
      <Layout>
        <PageHeader
          title={name}
          onClick={this.updateOrganization}
          disabled={this.isUpdateOrganizationDisabled()}
          loading={fetching}
          // hideButton={!this.canUpdate()}
          editing={editing}
          setEditing={this.setEditing}
        />
        <OrganizationInfoForm
          name={name}
          businessId={businessId}
          description={description}
          setName={this.setName}
          setBusinessId={this.setBusinessId}
          setDescription={this.setDescription}
          editing={this.canUpdate() && editing}
        />
        <OrganizationContactInfoForm
          email={email}
          phone={phone}
          contactPerson={contactPerson}
          url={url}
          setEmail={this.setEmail}
          setPhone={this.setPhone}
          setContactPerson={this.setContactPerson}
          setUrl={this.setUrl}
          editing={this.canUpdate() && editing}
        />
        <OrganizationLocationForm
          address={address}
          city={city}
          postalCode={postalCode}
          setAddress={this.setAddress}
          setCity={this.setCity}
          setPostalCode={this.setPostalCode}
          editing={this.canUpdate() && editing}
        />
        {/* <DeactivateButton
          canDeactivate={this.canDeactivate()}
          deactivate={this.deactivateOrganization}
          deactivateLoading={deactivateFetching}
          deactivateText={t('deactivate_organization')}
        /> */}
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    fetching: state.organization.fetching,
    deactivateFetching: state.organization.deactivateFetching,
    organization: state.organization.organization,
    success: state.notification.success,
    error: state.notification.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOrganization: id => dispatch(OrganizationActions.getOrganization(id)),
    updateOrganization: (id, fields) =>
      dispatch(OrganizationActions.updateOrganization(id, fields)),
    deactivateOrganization: id => dispatch(OrganizationActions.deactivateOrganization(id))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoot(withStyles(styles)(withTranslation()(OrganizationScreen))))
