import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router-dom'
import { withRouter } from 'react-router'
import NotificationActions from '../redux/NotificationRedux'
import UserActions from '../redux/UserRedux'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Header from './Header'
import Sidebar from './Sidebar'
import Notification from '../components/Notification'
import { MENU_ITEMS, SUPERADMIN_ALL_MENU_ITEMS, SUPERADMIN_LIMITED_MENU_ITEMS } from '../utils/nav'
import { ROLES } from '../utils/constants'
import { LAYOUT_SIDEBAR_OPEN, readStorage, writeStorage } from '../utils/storage'
import { COLORS } from '../utils/colors'

const styles = theme => ({
  root: {
    backgroundColor: COLORS.darkBackground,
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },
  appBar: {
    height: 56,
    zIndex: theme.zIndex.drawer + 1,
    background: COLORS.dark,
    boxShadow: 'none',
    marginLeft: 60,
    width: `calc(100% - 60px)`,
    borderBottom: `0.5px solid ${COLORS.border}`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    height: 56,
    zIndex: theme.zIndex.drawer + 1,
    background: COLORS.dark,
    boxShadow: 'none',
    marginLeft: 300,
    width: `calc(100% - 300px)`,
    borderBottom: `0.5px solid ${COLORS.border}`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  content: {
    minHeight: '100vh',
    flexGrow: 1,
    minWidth: 'calc(100% - 300px)',
    paddingBottom: 200
  },
  toolbarRoot: {
    minHeight: 56
  },
  toolbar: theme.mixins.toolbar,
  loading: {
    color: COLORS.violet,
    position: 'absolute',
    top: '50%',
    left: '50%'
  }
})

class Layout extends React.PureComponent {
  constructor (props) {
    super(props)
    // Load previous state from local storage
    this.state = {
      open: readStorage(LAYOUT_SIDEBAR_OPEN) !== false
    }
  }

  componentDidMount () {
    if (this.props.token && !this.props.user) {
      this.props.getMe(this.props.token)
    }
  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
    writeStorage(LAYOUT_SIDEBAR_OPEN, true)
  }

  handleDrawerClose = () => {
    this.setState({ open: false })
    writeStorage(LAYOUT_SIDEBAR_OPEN, false)
  }

  toUserScreen = (user, edit) => {
    this.props.history.push('/asetukset')
  }

  getMenuItems = () => {
    const { user, currentOrganizationId } = this.props
    if (user.role === ROLES.SUPERADMIN) {
      if (!currentOrganizationId) return SUPERADMIN_LIMITED_MENU_ITEMS
      return SUPERADMIN_ALL_MENU_ITEMS
    }
    return MENU_ITEMS
  }

  renderSuccessNotification = () => {
    const { success, t, clearNotifications } = this.props
    const message = success ? t(success) : null
    return (
      <Notification
        type='success'
        message={message}
        open={!!message}
        onClose={clearNotifications}
      />
    )
  }

  renderErrorNotification = () => {
    const { error, t, clearNotifications } = this.props
    const message = (error && error.message) ? t(error.message) : null
    return (
      <Notification
        type='error'
        message={message}
        open={!!message}
        onClose={clearNotifications}
      />
    )
  }

  renderContent = () => {
    const { classes, children, user, token, organization, logout } = this.props
    const { open } = this.state
    if (user) {
      return (
        <div className={classes.root}>
          <AppBar className={classNames(classes.appBar, { [classes.appBarShift]: open })}>
            <Toolbar classes={{ root: classes.toolbarRoot }} disableGutters>
              <Header user={user} logout={logout} toUserScreen={this.toUserScreen} />
            </Toolbar>
          </AppBar>
          <Sidebar
            open={open}
            handleOpen={this.handleDrawerOpen}
            handleClose={this.handleDrawerClose}
            menuItems={this.getMenuItems()}
            organization={organization}
          />
          <main className={classes.content}>
            <div className={classes.toolbarRoot} />
            {children}
          </main>
          {this.renderSuccessNotification()}
          {this.renderErrorNotification()}
        </div>
      )
    } else if (token) {
      return (
        <div className={classes.root}>
          <main className={classes.content}>
            <CircularProgress className={classes.loading} size={30} />
          </main>
        </div>
      )
    }
    return <Redirect to='/' />
  }

  render () {
    return this.renderContent()
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    token: state.user.token,
    currentOrganizationId: state.user.currentOrganizationId,
    organization: state.organization.organization,
    success: state.notification.success,
    error: state.notification.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(UserActions.logout()),
    getMe: (token) => dispatch(UserActions.getMe(token)),
    clearNotifications: () => dispatch(NotificationActions.clearNotifications()),
    setTargetUser: (user) => dispatch(UserActions.setTargetUser(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(withTranslation()(withRouter(Layout)))
)
