import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getAssetStorages: ['organizationId', 'params'],
  getAssetStoragesSuccess: ['assetStorages', 'total'],
  getAssetStoragesFailure: null,
  getAssetStorage: ['id'],
  getAssetStorageSuccess: ['assetStorage'],
  getAssetStorageFailure: null,
  createAssetStorage: ['organizationId', 'fields'],
  createAssetStorageFinished: null,
  updateAssetStorage: ['id', 'fields'],
  updateAssetStorageSuccess: ['assetStorage'],
  updateAssetStorageFailure: null,
  deactivateAssetStorage: ['id'],
  deactivateAssetStorageSuccess: ['assetStorage'],
  deactivateAssetStorageFailure: null,
  setAssetStorage: ['assetStorage']
})

export const AssetStorageTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  assetStorage: null,
  assetStorages: null,
  total: null,
  deactivateFetching: null
})

/* ------------- Reducers ------------- */

const getAssetStorages = state => state.merge({ fetching: true })
const getAssetStoragesSuccess = (state, { assetStorages, total }) => state.merge({ assetStorages, total, fetching: false })
const getAccessStoragesFailure = state => state.merge({ fetching: false })

const getAssetStorage = state => state.merge({ fetching: true })
const getAssetStorageSuccess = (state, { assetStorage }) => state.merge({ assetStorage, fetching: false })
const getAccessStorageFailure = state => state.merge({ fetching: false })

const createAssetStorage = (state, { organizationId, fields }) => state.merge({ fetching: true })
const createAssetStorageFinished = (state) => state.merge({ fetching: false })

const updateAssetStorage = (state, { id, fields }) => state.merge({ fetching: true })
const updateAssetStorageSuccess = (state, { assetStorage }) => state.merge({ assetStorage, fetching: false })
const updateAssetStorageFailure = state => state.merge({ fetching: false })

const deactivateAssetStorage = (state, { id }) => state.merge({ deactivateFetching: true })
const deactivateAssetStorageSuccess = (state, { assetStorage }) => state.merge({ assetStorage, deactivateFetching: false })
const deactivateAssetStorageFailure = state => state.merge({ deactivateFetching: false })

const setAssetStorage = (state, { assetStorage }) => state.merge({ assetStorage })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ASSET_STORAGES]: getAssetStorages,
  [Types.GET_ASSET_STORAGES_SUCCESS]: getAssetStoragesSuccess,
  [Types.GET_ASSET_STORAGES_FAILURE]: getAccessStoragesFailure,
  [Types.GET_ASSET_STORAGE]: getAssetStorage,
  [Types.GET_ASSET_STORAGE_SUCCESS]: getAssetStorageSuccess,
  [Types.GET_ASSET_STORAGE_FAILURE]: getAccessStorageFailure,
  [Types.CREATE_ASSET_STORAGE]: createAssetStorage,
  [Types.CREATE_ASSET_STORAGE_FINISHED]: createAssetStorageFinished,
  [Types.UPDATE_ASSET_STORAGE]: updateAssetStorage,
  [Types.UPDATE_ASSET_STORAGE_SUCCESS]: updateAssetStorageSuccess,
  [Types.UPDATE_ASSET_STORAGE_FAILURE]: updateAssetStorageFailure,
  [Types.DEACTIVATE_ASSET_STORAGE]: deactivateAssetStorage,
  [Types.DEACTIVATE_ASSET_STORAGE_SUCCESS]: deactivateAssetStorageSuccess,
  [Types.DEACTIVATE_ASSET_STORAGE_FAILURE]: deactivateAssetStorageFailure,
  [Types.SET_ASSET_STORAGE]: setAssetStorage
})
