import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { darken } from '@material-ui/core/styles/colorManipulator'
import Input from '../Input'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  container: {
    maxWidth: 1000,
    padding: '40px 60px 0px 60px'
  },
  header: {
    color: COLORS.white,
    fontSize: 24,
    margin: 0,
    marginBottom: 30,
    fontFamily: 'Chivo',
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  row: {
    display: 'flex',
    marginBottom: 30
  },
  spacer: {
    minWidth: 60
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    minWidth: 'calc(50% - 30px)'
  },
  button: {
    color: COLORS.white,
    background: COLORS.lightButton, // `radial-gradient(${COLORS.violetGradientStart} 0%, ${COLORS.violetGradientEnd} 100%)`,
    '&:hover': {
      background: darken(COLORS.lightButton, 0.2)
    },
    width: '100%',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center'
  },
  buttonText: {
    margin: 0,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 16,
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  disabledButton: {
    background: COLORS.disabledButton
  },
  column: {
    flexDirection: 'column',
    minWidth: 'calc(50% - 30px)'
  },
  label: {
    margin: 0,
    marginLeft: 15,
    marginBottom: 15,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 10,
      marginLeft: 5
    }
  },
  value: {
    margin: 0,
    marginLeft: 15,
    color: COLORS.white,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginLeft: 5
    }
  }
})

class PropertyInfoForm extends React.PureComponent {
  renderCoordButton = () => {
    const { classes, t, getLatLng, address, city, postalCode } = this.props
    return (
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          classes={{ disabled: classes.disabledButton }}
          onClick={getLatLng}
          disabled={!address || !city || !postalCode}
        >
          <p className={classes.buttonText}>{t('set_lat_lng')}</p>
        </Button>
      </div>
    )
  }

  renderDeactivateButtonContent = () => {
    const { classes, t, loading } = this.props
    if (loading) return <CircularProgress className={classes.loading} size={22} />
    return <p className={classes.buttonText}>{t('deactivate_property')}</p>
  }

  renderDeactivateProperty = () => {
    const { classes, canDeactivate, deactivate } = this.props
    if (canDeactivate) {
      return (
        <div className={classes.buttonContainer}>
          <Button className={classes.button} onClick={deactivate}>
            {this.renderDeactivateButtonContent()}
          </Button>
        </div>
      )
    }
    return null
  }

  renderContent = () => {
    const {
      classes,
      t,
      name,
      address,
      postalCode,
      city,
      latitude,
      longitude,
      editing,
      setName,
      setAddress,
      setPostalCode,
      setCity,
      setLatitude,
      setLongitude
    } = this.props
    if (editing) {
      return (
        <div>
          <div className={classes.row}>
            <Input label={t('name')} value={name} onChange={setName} required />
            <div className={classes.spacer} />
            <Input label={t('street_address')} value={address} onChange={setAddress} required />
          </div>
          <div className={classes.row}>
            <Input label={t('postal_code')} value={postalCode} onChange={setPostalCode} required />
            <div className={classes.spacer} />
            <Input label={t('city')} value={city} onChange={setCity} required />
          </div>
          <div className={classes.row}>
            <Input label={t('latitude')} value={latitude} onChange={setLatitude} />
            <div className={classes.spacer} />
            <Input label={t('longitude')} value={longitude} onChange={setLongitude} />
          </div>
          <div className={classes.row}>{this.renderCoordButton()}</div>
        </div>
      )
    }
    return (
      <div>
        <div className={classes.row}>
          <div className={classes.column}>
            <p className={classes.label}>{t('name')}</p>
            <p className={classes.value}>{name}</p>
          </div>
          <div className={classes.spacer} />
          <div className={classes.column}>
            <p className={classes.label}>{t('street_address')}</p>
            <p className={classes.value}>{address || '-'}</p>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.column}>
            <p className={classes.label}>{t('postal_code')}</p>
            <p className={classes.value}>{postalCode || '-'}</p>
          </div>
          <div className={classes.spacer} />
          <div className={classes.column}>
            <p className={classes.label}>{t('city')}</p>
            <p className={classes.value}>{city || '-'}</p>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.column}>
            <p className={classes.label}>{t('latitude')}</p>
            <p className={classes.value}>{latitude || '-'}</p>
          </div>
          <div className={classes.spacer} />
          <div className={classes.column}>
            <p className={classes.label}>{t('longitude')}</p>
            <p className={classes.value}>{longitude || '-'}</p>
          </div>
        </div>
      </div>
    )
  }

  render () {
    const { classes, t } = this.props
    return (
      <div className={classes.container}>
        <p className={classes.header}>{t('property_info')}</p>
        {this.renderContent()}
        {/* <div className={classes.row}>
          {this.renderDeactivateProperty()}
        </div> */}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(PropertyInfoForm))
