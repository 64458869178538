import { call, put, select } from 'redux-saga/effects'
import CustomerActions from '../redux/CustomerRedux'
import NotificationActions from '../redux/NotificationRedux'
import { composeError } from '../utils/transforms'

export function * createCustomer (api, action) {
  const { organizationId, fields } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.createCustomer, token, organizationId, fields)
  console.log(response)
  if (response.ok) {
    const customer = response.data
    yield put(CustomerActions.createCustomerSuccess(customer))
    yield put(NotificationActions.setSuccess('create_customer_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(CustomerActions.createCustomerFailure())
  }
}

export function * getCustomer (api, action) {
  const { customerId } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.getCustomer, token, customerId)
  if (response.ok) {
    const customer = response.data
    yield put(CustomerActions.getCustomerSuccess(customer))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(CustomerActions.getCustomerFailure())
  }
}

export function * getCustomers (api, action) {
  const { organizationId, params } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.getCustomers, token, organizationId, params)
  if (response.ok) {
    const customers = response.data
    yield put(CustomerActions.getCustomersSuccess(customers))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(CustomerActions.getCustomersFailure())
  }
}

export function * updateCustomer (api, action) {
  const { customerId, fields } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.updateCustomer, token, customerId, fields)
  console.log(response)
  if (response.ok) {
    const customer = response.data
    yield put(CustomerActions.updateCustomerSuccess(customer))
    yield put(NotificationActions.setSuccess('update_customer_success'))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(CustomerActions.updateCustomerFailure())
  }
}

export function * addCustomerDomain (api, action) {
  const { customerId, domainId } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.addCustomerDomain, token, customerId, domainId)
  console.log(response)
  if (response.ok) {
    yield put(CustomerActions.addCustomerDomainFinished())
    yield put(NotificationActions.setSuccess('update_customer_success'))
    // Refresh customer
    yield put(CustomerActions.getCustomer(customerId))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(CustomerActions.addCustomerDomainFinished())
  }
}

export function * removeCustomerDomain (api, action) {
  const { customerId, domainId } = action
  const { token } = yield select((state) => state.user)
  const response = yield call(api.removeCustomerDomain, token, customerId, domainId)
  console.log(response)
  if (response.ok) {
    yield put(CustomerActions.removeCustomerDomainFinished())
    yield put(NotificationActions.setSuccess('update_customer_success'))
    // Refresh customer
    yield put(CustomerActions.getCustomer(customerId))
  } else {
    const error = composeError(response)
    yield put(NotificationActions.setError(error))
    yield put(CustomerActions.removeCustomerDomainFinished())
  }
}
