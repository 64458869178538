import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import withRoot from '../utils/withRoot'
import CustomerActions from '../redux/CustomerRedux'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import CustomerTable from '../components/Customer/CustomerTable'

const styles = theme => ({
})

class CustomersScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      pageSize: 10,
      page: 0,
      search: ''
    }
    this.timeout = 0
  }

  componentDidMount () {
    const { currentOrganizationId } = this.props
    const { pageSize } = this.state
    // Load customers if user is ready
    if (currentOrganizationId) {
      this.props.getCustomers(currentOrganizationId, { limit: pageSize, offset: 0 })
    }
  }

  componentWillReceiveProps (nextProps) {
    const { customers, currentOrganizationId } = this.props
    const { pageSize } = this.state
    // Load customers when user is loaded
    if (!customers && !currentOrganizationId && nextProps.currentOrganizationId) {
      this.props.getCustomers(nextProps.currentOrganizationId, { limit: pageSize, offset: 0 })
    }
  }

  toCreateCustomer = () => this.props.history.push('/uusi-asiakas')

  setPage = (evt, page) => {
    const { currentOrganizationId } = this.props
    const { pageSize } = this.state
    this.props.getCustomers(currentOrganizationId, { limit: pageSize, offset: page * pageSize })
    this.setState({ page })
  }

  handleSearchChange = evt => {
    const { page, pageSize } = this.state
    const searchValue = evt.target.value
    this.setState({ search: searchValue })

    if (this.timeout) clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.props.getCustomers(this.props.currentOrganizationId, {
        limit: pageSize,
        offset: page * pageSize,
        search: searchValue
      })
    }, 300)
  }

  toCustomer = (customer, edit) => {
    this.props.setCustomer(customer)
    this.props.history.push({
      pathname: `/asiakas/${customer.id}`,
      state: {
        edit
      }
    })
  }

  renderPage = () => {
    const { t, customers, fetching, user } = this.props
    const { page, pageSize, search } = this.state
    const totalCount = customers ? customers.total : 0
    const visibleCustomers = customers ? customers.results : null

    return (
      <Layout>
        <PageHeader
          title={t('customers')}
          onClick={this.toCreateCustomer}
          loading={fetching}
          buttonText={t('create_customer')}
          listScreen
        />
        <CustomerTable
          customers={visibleCustomers}
          toSingle={this.toCustomer}
          loading={fetching}
          onChangePage={this.setPage}
          total={totalCount}
          page={page}
          pageSize={pageSize}
          user={user}
          search={search}
          handleSearchChange={this.handleSearchChange}
        />
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: state.customer.fetching,
    customers: state.customer.customers,
    error: state.notification.error,
    currentOrganizationId: state.user.currentOrganizationId,
    user: state.user.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomers: (organizationId, params) => dispatch(CustomerActions.getCustomers(organizationId, params)),
    setCustomer: (customer) => dispatch(CustomerActions.setCustomer(customer))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRoot(withStyles(styles)(withTranslation()(CustomersScreen)))
)
