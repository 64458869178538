import React from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Collapse from '@material-ui/core/Collapse'
import DrawerHeader from './DrawerHeader'
import { COLORS } from '../utils/colors'

const styles = theme => ({
  listItem: {
    padding: 0,
    height: 60
  },
  listSubItem: {
    padding: 0,
    paddingLeft: 100,
    height: 40
  },
  listIcon: {
    height: 60,
    width: 60,
    alignItems: 'center',
    justifyContent: 'center',
    color: COLORS.white,
    margin: 0
  },
  listArrowIcon: {
    height: 60,
    width: 60,
    alignItems: 'center',
    justifyContent: 'center',
    color: COLORS.white,
    paddingLeft: 30
  },
  listSubheader: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: '700',
    color: COLORS.navGray,
    textTransform: 'uppercase',
    padding: 0,
    paddingLeft: 20,
    marginTop: 10,
    marginBottom: 5,
    height: 30
  },
  listItemTextRoot: {
    padding: 0
  },
  listItemText: {
    fontFamily: 'Chivo',
    fontSize: 14,
    fontWeight: '700',
    color: COLORS.white
  },
  listSubItemText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: '700',
    color: COLORS.white,
    opacity: 0.8,
    '&:hover': {
      opacity: 1
    }
  },
  leftBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: 60
  },
  drawer: {
    width: 300,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    overflowX: 'hidden',
    width: 300,
    background: `linear-gradient(180deg, ${COLORS.darkGradientStart}, ${COLORS.darkGradientEnd})`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    background: `linear-gradient(180deg, ${COLORS.darkGradientStart}, ${COLORS.darkGradientEnd})`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: 60
  },
  link: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  arrow: {
    transform: 'rotate(-90deg)'
  }
})

class Sidebar extends React.PureComponent {
  state = {
    open: false
  }

  toggleList = () => this.setState({ open: !this.state.open })

  renderSubItems = (subItems) => {
    const { classes, t, open } = this.props
    if (open) {
      return (
        <Collapse in={this.state.open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            { subItems.map((item) => (
              <ListItem key={item.title} classes={{ root: classes.listSubItem }} disableGutters button>
                <Link to={item.path} className={classes.link}>
                  <ListItemText primary={t(item.title)} classes={{ primary: classes.listSubItemText }} />
                </Link>
              </ListItem>
            ))
            }
          </List>
        </Collapse>
      )
    }
    return null
  }

  renderItem = (item) => {
    const { classes, t, open } = this.props
    if (open && item.subItems && item.subItems.length) {
      return (
        <div key={item.title}>
          <ListItem
            classes={{ root: classes.listItem }}
            onClick={this.toggleList}
            disableGutters
            button
          >
            <ListItemIcon classes={{ root: classes.listIcon }}>
              <img src={item.icon} alt={t(item.title)} />
            </ListItemIcon>
            <ListItemText
              primary={t(item.title)}
              classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}
            />
          </ListItem>
          {this.renderSubItems(item.subItems)}
        </div>
      )
    }
    return (
      <ListItem key={item.title} classes={{ root: classes.listItem }} disableGutters button>
        <Link to={item.path} className={classes.link}>
          <ListItemIcon classes={{ root: classes.listIcon }}>
            <img src={item.icon} alt={t(item.title)} />
          </ListItemIcon>
          <ListItemText
            primary={t(item.title)}
            classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}
          />
        </Link>
      </ListItem>
    )
  }

  renderMenuItems = () => {
    const { t, menuItems } = this.props
    return menuItems.map((item, index) => {
      return (
        <List
          component='div'
          subheader={this.renderListSubheader(t(item.header))}
          key={item.header}
          disablePadding
        >
          {item.items.map((item) => this.renderItem(item))}
        </List>
      )
    })
  }

  renderListSubheader = (text) => {
    const { classes, open } = this.props
    if (!open) return null
    return (
      <ListSubheader component='div' classes={{ root: classes.listSubheader }}>
        {text}
      </ListSubheader>
    )
  }

  renderMenu = () => {
    const { classes } = this.props
    return (
      <div>
        <div className={classes.leftBg} />
        <List component='nav' disablePadding>
          {this.renderMenuItems()}
        </List>
      </div>
    )
  }

  render () {
    const { classes, organization, open, handleOpen, handleClose } = this.props
    return (
      <Drawer
        variant='permanent'
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        open={open}
      >
        <DrawerHeader
          organization={organization}
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
        {this.renderMenu()}
      </Drawer>
    )
  }
}

export default withStyles(styles)(withTranslation()(Sidebar))
