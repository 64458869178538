import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import CircularProgress from '@material-ui/core/CircularProgress'
import OrganizationTableHeader from './OrganizationTableHeader'
import OrganizationTableRow from './OrganizationTableRow'
import TableFooter from '../TableFooter'
import { COLORS } from '../../utils/colors'
import Input from '../Input'

const styles = theme => ({
  tableContainer: {
    padding: '60px 60px',
    backgroundColor: COLORS.darkBackground,
    position: 'relative'
  },
  root: {
    alignSelf: 'center',
    width: '100%',
    overflowX: 'auto',
    maxWidth: 1200,
    backgroundColor: COLORS.darkBackground,
    position: 'relative'
  },
  table: {
    minWidth: 600
  },
  rounded: {
    border: `0.5px solid ${COLORS.border}`
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: COLORS.white
  },
  search: {
    width: 270,
    marginBottom: 24
  }
})

class OrganizationTable extends React.PureComponent {
  renderLoading = () => {
    const { classes, loading } = this.props
    if (loading) return <CircularProgress className={classes.loading} size={30} />
    return null
  }

  renderSearch = () => {
    const { t, classes, search, handleSearchChange } = this.props
    return (
      <div className={classes.search}>
        <Input value={search} onChange={handleSearchChange} placeholder={t('search_organization')} search />
      </div>
    )
  }

  renderRows = () => {
    const { organizations, toSingle } = this.props
    if (organizations && organizations.length) {
      return organizations.map((organization) => (
        <OrganizationTableRow
          key={organization.id}
          organization={organization}
          toSingle={toSingle}
        />
      ))
    }
    return null
  }

  renderFooter = () => {
    const { total, page, pageSize, onChangePage } = this.props
    if (total < pageSize) return null
    return (
      <TableFooter page={page} pageSize={pageSize} onChangePage={onChangePage} total={total} />
    )
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.tableContainer}>
        {this.renderSearch()}
        <Paper className={classes.root} classes={{ rounded: classes.rounded }} elevation={0}>
          <Table className={classes.table} padding='none'>
            <OrganizationTableHeader />
            <TableBody>
              {this.renderRows()}
            </TableBody>
          </Table>
          {this.renderLoading()}
        </Paper>
        {this.renderFooter()}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(OrganizationTable))
