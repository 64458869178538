import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../utils/withRoot'
import DomainActions from '../redux/DomainRedux'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import DomainTable from '../components/Domain/DomainTable'
import DomainModal from '../components/Domain/DomainModal'
import { isAdminLevelUser } from '../utils/permissions'
import { isDomainAdmin } from '../utils/transforms'

const MODAL_TYPES = {
  NEW: 'new',
  EDIT: 'edit'
}

const styles = theme => ({
})

class DomainsScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      pageSize: 10,
      page: 0,
      modal: null,
      name: '',
      editDomainId: null
    }
  }

  componentDidMount () {
    const { currentOrganizationId } = this.props
    const { pageSize } = this.state
    // Load domains if user is ready
    if (currentOrganizationId) {
      this.props.getDomains(currentOrganizationId, { limit: pageSize, offset: 0 })
    }
  }

  componentWillReceiveProps (nextProps) {
    const { domains, currentOrganizationId, fetching, error } = this.props
    const { pageSize } = this.state
    // Load domains when user is loaded
    if (!domains && !currentOrganizationId && nextProps.currentOrganizationId) {
      this.props.getDomains(nextProps.currentOrganizationId, { limit: pageSize, offset: 0 })
    }
    // Close modal after success
    if (fetching && !nextProps.fetching && !error) {
      this.closeModal()
    }
  }

  setPage = (evt, page) => {
    const { currentOrganizationId } = this.props
    const { pageSize } = this.state
    this.props.getDomains(currentOrganizationId, { limit: pageSize, offset: page * pageSize })
    this.setState({ page })
  }

  setName = (evt) => this.setState({ name: evt.target.value })

  isCreateDisabled = () => !isAdminLevelUser(this.props.user)

  showEdit = () => !isDomainAdmin(this.props.user)

  openCreateNewModal = () => this.setState({ name: '', modal: MODAL_TYPES.NEW })

  openEditModal = (domain) => {
    this.setState({ name: domain.name, editDomainId: domain.id, modal: MODAL_TYPES.EDIT })
  }

  closeModal = () => this.setState({ modal: null })

  handleCreate = () => {
    const { name } = this.state
    const { currentOrganizationId } = this.props
    this.props.createDomain(currentOrganizationId, { name })
  }

  handleUpdate = () => {
    const { name, editDomainId } = this.state
    this.props.updateDomain(editDomainId, { name })
  }

  renderDomainModal = () => {
    const { modal, name } = this.state
    if (modal) {
      const { t } = this.props
      const title = modal === MODAL_TYPES.NEW ? t('create_domain') : t('edit')
      const onSubmit = modal === MODAL_TYPES.NEW ? this.handleCreate : this.handleUpdate
      return (
        <DomainModal
          title={title}
          onClose={this.closeModal}
          name={name}
          setName={this.setName}
          onSubmit={onSubmit}
          visible
        />
      )
    }
    return null
  }

  renderPage = () => {
    const { t, fetching, domains, user } = this.props
    const { page, pageSize } = this.state
    const totalCount = domains ? domains.total : 0
    const visibleDomains = domains ? domains.results : null
    return (
      <Layout>
        <PageHeader
          title={t('domains')}
          onClick={this.openCreateNewModal}
          loading={fetching}
          buttonText={t('create_domain')}
          hideButton={this.isCreateDisabled()}
          listScreen
        />
        <DomainTable
          domains={visibleDomains}
          editDomain={this.openEditModal}
          loading={fetching}
          total={totalCount}
          onChangePage={this.setPage}
          page={page}
          pageSize={pageSize}
          user={user}
          showEdit={this.showEdit()}
        />
        {this.renderDomainModal()}
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: state.domain.fetching,
    error: state.notification.error,
    domains: state.domain.domains,
    currentOrganizationId: state.user.currentOrganizationId,
    user: state.user.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDomains: (organizationId, params) => dispatch(DomainActions.getDomains(organizationId, params)),
    createDomain: (organizationId, fields) => dispatch(DomainActions.createDomain(organizationId, fields)),
    updateDomain: (domainId, fields) => dispatch(DomainActions.updateDomain(domainId, fields))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRoot(withStyles(styles)(withTranslation()(DomainsScreen)))
)
