import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../utils/withRoot'
import UserActions from '../redux/UserRedux'
import DomainActions from '../redux/DomainRedux'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import CreateUserEmailForm from '../components/User/CreateUserEmailForm'
import UserInfoForm from '../components/User/UserInfoForm'
import UserContactInfoForm from '../components/User/UserContactInfoForm'
import { ROLES, LOCALES, BIG_LIMIT, EXTENDED_ROLES } from '../utils/constants'
import UserDomainForm from '../components/User/UserDomainForm'
import { isValidEmail } from '../utils/transforms'
import { isUser } from '../utils/permissions'

const styles = theme => ({
})

class CreateUserScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      role: ROLES.USER,
      firstName: '',
      lastName: '',
      title: '',
      phone: '',
      locale: LOCALES.FI,
      domains: [],
      existingUserChecked: false
    }
  }

  componentDidMount () {
    const { currentOrganizationId, user } = this.props
    if (currentOrganizationId && user && user.role !== ROLES.USER) {
      this.props.getAllDomains(currentOrganizationId)
    }
    this.props.clearExistingUser()
  }

  componentWillReceiveProps (nextProps) {
    const { currentOrganizationId } = this.props
    const { user } = nextProps

    // Get all domains when current organization is set and user is admin/superadmin
    if (!currentOrganizationId && nextProps.currentOrganizationId && user && user.role !== ROLES.USER) {
      this.props.getAllDomains(nextProps.currentOrganizationId)
    }

    if (nextProps.success) {
      this.props.history.push('/kayttajat')
    }
  }

  setEmail = (evt) => this.setState({ email: evt.target.value })

  setRole = (evt) => this.setState({ role: evt.target.value, domains: [] })

  setFirstName = (evt) => this.setState({ firstName: evt.target.value })

  setLastName = (evt) => this.setState({ lastName: evt.target.value })

  setTitle = (evt) => this.setState({ title: evt.target.value })

  setPhone = (evt) => this.setState({ phone: evt.target.value })

  setLocale = (evt) => this.setState({ locale: evt.target.value })

  setDomain = (domainId) => {
    let domains = this.state.domains
    if (domains.includes(domainId)) {
      domains = domains.filter(domain => domain !== domainId)
      this.setState({ domains })
    } else {
      domains = domains.concat(domainId)
      this.setState({ domains })
    }
  }

  toUser = (userId) => this.props.history.push(`/kayttaja/${userId}`)

  handleGetExistingUserByEmail = (email) => {
    this.props.getExistingUserByEmail(email)
    this.setState({ existingUserChecked: true })
  }

  createUser = () => {
    const { currentOrganizationId } = this.props
    const domainRole = this.state.role === EXTENDED_ROLES.DOMAIN_ADMIN ? ROLES.ADMIN : ROLES.USER
    const role = this.state.role === EXTENDED_ROLES.DOMAIN_ADMIN ? ROLES.USER : this.state.role
    const newUser = {
      organizationId: currentOrganizationId,
      email: this.state.email.trim(),
      role,
      firstName: this.state.firstName.trim(),
      lastName: this.state.lastName.trim(),
      title: this.state.title.trim() || null,
      phone: this.state.phone.trim() || null,
      locale: this.state.locale.trim(),
      domains: this.state.domains.map(id => ({ id, role: domainRole }))
    }
    this.props.createUser(newUser)
  }

  isCreateUserDisabled = () => {
    // Check required fields
    const { firstName, lastName, email, role, domains } = this.state
    if (role !== ROLES.ADMIN && !domains.length) return true
    if (!firstName.trim() || !lastName.trim()) return true
    if (!isValidEmail(email)) return true
    return false
  }

  getAvailableDomains = () => {
    const { user, allDomains } = this.props
    if (!user) return []
    if (user.role === ROLES.USER) {
      return user.domains
    }
    return allDomains ? allDomains.results : []
  }

  renderUserDomainForm = () => {
    const { user } = this.props
    const { role, domains } = this.state
    // Disable role for domain admin
    const roleDisabled = !user || isUser(user)
    return (
      <UserDomainForm
        allDomains={this.getAvailableDomains()}
        currentUser={user}
        role={role}
        setRole={this.setRole}
        domains={domains}
        setDomain={this.setDomain}
        roleDisabled={roleDisabled}
        editing
      />
    )
  }

  renderCreateUserForms = () => {
    const { user, existingUser, fetching } = this.props
    const { email, role, firstName, lastName, title, phone, locale, existingUserChecked } = this.state

    if (existingUser || !existingUserChecked || fetching) {
      return null
    }

    return (
      <div>
        <UserContactInfoForm
          email={email}
          firstName={firstName}
          lastName={lastName}
          phone={phone}
          setEmail={this.setEmail}
          setFirstName={this.setFirstName}
          setLastName={this.setLastName}
          setPhone={this.setPhone}
          emailDisabled
          editing
        />
        <UserInfoForm
          role={role}
          title={title}
          locale={locale}
          setRole={this.setRole}
          setTitle={this.setTitle}
          setLocale={this.setLocale}
          user={user}
          editing
        />
        {this.renderUserDomainForm()}
      </div>
    )
  }

  renderPage = () => {
    const { t, fetching, existingUser } = this.props
    const { email, existingUserChecked } = this.state
    return (
      <Layout>
        <PageHeader
          title={t('create_user')}
          onClick={this.createUser}
          disabled={this.isCreateUserDisabled()}
          loading={fetching}
          hideButton={!existingUserChecked}
          editing
        />
        <CreateUserEmailForm
          email={email}
          setEmail={this.setEmail}
          existingUser={existingUser}
          getExistingUserByEmail={this.handleGetExistingUserByEmail}
          existingUserChecked={existingUserChecked}
          toUser={this.toUser}
        />
        {this.renderCreateUserForms()}
      </Layout>
    )
  }

  render () {
    return this.renderPage()
  }
}

const mapStateToProps = (state) => {
  return {
    currentOrganizationId: state.user.currentOrganizationId,
    fetching: state.user.fetching,
    error: state.notification.error,
    success: state.notification.success,
    user: state.user.user,
    allDomains: state.domain.domains,
    targetUser: state.user.targetUser,
    existingUser: state.user.existingUser
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (fields) => dispatch(UserActions.createUser(fields)),
    getAllDomains: (organizationId) => dispatch(DomainActions.getDomains(organizationId, { offset: 0, limit: BIG_LIMIT })),
    getExistingUserByEmail: (email) => dispatch(UserActions.getExistingUserByEmail(email)),
    clearExistingUser: () => dispatch(UserActions.clearExistingUser())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRoot(withStyles(styles)(withTranslation()(CreateUserScreen)))
)
