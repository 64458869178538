import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
import Button from '@material-ui/core/Button'
import MenuIcon from '@material-ui/icons/Menu'
import { COLORS } from '../utils/colors'

const styles = theme => ({
  headerContainer: {
    zIndex: 5000,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: 56,
    width: 300,
    backgroundColor: COLORS.dark,
    padding: 0,
    borderRadius: 0,
    paddingLeft: 20,
    '&:hover': {
      background: darken(COLORS.dark, 0.1)
    }
  },
  organization: {
    color: COLORS.white,
    fontFamily: 'Chivo',
    fontSize: 18,
    fontWeight: '700',
    margin: 0
  },
  icon: {
    color: COLORS.white
  }
})

class DrawerHeader extends React.PureComponent {
  renderContent = () => {
    const { classes, open, organization } = this.props
    if (open) {
      if (organization && organization.name) {
        return <p className={classes.organization}>{organization.name}</p>
      }
      return ''
    }
    return <MenuIcon classes={{ root: classes.icon }} />
  }

  render () {
    const { classes, open, handleOpen, handleClose } = this.props
    return (
      <Button className={classes.headerContainer} onClick={open ? handleClose : handleOpen}>
        {this.renderContent()}
      </Button>
    )
  }
}

export default withStyles(styles)(withTranslation()(DrawerHeader))
