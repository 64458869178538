import apisauce from 'apisauce'

const API_URLS = {
  local: 'http://localhost:1337/',
  stage: 'https://avain-api.stage.geniem.io/',
  production: 'https://avain-api.production.geniem.io/'
}

// Select API URL to use
let apiUrl = API_URLS.production
if (process.env.NODE_ENV === 'development') apiUrl = API_URLS.stage
if (window.location.hostname.includes('.stage.geniem.io')) apiUrl = API_URLS.stage

const auth = (token) => ({ headers: { Authorization: `Bearer ${token}` } })

const create = () => {
  const api = apisauce.create({
    baseURL: apiUrl,
    timeout: 20000
  })

  // Public endpoints
  const login = (email, password) => api.post('auth/login', { email, password }, {})
  const requestPasswordReset = (email) => api.post('auth/password-reset-code', { email }, {})
  const checkCodeValidity = (code, email) => api.get(`auth/code-validity?code=${code}&email=${email}`, {}, {})
  const resetPassword = (code, email, password) => api.post('auth/set-password', { code, email, password }, {})

  // User endpoints
  const getMe = (token) => api.get('user/me', {}, auth(token))
  const updateUser = (token, id, fields) => api.put(`user/${id}`, fields, auth(token))
  const changePassword = (token, oldPassword, newPassword) =>
    api.put('user/me/password', { oldPassword, newPassword }, auth(token))
  const getUsers = (token, organizationId, params) => api.get(`organization/${organizationId}/user`, params, auth(token))
  const getUser = (token, userId) => api.get(`user/${userId}`, {}, auth(token))
  const createUser = (token, user) => api.post('user', user, auth(token))
  const addUserDomain = (token, userId, domainId, role) => api.put(`/user/${userId}/domain/${domainId}`, { role }, auth(token))
  const removeUserDomain = (token, userId, domainId) => api.delete(`/user/${userId}/domain/${domainId}`, {}, auth(token))
  const getUserByEmail = (token, email) => api.get(`user/email/${email}`, {}, auth(token))

  // Asset endpoints
  const getCustomerAssets = (token, customerId, params) => api.get(`customer/${customerId}/asset`, params, auth(token))
  const getPropertyAssets = (token, propertyId, params) => api.get(`property/${propertyId}/asset`, params, auth(token))
  const getUserAssets = (token, userId, params) => api.get(`user/${userId}/asset`, params, auth(token))
  const getAssetStorageAssets = (token, assetStorageId, params) => api.get(`asset-storage/${assetStorageId}/asset`, params, auth(token))
  const getMyAssets = (token, params) => api.get(`user/me/asset`, params, auth(token))
  const getAsset = (token, id) => api.get(`asset/${id}`, {}, auth(token))
  const createAsset = (token, fields) => api.post('asset', fields, auth(token))
  const updateAsset = (token, id, fields) => api.put(`asset/${id}`, fields, auth(token))
  const deactivateAsset = (token, id) => api.delete(`asset/${id}`, {}, auth(token))
  const activateAsset = (token, id) => api.put(`asset/${id}`, { active: true }, auth(token))

  // Asset storage endpoints
  const getAssetStorages = (token, organizationId, params) => api.get(`organization/${organizationId}/asset-storage`, params, auth(token))
  const getAssetStorage = (token, id) => api.get(`asset-storage/${id}`, {}, auth(token))
  const createAssetStorage = (token, organizationId, fields) =>
    api.post(`organization/${organizationId}/asset-storage`, fields, auth(token))
  const updateAssetStorage = (token, id, fields) => api.put(`asset-storage/${id}`, fields, auth(token))
  const deactivateAssetStorage = (token, id) => api.delete(`asset-storage/${id}`, {}, auth(token))

  // Customer endpoints
  const createCustomer = (token, organizationId, fields) => api.post(`organization/${organizationId}/customer`, fields, auth(token))
  const getCustomer = (token, customerId) => api.get(`customer/${customerId}`, {}, auth(token))
  const getCustomers = (token, organizationId, params) => api.get(`organization/${organizationId}/customer`, params, auth(token))
  const updateCustomer = (token, customerId, fields) => api.put(`customer/${customerId}`, fields, auth(token))
  const addCustomerDomain = (token, customerId, domainId) => api.post(`customer/${customerId}/domain/${domainId}`, {}, auth(token))
  const removeCustomerDomain = (token, customerId, domainId) => api.delete(`customer/${customerId}/domain/${domainId}`, {}, auth(token))

  // Property endpoints
  const getProperties = (token, customerId, params) => api.get(`customer/${customerId}/property`, params, auth(token))
  const getProperty = (token, propertyId) => api.get(`property/${propertyId}`, {}, auth(token))
  const createProperty = (token, customerId, fields) => api.post(`customer/${customerId}/property`, fields, auth(token))
  const updateProperty = (token, propertyId, fields) => api.put(`property/${propertyId}`, fields, auth(token))
  const deactivateProperty = (token, id) => api.delete(`property/${id}`, {}, auth(token))

  // Domain endpoints
  const createDomain = (token, organizationId, fields) => api.post(`organization/${organizationId}/domain`, fields, auth(token))
  const getDomains = (token, organizationId, params) => api.get(`organization/${organizationId}/domain`, params, auth(token))
  const updateDomain = (token, domainId, fields) => api.put(`domain/${domainId}`, fields, auth(token))

  // Organization endpoints
  const getOrganization = (token, id) => api.get(`organization/${id}`, {}, auth(token))
  const getOrganizations = (token, params) => api.get('organization', params, auth(token))
  const createOrganization = (token, organization) => api.post('organization', organization, auth(token))
  const updateOrganization = (token, id, organization) => api.put(`organization/${id}`, organization, auth(token))
  const deactivateOrganization = (token, id) => api.delete(`organization/${id}`, {}, auth(token))

  // Asset transaction endpoints
  const getUserAssetTransactions = (token, userId, params) => api.get(`user/${userId}/transaction-log`, params, auth(token))
  const getAssetAssetTransactions = (token, assetId, params) => api.get(`asset/${assetId}/transaction-log`, params, auth(token))

  return {
    login,
    requestPasswordReset,
    checkCodeValidity,
    resetPassword,
    getMe,
    updateUser,
    changePassword,
    getAsset,
    getUserByEmail,
    getCustomerAssets,
    getPropertyAssets,
    getUserAssets,
    getAssetStorageAssets,
    getMyAssets,
    createAsset,
    updateAsset,
    deactivateAsset,
    activateAsset,
    getUsers,
    getUser,
    createUser,
    addUserDomain,
    removeUserDomain,
    getAssetStorages,
    getAssetStorage,
    createAssetStorage,
    updateAssetStorage,
    deactivateAssetStorage,
    createCustomer,
    getCustomer,
    getCustomers,
    updateCustomer,
    addCustomerDomain,
    removeCustomerDomain,
    createDomain,
    getDomains,
    updateDomain,
    getOrganization,
    getOrganizations,
    createOrganization,
    updateOrganization,
    deactivateOrganization,
    getProperties,
    getProperty,
    createProperty,
    updateProperty,
    deactivateProperty,
    getUserAssetTransactions,
    getAssetAssetTransactions
  }
}

export default {
  create
}
